import React from 'react';
import Grid from '@material-ui/core/Grid';
import EntitiesPersonsDetails from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsDetails';
import { useLocation } from 'react-router-dom';
import { getTypeByLocation } from 'components/wpio/EntitiesPersons/EntitiesPersonsUtils';
import { TYPE } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { EntitiesContextProvider } from 'contexts/wpio/EntitiesContext';
import { PersonsContextProvider } from 'contexts/wpio/PersonsContext';

const EntitiesPersonsDetailsView = () => {
  const location = useLocation();
  const type = getTypeByLocation(location);
  const ContextProvider =
    type === TYPE.ENTITY ? EntitiesContextProvider : PersonsContextProvider;

  return (
    <ContextProvider>
      <div>
        <Grid container spacing={3}>
          <EntitiesPersonsDetails />
        </Grid>
      </div>
    </ContextProvider>
  );
};

export default EntitiesPersonsDetailsView;
