import React, { useContext, useEffect, useState } from "react"
import { Button } from "components/shared/Button/Button"
import { TextField } from "@material-ui/core"
import AccountService from "services/shared/AccountService"
import { Redirect, useHistory } from "react-router-dom"
import UserContext from "contexts/shared/UserContext"
import { Dialog } from "components/shared/Modal/Modal"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import { skanerTheme } from "utils/skanerTheme"
import AccountCircle from "@material-ui/icons/AccountCircle"
import Lock from "@material-ui/icons/Lock"
import Loader from "components/shared/Loader/Loader"
import CircularLoader from "components/shared/Loader/CircularLoader"
import * as Yup from "yup"
import { useFormik } from "formik"
import SkanerContext from "contexts/shared/SkanerContext"
import { skanerRoutes } from "routes/skanerRoutes"

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("To nie jest poprawny adres email.")
    .required("Adres email musi być uzupełniony."),
  password: Yup.string().required("Hasło musi być uzupełnione."),
})

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 370,
    width: "100%",
  },
  container: {
    justifyContent: "center",
  },
  cardHedaer: {
    background: skanerTheme.palette.primary,
    color: skanerTheme.palette.white,
  },
  actions: {
    justifyContent: "space-between",
  },
  gridItemInputWrapper: {
    width: "calc(100% - 40px)",
    "& > div": {
      width: "100%",
    },
  },
}))

const loginChannel = new BroadcastChannel("login")

export const loginAllTabsEventListener = () => {
  loginChannel.onmessage = () => {
    window.location.reload()
    loginChannel.close()
  }
}

const LoginView = () => {
  const {
    user,
    isLoading: userIsLoading,
    setUser,
    enrichUserInfo,
  } = useContext(UserContext)
  const { appVersion } = useContext(SkanerContext)
  const history = useHistory()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: null,
  })

  const loginAllTabsPostMessage = () => {
    loginChannel.postMessage({ loginMessage: "Login" })
    loginAllTabsEventListener()
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await AccountService.login(values)
        const userInfo = await AccountService.getUserInfo()
        setUser(enrichUserInfo(userInfo))
        history.push(skanerRoutes.home)
        loginAllTabsPostMessage()
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 401) {
          setErrorModalOptions({
            isOpen: true,
            message:
              "Nie znaleziono użytkownika z podanym adresem email lub hasło jest niepoprawne.",
          })
        } else {
          history.push(skanerRoutes.errors[500])
        }
      }
    },
    validationSchema: LoginSchema,
  })

  if (userIsLoading) {
    return <Loader />
  }

  if (user && user.isLoggedIn) {
    return <Redirect to={skanerRoutes.home} />
  }

  return (
    <Grid container spacing={3} className={classes.container}>
      <Card elevation={3} className={classes.card}>
        <CardHeader
          title="Panel logowania"
          titleTypographyProps={{ variant: "h6" }}
          className={classes.cardHedaer}
        ></CardHeader>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <CardContent>
            <div className={classes.margin}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <AccountCircle
                    color="primary"
                    titleAccess="użytkownik"
                    aria-label="użytkownik"
                  />
                </Grid>
                <Grid item className={classes.gridItemInputWrapper}>
                  <TextField
                    id="email"
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    helperText={
                      formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email
                    }
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    fullWidth
                    autoFocus
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className={classes.gridFormRow}
              >
                <Grid item>
                  <Lock
                    color="primary"
                    titleAccess="hasło"
                    aria-label="hasło"
                  />
                </Grid>
                <Grid item className={classes.gridItemInputWrapper}>
                  <TextField
                    id="password"
                    label="Hasło"
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    helperText={
                      formik.errors.password &&
                      formik.touched.password &&
                      formik.errors.password
                    }
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => history.push(skanerRoutes.resetPassword)}
            >
              Resetuj hasło do konta
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {isLoading ? <CircularLoader /> : "Zaloguj"}
            </Button>
          </CardActions>
        </form>
      </Card>
      <Dialog
        isOpen={errorModalOptions.isOpen}
        handleClose={() =>
          setErrorModalOptions({ isOpen: false, message: null })
        }
        error
        title="Błąd logowania"
        text={errorModalOptions.message}
      ></Dialog>
      <div style={{ position: "absolute", bottom: "10px", right: "20px" }}>
        v.{appVersion}
      </div>
    </Grid>
  )
}

export default LoginView
