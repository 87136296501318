export const TYPE = { ENTITY: 'ENTITY', PERSON: 'PERSON' };

export const errorMessage =
  'Wystąpił błąd w trakcie pobierania danych. Spróbuj ponownie. Jeżeli problem nie ustępuje skontaktuj się z administratorem systemu.';

export const columnViewPxBoundary = 1400;

export const LAYOUT = {
  ONLY_GRAPH: 'ONLY_GRAPH',
  ONLY_DETAILS: 'ONLY_DETAILS',
  BOTH: 'BOTH',
};
