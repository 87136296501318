import React from 'react';
import { getEntityPersonValue } from 'components/wpio/EntitiesPersons/EntitiesPersonsUtils';
import {
  LabelValue,
  LabelValueRow,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsShared';

const PersonsIdentificationSection = ({
  data: { data_urodzenia, adres, nr_pesel },
}) => {
  return (
    <div>
      <LabelValueRow>
        <LabelValue label="PESEL" value={getEntityPersonValue(nr_pesel)} />
        <LabelValue
          label="Data urodzenia"
          value={getEntityPersonValue(data_urodzenia)}
        />
        <LabelValue label="Adres" value={getEntityPersonValue(adres)} />
      </LabelValueRow>
    </div>
  );
};

export default PersonsIdentificationSection;
