import React, { useState } from "react"
import {
  Divider,
  ErrorMessage,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
  TableContainer,
} from "components/wpio/EntitiesPersons/EntitiesPersonsShared"
import { errorMessage } from "components/wpio/EntitiesPersons/EntitiesPersonsConsts"
import { isNUllOrEmptyOrUndefined } from "utils/utils"
import { Button } from "components/shared/Button/Button"
import { Modal } from "components/shared/Modal/Modal"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { DangerButton } from "components/shared/Button/Button"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Table from "@material-ui/core/Table"

const EntitiesPersonsPublicProcurementSection = ({
  publicProcurement,
  error,
}) => {
  if (error) {
    return (
      <ErrorMessage>
        {(error.response &&
          error.response.data &&
          error.response.data.detail) ||
          errorMessage}
      </ErrorMessage>
    )
  }

  const { lista_BK14, lista_BK21 } = publicProcurement

  const moreThan3 = (data) => data && data.length > 0 && data.length > 3

  return (
    <>
      {lista_BK14 && lista_BK14.length > 0 && (
        <div>
          <SubSectionTitle>
            Zamówienia publiczne w projektach współfinansowanych ze środków UE -
            Baza Konkurencyjności 2014
          </SubSectionTitle>
          {(
            (moreThan3(lista_BK14) && [
              ...lista_BK14.filter((p, index) => index < 3),
            ]) || [...lista_BK14]
          ).map((item, index) => (
            <BK14Item
              key={
                item.nr_projektu +
                item.nr_ogloszenia +
                item.rola +
                item.nip +
                item.nazwa +
                item.kod_pocztowy +
                item.oferty +
                item.przedmiot_zamowienia +
                item.nazwa_i_adres_zwyciezcy
              }
              item={item}
              index={index}
              length={lista_BK14.length}
            ></BK14Item>
          ))}
          {moreThan3(lista_BK14) && (
            <ShowAll
              partialTitle="Baza Konkurencyjności 2014"
              publicProcurement={lista_BK14}
              renderComponent={(props) => <BK14Item {...props} />}
              getKey={(item) =>
                item.nr_projektu +
                item.nr_ogloszenia +
                item.rola +
                item.nip +
                item.nazwa +
                item.kod_pocztowy +
                item.oferty +
                item.przedmiot_zamowienia +
                item.nazwa_i_adres_zwyciezcy
              }
            />
          )}
        </div>
      )}
      {lista_BK21 && lista_BK21.length > 0 && (
        <>
          {lista_BK14 && lista_BK14.length > 0 && (
            <Divider style={{ marginTop: 16, marginBottom: 32 }} />
          )}
          <div>
            <SubSectionTitle>
              Zamówienia publiczne w projektach współfinansowanych ze środków UE
              - Baza Konkurencyjności 2021
            </SubSectionTitle>
            {(
              (moreThan3(lista_BK21) && [
                ...lista_BK21.filter((p, index) => index < 3),
              ]) || [...lista_BK21]
            ).map((item, index) => (
              <BK21Item
                key={
                  item.nr_projektu +
                  item.nr_ogloszenia +
                  item.rola +
                  item.nazwa +
                  item.przedmiot_zamowienia +
                  item.nazwa_i_adres_zwyciezcy +
                  item.nr_zwyciezcy +
                  item.nr_zamawiajacego +
                  item.data_wplyniecia_oferty +
                  item.cena_oferty
                }
                item={item}
                index={index}
                length={lista_BK21.length}
              ></BK21Item>
            ))}
            {moreThan3(lista_BK21) && (
              <ShowAll
                partialTitle="Baza Konkurencyjności 2021"
                publicProcurement={lista_BK21}
                renderComponent={(props) => <BK21Item {...props} />}
                getKey={(item) =>
                  item.nr_projektu +
                  item.nr_ogloszenia +
                  item.rola +
                  item.nazwa +
                  item.przedmiot_zamowienia +
                  item.nazwa_i_adres_zwyciezcy +
                  item.nr_zwyciezcy +
                  item.nr_zamawiajacego +
                  item.data_wplyniecia_oferty +
                  item.cena_oferty
                }
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

const BK14Item = ({ item, index, length }) => {
  const {
    nr_projektu,
    nr_ogloszenia,
    rola,
    nip,
    nazwa,
    kod_pocztowy,
    oferty,
    przedmiot_zamowienia,
    nazwa_i_adres_zwyciezcy,
  } = item

  return (
    <div>
      <LabelValueRow>
        {!isNUllOrEmptyOrUndefined(nr_projektu) && (
          <LabelValue label="Numer projektu" value={nr_projektu} />
        )}
        {!isNUllOrEmptyOrUndefined(nr_ogloszenia) && (
          <LabelValue label="Numer ogłoszenia" value={nr_ogloszenia} />
        )}
        {!isNUllOrEmptyOrUndefined(rola) && (
          <LabelValue label="Rola" value={rola} />
        )}
        {!isNUllOrEmptyOrUndefined(nip) && (
          <LabelValue label="NIP Zamawiającego" value={nip} />
        )}
        {!isNUllOrEmptyOrUndefined(nazwa) && (
          <LabelValue label="Nazwa Zamawiającego" value={nazwa} />
        )}
        {!isNUllOrEmptyOrUndefined(kod_pocztowy) && (
          <LabelValue label="Kod pocztowy Zamawiającego" value={kod_pocztowy} />
        )}
        {!isNUllOrEmptyOrUndefined(przedmiot_zamowienia) && (
          <LabelValue
            label="Przedmiot zamówienia"
            value={przedmiot_zamowienia}
          />
        )}
        {!isNUllOrEmptyOrUndefined(oferty) && (
          <LabelValue label="Oferty" value={oferty} />
        )}
        {!isNUllOrEmptyOrUndefined(nazwa_i_adres_zwyciezcy) && (
          <LabelValue
            label="Zwycięzca ogłoszenia"
            value={nazwa_i_adres_zwyciezcy}
          />
        )}
      </LabelValueRow>
      {index !== length - 1 && <Divider />}
    </div>
  )
}

const BK21Item = ({ item, index, length }) => {
  const {
    nr_projektu,
    nr_ogloszenia,
    rola,
    nazwa,
    lista_ofert,
    przedmiot_zamowienia,
    nazwa_i_adres_zwyciezcy,
    typ_nr_zwyciezcy,
    nr_zwyciezcy,
    typ_nr_zamawiajacego,
    nr_zamawiajacego,
    data_wplyniecia_oferty,
    cena_oferty,
  } = item

  return (
    <div>
      <LabelValueRow>
        {!isNUllOrEmptyOrUndefined(nr_projektu) && (
          <LabelValue
            label={
              nr_projektu.length > 1 ? "Numery projektów" : "Numer projektu"
            }
            value={nr_projektu}
            multi
          />
        )}
        {!isNUllOrEmptyOrUndefined(nr_ogloszenia) && (
          <LabelValue label="Numer ogłoszenia" value={nr_ogloszenia} />
        )}
        {!isNUllOrEmptyOrUndefined(rola) && (
          <LabelValue label="Rola" value={rola} />
        )}
        {!isNUllOrEmptyOrUndefined(nr_zamawiajacego) &&
          !isNUllOrEmptyOrUndefined(typ_nr_zamawiajacego) && (
            <LabelValue
              label={`${typ_nr_zamawiajacego.toUpperCase()} Zamawiającego`}
              value={nr_zamawiajacego}
            />
          )}
        {!isNUllOrEmptyOrUndefined(nazwa) && (
          <LabelValue label="Nazwa Zamawiającego" value={nazwa} />
        )}
        {!isNUllOrEmptyOrUndefined(przedmiot_zamowienia) && (
          <LabelValue
            label="Przedmiot zamówienia"
            value={przedmiot_zamowienia}
          />
        )}
        {!isNUllOrEmptyOrUndefined(nazwa_i_adres_zwyciezcy) && (
          <LabelValue
            label="Zwycięzca ogłoszenia"
            value={nazwa_i_adres_zwyciezcy}
          />
        )}
        {!isNUllOrEmptyOrUndefined(typ_nr_zwyciezcy) &&
          !isNUllOrEmptyOrUndefined(nr_zwyciezcy) && (
            <LabelValue
              label={`Identyfikator Zwycięzcy`}
              value={nr_zwyciezcy}
            />
          )}
        {!isNUllOrEmptyOrUndefined(data_wplyniecia_oferty) && (
          <LabelValue
            label="Data wpłynięcia oferty"
            value={data_wplyniecia_oferty}
          />
        )}
        {!isNUllOrEmptyOrUndefined(cena_oferty) && (
          <LabelValue label="Cena oferty" value={cena_oferty} />
        )}
      </LabelValueRow>
      {lista_ofert && lista_ofert.length > 0 && (
        <TableContainer component="div">
          <Table
            size="small"
            aria-label={`tabela ofert dla projektu nr ${nr_projektu}`}
          >
            <TableHead>
              <TableRow>
                <TableCell>Nazwa i adres oferenta</TableCell>
                <TableCell>Numer oferenta</TableCell>
                <TableCell>Data wpłynięcia oferty</TableCell>
                <TableCell>Cena oferty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lista_ofert.map(
                ({
                  nazwa_i_adres_oferenta,
                  typ_nr_oferenta,
                  nr_oferenta,
                  data_wplyniecia_oferty,
                  cena_oferty,
                }) => (
                  <TableRow
                    key={
                      nazwa_i_adres_oferenta +
                      typ_nr_oferenta +
                      nr_oferenta +
                      data_wplyniecia_oferty +
                      cena_oferty
                    }
                  >
                    <TableCell component="th" scope="row">
                      {nazwa_i_adres_oferenta}
                    </TableCell>
                    <TableCell>
                      {`${typ_nr_oferenta.toUpperCase()} ${nr_oferenta}`}
                    </TableCell>
                    <TableCell>{data_wplyniecia_oferty}</TableCell>
                    <TableCell>{cena_oferty}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {index !== length - 1 && <Divider />}
    </div>
  )
}

const ShowAll = ({
  publicProcurement,
  getKey,
  renderComponent,
  partialTitle,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => setModalIsOpen(true)}
        variant="contained"
        color="primary"
      >
        Pokaż wszystkie ({publicProcurement.length})
      </Button>
      <Modal
        isOpen={modalIsOpen}
        title={`Zamówienia publiczne w projektach współfinansowanych ze środków UE - ${partialTitle}`}
        handleClose={() => setModalIsOpen(false)}
        maxWidth="lg"
      >
        <DialogContent>
          {publicProcurement.map((item, index) => (
            <React.Fragment key={getKey(item)}>
              {renderComponent({
                item,
                index,
                length: publicProcurement.length,
              })}
            </React.Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <DangerButton
            style={{ marginLeft: 16 }}
            variant="outlined"
            onClick={() => setModalIsOpen(false)}
          >
            Zamknij
          </DangerButton>
        </DialogActions>
      </Modal>
    </>
  )
}

export default EntitiesPersonsPublicProcurementSection
