import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { skanerTheme } from 'utils/skanerTheme';

const StyledCircularLoader = styled(CircularProgress)`
  svg {
    color: ${({ svgcolor }) => svgcolor};
  }
`;

const CircularLoader = ({ svgcolor = skanerTheme.palette.white }) => {
  return (
    <StyledCircularLoader size={24} svgcolor={svgcolor} aria-label="loader" />
  );
};

export default CircularLoader;
