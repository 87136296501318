import React from 'react';
import {
  Divider,
  ErrorMessage,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsShared';
import { errorMessage } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { isNullOrEmpty } from 'utils/utils';

const EntitiesUnitsSection = ({ units, error }) => {
  if (error) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  const { dane_krs, dane_ceidg } = units;

  return (
    <>
      {dane_krs && dane_krs.oddzialy && dane_krs.oddzialy.length > 0 && (
        <div>
          <SubSectionTitle>Oddziały</SubSectionTitle>
          {dane_krs.oddzialy.map(
            ({ firma_oddzialu, siedziba, adres }, index) => (
              <div key={firma_oddzialu + siedziba + adres}>
                {!isNullOrEmpty(firma_oddzialu) && (
                  <LabelValue label="Firma oddziału" value={firma_oddzialu} />
                )}
                {!isNullOrEmpty(siedziba) && (
                  <LabelValue label="Siedziba" value={siedziba} />
                )}
                {!isNullOrEmpty(adres) && (
                  <LabelValue label="Adres" value={adres} />
                )}
                {index !== dane_krs.oddzialy.length - 1 && <Divider />}
              </div>
            )
          )}
        </div>
      )}
      {dane_ceidg &&
        dane_ceidg.adresy_dzialalnosci_dodatkowe &&
        dane_ceidg.adresy_dzialalnosci_dodatkowe.length > 0 && (
          <div>
            <SubSectionTitle>Adresy dodatkowe</SubSectionTitle>
            {dane_ceidg.adresy_dzialalnosci_dodatkowe.map(
              (
                {
                  kraj,
                  wojewodztwo,
                  powiat,
                  gmina,
                  miasto,
                  kod,
                  ulica,
                  budynek,
                  lokal,
                },
                index
              ) => (
                <div
                  key={
                    kraj +
                    wojewodztwo +
                    powiat +
                    gmina +
                    miasto +
                    kod +
                    ulica +
                    budynek +
                    lokal
                  }
                >
                  <LabelValueRow>
                    {!isNullOrEmpty(kraj) && (
                      <LabelValue label="Kraj" value={kraj} />
                    )}
                    {!isNullOrEmpty(wojewodztwo) && (
                      <LabelValue label="Województwo" value={wojewodztwo} />
                    )}
                    {!isNullOrEmpty(powiat) && (
                      <LabelValue label="Powiat" value={powiat} />
                    )}
                    {!isNullOrEmpty(gmina) && (
                      <LabelValue label="Gmina" value={gmina} />
                    )}
                    {!isNullOrEmpty(miasto) && (
                      <LabelValue label="Miasto" value={miasto} />
                    )}
                    {!isNullOrEmpty(kod) && (
                      <LabelValue label="Kod" value={kod} />
                    )}
                    {!isNullOrEmpty(ulica) && (
                      <LabelValue label="Ulica" value={ulica} />
                    )}
                    {!isNullOrEmpty(budynek) && (
                      <LabelValue label="Budynek" value={budynek} />
                    )}
                    {!isNullOrEmpty(lokal) && (
                      <LabelValue label="Lokal" value={lokal} />
                    )}
                  </LabelValueRow>
                  {index !==
                    dane_ceidg.adresy_dzialalnosci_dodatkowe.length - 1 && (
                    <Divider />
                  )}
                </div>
              )
            )}
          </div>
        )}
    </>
  );
};

export default EntitiesUnitsSection;
