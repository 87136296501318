import React, { useLayoutEffect, useRef, useState } from "react"
import PaginationItem from "@material-ui/lab/PaginationItem"
import TablePagination from "@material-ui/core/TablePagination"
import styled from "styled-components"
import { skanerTheme } from "utils/skanerTheme"
import { formatNumber, isNUllOrEmptyOrUndefined } from "utils/utils"
import { usePagination } from "@material-ui/lab/Pagination"
import { Button } from "components/shared/Button/Button"
import { useFormik } from "formik"
import { TextField } from "@material-ui/core"
import Popover from "@material-ui/core/Popover"
import { DropdownIcon } from "components/shared/Select/Select"

const StyledTablePagination = styled(TablePagination)`
  .MuiTablePagination-spacer {
    display: none;
  }

  .MuiInputBase-input {
    color: ${skanerTheme.palette.black};
  }

  padding: 16px;
  display: flex;
  justify-content: center;
`

const StyledNavPagination = styled.nav`
  margin-left: 32px;
`

const StyledListPagination = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`

const Pagination = ({
  count,
  page,
  pageSize,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPageOptions = [10, 25, 50],
  isPreciseCount = true,
}) => {
  const Actions = () => {
    const { items } = usePagination({
      count: Math.ceil(count / pageSize),
      showFirstButton: true,
      showLastButton: true,
      page,
      onChange: (_, page) => handleChangePage(page),
    })
    const paginationRefWrapper = useRef(null)
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)

    const openGoToPagePopover = (e) => {
      setPopoverAnchorEl(e.currentTarget)
    }

    const closeGoToPagePopover = () => {
      setPopoverAnchorEl(null)
    }

    const formik = useFormik({
      initialValues: {
        goToPage: "",
      },
      onSubmit: ({ goToPage }) => {
        if (!isNUllOrEmptyOrUndefined(goToPage)) {
          let newPage = +goToPage
          const lastPage = [...items].pop().page
          const firstPage = [...items][0].page

          if (newPage < firstPage) {
            newPage = 1
          }

          if (newPage > lastPage) {
            newPage = lastPage
          }

          handleChangePage(newPage)
        }

        closeGoToPagePopover()
      },
    })

    // WCAG compliance - need to be done in this way because there is no access to icon component in page navigation item
    useLayoutEffect(() => {
      if (paginationRefWrapper.current) {
        const svgs = paginationRefWrapper.current.querySelectorAll("svg")
        svgs.forEach((svg) => {
          svg.setAttribute("role", "none")
          svg.setAttribute("focusable", "false")
        })
      }
    }, [])

    const getTitle = (type) => {
      switch (type) {
        case "first":
          return "pierwsza strona"
        case "previous":
          return "poprzednia strona"
        case "page":
          return "strona"
        case "next":
          return "następna strona"
        case "last":
          return "ostatnia strona"
        default:
          return type
      }
    }

    return (
      <StyledNavPagination>
        <StyledListPagination ref={paginationRefWrapper}>
          {items.map(({ type, ...item }, index) => {
            let children = null

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = (
                <Button
                  type="button"
                  disableFocusOutline
                  onClick={openGoToPagePopover}
                  style={{ letterSpacing: "0.01071em", minWidth: 40 }}
                  title="idź do strony"
                >
                  ...
                </Button>
              )
            } else {
              children = (
                <PaginationItem
                  {...item}
                  type={type}
                  shape="rounded"
                  color="primary"
                  title={getTitle(type)}
                  aria-label={getTitle(type)}
                />
              )
            }

            return <li key={index}>{children}</li>
          })}
        </StyledListPagination>
        <Popover
          open={popoverAnchorEl !== null}
          anchorEl={popoverAnchorEl}
          onClose={closeGoToPagePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <div style={{ maxWidth: 200, width: 140, padding: 8 }}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                label="Idź do strony"
                id="goToPage"
                name="goToPage"
                type="number"
                value={formik.values.goToPage}
                onChange={formik.handleChange}
                fullWidth
                autoFocus
              />
            </form>
          </div>
        </Popover>
      </StyledNavPagination>
    )
  }

  return (
    <StyledTablePagination
      component={"div"}
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={pageSize}
      page={page - 1}
      SelectProps={{
        inputProps: {
          "aria-label": "wyników na stronę",
          IconComponent: DropdownIcon,
        },
      }}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} ${isPreciseCount ? "z" : "z ok."} ${formatNumber(
          count,
          0
        )}`
      }
      labelRowsPerPage="Wyników na stronę:"
      onPageChange={() => {}}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={Actions}
    />
  )
}

export default Pagination
