import React, { useState } from "react"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Button, DangerButton } from "components/shared/Button/Button"
import * as Yup from "yup"
import UserService from "services/shared/UserService"
import CircularLoader from "components/shared/Loader/CircularLoader"
import { useHistory, useParams } from "react-router-dom"
import { useFormik, resetForm } from "formik"
import { useApi } from "hooks/useApi"
import Loader from "components/shared/Loader/Loader"
import ErrorHandler from "components/shared/ErrorHandler/ErrorHandler"
import { skanerRoutes } from "routes/skanerRoutes"
import { getPath } from "routes/routesUtils"
import { Dialog } from "components/shared/Modal/Modal"
import CardActions from "@material-ui/core/CardActions"

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  gridItem: {
    width: "100%",
  },
  passwordInfo: {
    marginTop: "5px",
    padding: "10px",
  },
}))

const UserPasswordSchema = Yup.object().shape({
  editedPassword: Yup.string()
    .min(10, "Hasło jest za krótkie - musi mieć min. 10 znaków.")
    .required("To pole musi być uzupełnione."),
  confirmPassword: Yup.string()
    .required("To pole musi być uzupełnione.")
    .min(10, "Hasło jest za krótkie - musi mieć min. 10 znaków.")
    .oneOf([Yup.ref("editedPassword")], "Hasła nie pasują do siebie."),
})

const EditPassword = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const { result, isLoading: userIsLoading, error } = useApi(`/api/users/${id}`)
  const [showConfirmModalOptions, setShowConfirmModalOptions] = useState(false)
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: null,
    title: null,
  })

  const formik = useFormik({
    initialValues: {
      editedPassword: "",
      confirmPassword: "",
    },
    onSubmit: async ({ editedPassword }, { resetForm }) => {
      try {
        setIsLoading(true)
        await UserService.editPassword({ password: editedPassword, id: id })
        setIsLoading(false)
        setShowConfirmModalOptions(true)
        resetForm()
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          setErrorModalOptions({
            title: "Błąd",
            isOpen: true,
            message: "Wymogi wobec hasła nie zostały spełnione.",
          })
        } else {
          history.push(skanerRoutes.errors[500])
        }
      }
    },
    validationSchema: UserPasswordSchema,
  })

  if (userIsLoading || !result) {
    return <Loader />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={2}>
          <CardHeader
            title={`Edytuj hasło`}
            titleTypographyProps={{ variant: "h6" }}
          ></CardHeader>
          <form onSubmit={formik.handleSubmit}>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Nowe hasło"
                  name="editedPassword"
                  id="editedPassword"
                  type="password"
                  value={formik.values.editedPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.editedPassword &&
                    formik.touched.editedPassword &&
                    formik.errors.editedPassword
                  }
                  error={
                    formik.touched.editedPassword &&
                    Boolean(formik.errors.editedPassword)
                  }
                  fullWidth
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Powtórz hasło"
                  name="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  helperText={
                    formik.errors.confirmPassword &&
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  fullWidth
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <CardActions className={classes.error}>
              {error === 400 && (
                <span style={{ justifyContent: "flex-start" }}>
                  Wymogi wobec hasła nie zostały spełnione.
                </span>
              )}
            </CardActions>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.buttonsWrapper}>
                <Button type="submit" variant="contained" color="primary">
                  {isLoading ? <CircularLoader /> : "Zapisz"}
                </Button>
                <DangerButton
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={() => {
                    history.push(getPath(skanerRoutes.user.userPanel, id))
                  }}
                >
                  Anuluj
                </DangerButton>
              </Grid>
            </Grid>
            <div className={classes.passwordInfo}>
              <span>
                <strong>Wymogi wobec haseł: </strong>
                <br />
                - Minimalna liczba znaków: 10 <br />
                - Maksymalna liczba znaków: 32 <br />
                - Musi różnić się od 3 poprzednich <br />
                - Przynajmniej jedna wielka oraz jedna mała litera
                <br />
                - Przynajmniej jedna cyfra <br />
                - Przynajmniej jeden znak specjalny <br /> - Nie może zawierać
                ciągu min. 3 liter z adresu mailowego lub imienia i nazwiska
              </span>
            </div>
          </form>

          <Dialog
            isOpen={showConfirmModalOptions}
            handleClose={() =>
              history.push(getPath(skanerRoutes.user.userPanel, id))
            }
            title="Sukces"
            close
            text={"Hasło zostało poprawnie zmienione."}
          ></Dialog>
          <Dialog
            isOpen={errorModalOptions.isOpen}
            handleClose={() =>
              setErrorModalOptions({ isOpen: false, message: null })
            }
            error
            title={errorModalOptions.title}
            text={errorModalOptions.message}
          ></Dialog>
        </Card>
      </Grid>
    </Grid>
  )
}

export default EditPassword
