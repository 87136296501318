import React, { useContext, useEffect, useRef, useState } from "react"
import cytoscape from "cytoscape"
import cola from "cytoscape-cola"
import { useApi } from "hooks/useApi"
import Loader from "components/shared/Loader/Loader"
import ErrorHandler from "components/shared/ErrorHandler/ErrorHandler"
import EntitiesService from "services/wpio/EntitiesService"
import PersonsService from "services/wpio/PersonsService"
import graphStyles from "components/wpio/EntitiesPersons/GraphRelations/GraphStyles"
import {
  mapGraphItems,
  mapNewGraphItems,
  prepareGraphElements,
  prepareNewGraphElements,
} from "components/wpio/EntitiesPersons/GraphRelations/GraphUtils"
import { useLocation, useRouteMatch } from "react-router-dom"
import { getTypeByLocation } from "components/wpio/EntitiesPersons/EntitiesPersonsUtils"
import {
  columnViewPxBoundary,
  LAYOUT,
  TYPE,
} from "components/wpio/EntitiesPersons/EntitiesPersonsConsts"
import styled from "styled-components"
import GraphEntityPersonCardInfo from "components/wpio/EntitiesPersons/GraphRelations/GraphEntityPersonCardInfo"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import { skanerTheme } from "utils/skanerTheme"
import { useWindowSize } from "hooks/useWindowSize"
import PersonsContext from "contexts/wpio/PersonsContext"
import EntitiesContext from "contexts/wpio/EntitiesContext"
import {
  GraphDetailsTogglerButton,
  ScrollableActionsWrapper,
} from "components/wpio/EntitiesPersons/EntitiesPersonsShared"
import {
  GraphConfigButton,
  GraphConfigCard,
} from "components/wpio/EntitiesPersons/GraphRelations/GraphConfig"

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${skanerTheme.palette.lightgray};
`

const StyledLoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`

const StyledGraphInstanceWrapper = styled.div`
  height: 100%;
  width: 100%;
  min-height: 300px;
`

const StyledSnackbar = styled(Snackbar)`
  position: absolute;
`

const GraphRelations = ({ layout, onChangeLayout }) => {
  const {
    params: { id },
  } = useRouteMatch()
  const location = useLocation()
  const type = getTypeByLocation(location)
  const { result, error, loaded } = useApi(
    `/api/wpio/v1/${type === TYPE.ENTITY ? "podmioty" : "osoby"}/${id}/graf`
  )
  const [currentSelectedNode, setCurrentSelectedNode] = useState(null)
  const doubleClickedDetectionRef = useRef(null)
  const graphContainer = useRef(null)
  const graphInstance = useRef()
  const graphLayout = useRef()
  const [alertMessage, setAlertMessage] = useState(null)
  const [graphItems, setGraphItems] = useState(null)
  const {
    graph: { setGraphImage },
  } = useContext(type === TYPE.ENTITY ? EntitiesContext : PersonsContext)
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState
  )
  const windowSize = useWindowSize()
  const [graphConfig, setGraphConfig] = useState({
    yearsBack: 0,
  })
  const [graphConfigCardIsVisible, setGraphConfigCardIsVisible] =
    useState(false)

  useEffect(() => {
    const onVisibilityChange = () => {
      if (
        visibilityState !== "visible" &&
        document.visibilityState === "visible"
      ) {
        setVisibilityState(document.visibilityState)
      }
    }

    window.addEventListener("visibilitychange", onVisibilityChange)

    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (!loaded || result.length === 0 || visibilityState !== "visible") return

    if (!graphContainer.current && !graphInstance.current) {
      return
    }

    // Remove graph instance if exitsts:
    if (graphInstance.current) {
      graphInstance.current.destroy()
    }

    try {
      const items = graphItems || mapGraphItems(result)
      setGraphItems(items)
      const elements = prepareGraphElements(items, graphConfig, id)

      const graphOptions = {
        elements,
        maxZoom: 2,
        minZoom: 0.5,
        wheelSensitivity: 0.5,
      }

      // Use cola layout:
      cytoscape.use(cola)

      // Initialize graph:
      graphInstance.current = cytoscape({
        ...graphOptions,
        style: graphStyles,
        container: graphContainer.current,
        layout: {
          name: "cola",
          edgeLength: 150,
          stop: () => {
            // Callback that can be called after entity id changed so for destroyed graph we do nothing:
            if (graphInstance.current && !graphInstance.current.destroyed()) {
              setGraphImage(graphInstance.current.png({ scale: 4 }))
            }
          },
        },
      })

      // Register events:
      graphInstance.current.on("tap", "node", onNodeTap)
      graphInstance.current.on("tap", onGraphTap)
      graphInstance.current.on("doubleTap", "node", onNodeDoubleTap)
      window.graf = graphInstance.current
    } catch (error) {
      console.error(`Nie udało się zainicjalizować grafu; ${error}`)
    }

    // Remove graph instance when component unmounted:
    return () => {
      graphInstance.current && graphInstance.current.destroy()
      setGraphImage(null)
    }
  }, [loaded, result, visibilityState, graphConfig])

  useEffect(() => {
    if (graphInstance.current) {
      setTimeout(() => {
        graphInstance.current.resize()
        graphInstance.current.fit()
      }, 300)
    }
  }, [layout])

  const onNodeDoubleTap = async (evt) => {
    const node = evt.target

    setCurrentSelectedNode(null)
    await extendGraph(node.id(), node.data().type)
    showSiblingEdgeLabels(node.id())
  }

  const onNodeTap = (evt) => {
    if (
      doubleClickedDetectionRef.current &&
      doubleClickedDetectionRef.current === evt.target
    ) {
      // Logic fired when node is double tapped/clicked
      // We need to do this beacause cytoscpae library doesnt support doubleTap event
      // so we neet custom
      doubleClickedDetectionRef.current = null
      evt.preventDefault()
      evt.stopPropagation()
      evt.target.emit("doubleTap", [evt])
    } else {
      doubleClickedDetectionRef.current = evt.target
      setTimeout(() => {
        if (
          doubleClickedDetectionRef.current &&
          doubleClickedDetectionRef.current === evt.target
        ) {
          // logic on one tap/click:
          doubleClickedDetectionRef.current = null
          const node = evt.target
          setGraphConfigCardIsVisible(false)
          setCurrentSelectedNode({ ...node.data(), nodeId: node.id() })
          showSiblingEdgeLabels(node.id())
        }
      }, 500)
    }
  }

  const onGraphTap = (evt) => {
    if (evt.target === graphInstance.current) {
      // Set null to current selected node to hide info panel:
      setCurrentSelectedNode(null)
      // Hide edge labels:
      hideSiblingEdgeLabels()
    }
  }

  const hideSiblingEdgeLabels = () => {
    graphInstance.current.elements().forEach((x) => {
      if (x.isEdge()) {
        x.data("titleIsVisible", false)
      }
    })
  }

  const showSiblingEdgeLabels = (nodeId) => {
    // Deactivate all edge labels first:
    hideSiblingEdgeLabels()

    // Activate only edge labels which has source or target property to current selected node:
    graphInstance.current
      .filter((element) => {
        return (
          element.isEdge() &&
          (element.data("source") === nodeId ||
            element.data("target") === nodeId)
        )
      })
      .forEach((x) => {
        if (x.isEdge()) {
          x.data("titleIsVisible", true)
        }
      })
  }

  const onShowRelations = (nodeId, type) => {
    // Set null to current selected node to hide info panel:
    setCurrentSelectedNode(null)

    extendGraph(nodeId, type)
  }

  const extendGraph = async (nodeId, type) => {
    let data = null
    try {
      data =
        type === TYPE.ENTITY
          ? await EntitiesService.getGraph(nodeId)
          : await PersonsService.getGraph(nodeId)
    } catch (error) {
      console.error(`Nie udało się pobrać powiązań dla węzła o id = ${nodeId}`)
    }

    const newItems = mapNewGraphItems(data)
    const newGraphElements = prepareNewGraphElements(
      newItems,
      graphInstance.current.nodes(),
      graphInstance.current.edges(),
      graphConfig,
      nodeId
    )

    if (newGraphElements.length === 0) {
      setAlertMessage({
        severity: "info",
        message: "Nie dowiązano żadnych nowych węzłów.",
      })
      return
    }
    setGraphItems((previousGraphItems) => {
      return {
        nodes: [...previousGraphItems.nodes, ...newItems.nodes],
        edges: [...previousGraphItems.edges, ...newItems.edges],
      }
    })

    graphInstance.current.nodes().forEach((node) => {
      node.lock()
    })

    graphInstance.current.add([...newGraphElements])

    graphLayout.current = graphInstance.current.elements().makeLayout({
      name: "cola",
      edgeLength: 150,
    })
    graphLayout.current.run()

    graphLayout.current.on("layoutstop", () => {
      // Callback that can be called after entity id changed so for destroyed graph we do nothing:
      if (graphInstance.current && !graphInstance.current.destroyed()) {
        graphInstance.current.nodes().forEach((node) => {
          node.unlock()
        })
        setGraphImage(
          graphInstance.current && graphInstance.current.png({ scale: 4 })
        )
      }
    })

    setAlertMessage({
      severity: "success",
      message: `Zaktualizowano graf o nowe węzły.`,
    })
  }

  if (error) {
    return <ErrorHandler module="wpio" error={error} />
  }

  if (!loaded) {
    return (
      <StyledLoaderWrapper>
        <Loader />
      </StyledLoaderWrapper>
    )
  }

  return (
    <StyledWrapper>
      {layout !== LAYOUT.ONLY_GRAPH && (
        <ScrollableActionsWrapper
          location="left"
          boundaryElement="#wpio-entity-person-details-header"
        >
          {windowSize.width > columnViewPxBoundary && (
            <GraphDetailsTogglerButton
              onClick={() => {
                if (layout === LAYOUT.BOTH) {
                  setGraphConfigCardIsVisible(false)
                }
                onChangeLayout(LAYOUT.ONLY_GRAPH)
              }}
              ariaLabel="zwiń graf"
              direction="left"
            />
          )}
          <GraphConfigButton
            onClick={() => {
              setCurrentSelectedNode(null)
              setGraphConfigCardIsVisible(!graphConfigCardIsVisible)
            }}
          />
        </ScrollableActionsWrapper>
      )}
      {currentSelectedNode && (
        <GraphEntityPersonCardInfo
          onShowRelations={onShowRelations}
          data={currentSelectedNode}
        />
      )}
      {graphConfigCardIsVisible && (
        <GraphConfigCard
          config={graphConfig}
          onChange={(config) => {
            if (config.yearsBack !== graphConfig.yearsBack) {
              setGraphConfig(config)
            }
            setGraphConfigCardIsVisible(false)
          }}
          onCancel={() => setGraphConfigCardIsVisible(false)}
        />
      )}
      <StyledGraphInstanceWrapper ref={graphContainer} />
      {alertMessage && (
        <StyledSnackbar
          open={true}
          autoHideDuration={4000}
          onClose={() => setAlertMessage(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setAlertMessage(null)}
            severity={alertMessage.severity}
            elevation={6}
            variant="filled"
          >
            {alertMessage.message}
          </Alert>
        </StyledSnackbar>
      )}
    </StyledWrapper>
  )
}

export default GraphRelations
