import React from 'react';
import { useHistory } from 'react-router-dom';
import { skanerRoutes } from 'routes/skanerRoutes';
import { Button } from 'components/shared/Button/Button';
import ErrorBaseView from 'views/shared/ErrorBaseView';

const Error404View = () => {
  const history = useHistory();

  return (
    <ErrorBaseView
      title="Błąd 404"
      message="Strona o podanym adresie nie istnieje."
      action={
        <Button
          style={{ marginTop: 20 }}
          type="button"
          variant="outlined"
          color="primary"
          onClick={() => history.replace(skanerRoutes.home)}
          title="Powrót do strony głównej"
        >
          Powrót do strony głównej
        </Button>
      }
    />
  );
};

export default Error404View;
