import { useApi } from "hooks/useApi"
import React, { createContext, useEffect, useRef, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import EntitiesService from "services/wpio/EntitiesService"
import { isEmpty } from "utils/utils"
import axios from "axios"

const EntitiesContext = createContext()

const EntitiesContextProvider = ({ children }) => {
  const {
    params: { id },
  } = useRouteMatch()
  const { result, isLoading, error, loaded } = useApi(
    `/api/wpio/v1/podmioty/${id}`
  )
  const [allSectionsIsLoading, setAllSectionsIsLoading] = useState(false)
  const [relations, setRelations] = useState({})
  const [finances, setFinances] = useState({})
  const [units, setUnits] = useState({})
  const [euFunding, setEuFunding] = useState({})
  const [publicProcurement, setPublicProcurement] = useState({})
  const [transformation, setTransformation] = useState({})
  const [object, setObject] = useState({})
  const [graphImage, setGraphImage] = useState(null)
  const requestCancellationToken = useRef(null)

  const fetchAllSections = () => {
    setAllSectionsIsLoading(true)
    clearAllSections()

    const request = axios.CancelToken.source()
    requestCancellationToken.current = request

    EntitiesService.getRelations(id, request)
      .then((relations) => setRelations({ data: relations }))
      .catch((error) => handleError(error, setRelations))

    EntitiesService.getFinances(id, request)
      .then((finances) => setFinances({ data: finances }))
      .catch((error) => handleError(error, setFinances))

    EntitiesService.getUnits(id, request)
      .then((units) => setUnits({ data: units }))
      .catch((error) => handleError(error, setUnits))

    EntitiesService.getEuFunding(id, request)
      .then((euFunding) => setEuFunding({ data: euFunding }))
      .catch((error) => handleError(error, setEuFunding))

    EntitiesService.getPublicProcurement(id, request)
      .then((publicProcurement) =>
        setPublicProcurement({ data: publicProcurement })
      )
      .catch((error) => handleError(error, setPublicProcurement))

    EntitiesService.getTransformation(id, request)
      .then((transformation) => setTransformation({ data: transformation }))
      .catch((error) => handleError(error, setTransformation))

    EntitiesService.getObject(id, request)
      .then((object) => setObject({ data: object }))
      .catch((error) => handleError(error, setObject))
  }

  const handleError = (error, setFunc) => {
    if (axios.isCancel(error)) return

    setFunc({ error })
  }

  useEffect(() => {
    return () => {
      cancelNotFinishedRequests()
    }
  }, [])

  const clearAllSections = () => {
    cancelNotFinishedRequests()

    setRelations({})
    setFinances({})
    setUnits({})
    setEuFunding({})
    setPublicProcurement({})
    setTransformation({})
    setObject({})
  }

  useEffect(() => {
    if (loaded) {
      fetchAllSections()
    }
  }, [loaded, id])

  useEffect(() => {
    if (
      [
        relations,
        finances,
        units,
        euFunding,
        publicProcurement,
        transformation,
        object,
      ].every((x) => !isEmpty(x))
    ) {
      setAllSectionsIsLoading(false)
    }
  }, [
    relations,
    finances,
    units,
    euFunding,
    publicProcurement,
    transformation,
    object,
  ])

  const cancelNotFinishedRequests = () => {
    // Cancel all requests that are not finished, because entity id has changed and they are not used anymore
    if (requestCancellationToken.current) {
      requestCancellationToken.current.cancel()
    }
  }

  return (
    <EntitiesContext.Provider
      value={{
        details: { result, isLoading, error },
        sections: {
          isLoading: allSectionsIsLoading,
          relations,
          finances,
          units,
          euFunding,
          publicProcurement,
          transformation,
          object,
        },
        graph: { graphImage, setGraphImage },
      }}
    >
      {children}
    </EntitiesContext.Provider>
  )
}

export default EntitiesContext

export { EntitiesContextProvider }
