import { useEffect, useRef } from 'react';

export const useSessionTimer = ({ timeout, onAction, onComplete }) => {
  const element = document;
  const events = ['click'];
  const emitOnAction = useRef(onAction);
  const emitOnComplete = useRef(onComplete);
  const timeoutId = useRef(null);
  const _timeout = useRef(timeout);
  const lastActive = useRef(null);

  const _handleEvent = (e) => {
    emitOnAction.current(e);
    if (!e.defaultPrevented) {
      localStorage.setItem(
        'session_timer_last_active',
        +new Date().getTime().toString()
      );
    }
  };

  const handleEvent = useRef(_handleEvent);

  const bindEvents = () => {
    events.forEach((e) =>
      element.addEventListener(e, handleEvent.current, {
        capture: true,
      })
    );
  };

  const unBindEvents = () => {
    events.forEach((e) =>
      element.removeEventListener(e, handleEvent.current, {
        capture: true,
      })
    );
  };

  const start = () => {
    lastActive.current = localStorage.setItem(
      'session_timer_last_active',
      +new Date().getTime().toString()
    );
    timeoutId.current = setTimeout(() => {}, _timeout.current);
  };

  const reset = () => {
    clearTimeout(timeoutId.current);
    start();
  };

  const getRemainingTime = () => {
    if (timeoutId.current === null) {
      return;
    }

    const sessionTimerLastActive = +new Date(
      +localStorage.getItem('session_timer_last_active')
    );

    const timeLeft = _timeout.current - (+new Date() - sessionTimerLastActive);

    if (timeLeft <= 0) {
      emitOnComplete.current();
      return { seconds: 0, minutes: 0 };
    }

    return {
      seconds: parseInt((timeLeft / 1000) % 60),
      minutes: parseInt((timeLeft / (1000 * 60)) % 60),
    };
  };

  useEffect(() => {
    bindEvents();

    start();

    return () => {
      clearTimeout(timeoutId.current);
      unBindEvents();
    };
  }, []);

  return {
    getRemainingTime,
    reset,
  };
};
