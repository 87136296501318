import React from "react"
import { Button, DangerButton } from "components/shared/Button/Button"
import MUIDialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import styled from "styled-components"
import { skanerTheme } from "utils/skanerTheme"
import CircularLoader from "components/shared/Loader/CircularLoader"

const StyledDialogTitle = styled(DialogTitle)`
  background: ${({ error }) =>
    (JSON.parse(error) && skanerTheme.palette.secondaryRed) ||
    skanerTheme.palette.primary};
  color: ${skanerTheme.palette.white};
`

const StyledDialogText = styled(DialogContentText)`
  color: ${skanerTheme.palette.black};
`

const removeSentinelTabIndex = () => {
  // Workaround to pass wcag compliance for modals:
  document
    .querySelectorAll('[data-test="sentinelStart"]')
    .forEach((x) => x.removeAttribute("tabIndex"))
  document
    .querySelectorAll('[data-test="sentinelEnd"]')
    .forEach((x) => x.removeAttribute("tabIndex"))
}

export const Modal = ({
  isOpen,
  title,
  handleClose,
  error = false,
  children,
  maxWidth = "sm",
}) => {
  return (
    <MUIDialog
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{
        role: "presentation",
        onEntered: () => {
          removeSentinelTabIndex()
        },
      }}
      aria-labelledby="alert-dialog-title"
    >
      <StyledDialogTitle id="alert-dialog-title" error={error.toString()}>
        {title}
      </StyledDialogTitle>
      {children}
    </MUIDialog>
  )
}

export const Dialog = ({
  isOpen,
  title,
  text,
  handleClose,
  customCloseText = "NIE",
  close = false,
  handleConfirm,
  customConfirmText = "TAK",
  dialog = false,
  error = false,
  isLoading = false,
  custom = null,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  children,
}) => {
  const onClose = (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false
    }

    if (disableEscapeKeyDown && reason === "escapeKeyDown") {
      return false
    }

    if (typeof handleClose === "function") {
      handleClose()
    }
  }

  return (
    <MUIDialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      TransitionProps={{
        role: "presentation",
        onEntered: () => {
          removeSentinelTabIndex()
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title" error={error.toString()}>
        {title}
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogText id="alert-dialog-description">
          {text}
        </StyledDialogText>
        {children}
      </DialogContent>
      <DialogActions>
        {error && (
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
        )}
        {close && (
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
        )}
        {dialog && (
          <>
            <Button onClick={handleConfirm} variant="contained" color="primary">
              {isLoading ? <CircularLoader /> : customConfirmText}
            </Button>
            <DangerButton
              style={{ marginLeft: 16 }}
              variant="outlined"
              onClick={handleClose}
            >
              {customCloseText}
            </DangerButton>
          </>
        )}
        {custom && (
          <>
            <Button onClick={handleConfirm} variant="contained" color="primary">
              {isLoading ? <CircularLoader /> : custom.confirmText}
            </Button>
            <DangerButton
              style={{ marginLeft: 16 }}
              variant="outlined"
              onClick={handleClose}
            >
              {custom.closeText}
            </DangerButton>
          </>
        )}
      </DialogActions>
    </MUIDialog>
  )
}
