export const wpioRoutes = {
  home: "/wpio",
  entitiesPersons: {
    entities: "/wpio/podmioty",
    persons: "/wpio/osoby",
    entityDetails: "/wpio/podmiot/:id",
    personDetails: "/wpio/osoba/:id",
  },
  errors: {
    500: "/wpio/blad-500",
    403: "/wpio/blad-403",
    404: "/wpio/blad-404",
  },
}
