import React from 'react';
import styled from 'styled-components';
import feUeLogosSrc from './fe_ue_logos.png';

const StyledImage = styled.img`
  width: 260px;
`;

const BottomLogos = () => {
  return (
    <div>
      <StyledImage
        src={feUeLogosSrc}
        alt="Zestawienie znaków: Fundusze Europejskie, Barwy Rzeczypospolitej Polskiej, Unia Europejska"
      />
    </div>
  );
};

export default BottomLogos;
