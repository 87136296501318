import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/TablePdf';
import { divider } from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/baseStyles';

const styles = StyleSheet.create({
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 15,
  },
});

const EntitiesNamesHistoryPdf = ({ history }) => {
  if (!history || history.length === 0) {
    return <></>;
  }

  return (
    <View style={{ marginTop: 30 }}>
      <View style={styles.sectionTitle}>
        <Text>Historia zmian nazwy podmiotu</Text>
      </View>
      <View style={{ ...divider }} />
      <Table>
        <TableHead
          columns={[
            { title: 'Nazwa', width: 50 },
            { title: 'Data od', width: 25 },
            { title: 'Data do', width: 25 },
          ]}
        />
        <TableBody>
          {history.map(({ nazwa, data_od, data_do }) => (
            <TableRow key={nazwa + data_od + data_do}>
              <TableCell width={50}>
                <Text>{nazwa}</Text>
              </TableCell>
              <TableCell width={25}>
                <Text>{data_od}</Text>
              </TableCell>
              <TableCell width={25}>
                <Text>
                  {(data_do === '9999-12-31' && 'obecnie') || data_do}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </View>
  );
};

export default EntitiesNamesHistoryPdf;
