import React, { useState } from "react"
import SamplerService from "services/sampler/SamplerService"
import Card from '@material-ui/core/Card';
import {Button} from '@material-ui/core';
import styled from "styled-components";
import { Button as MyButton } from "components/shared/Button/Button"

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Wrapper = styled.div`
  padding: 1rem;
`;

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(event.target.files[0]);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const handleUpload = async () => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    await SamplerService.sendFile(formData);
  };
  
  return (
    <Card elevation={2} sx={{ padding: '2rem' }}>
      <Wrapper>
        <div style={{ marginBottom: '1rem' }}>
          <b>Wybrany plik:</b> {fileName ? fileName : 'wybierz plik'}
        </div>

        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          style={{ marginRight: '1rem' }}
        >
          Wybierz plik
          <VisuallyHiddenInput type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
        </Button>

        <MyButton
          color="primary"
          variant="contained"
          onClick={handleUpload}
          disabled={!fileName.length}
        >
          Prześlij plik
        </MyButton>
      </Wrapper>
    </Card>
  );
};

export default FileUpload;