import { isBefore, isEqual } from "date-fns"

export const isNullOrUndefined = (value) =>
  value === null || value === undefined

export const isNullOrEmpty = (value) => value === null || value === ""

export const isNUllOrEmptyOrUndefined = (value) =>
  isNullOrEmpty(value) || value === undefined

export const getCookieValue = (key) => {
  const cookiesKeyValuePairs = document.cookie.split("=")
  const keyIndex = cookiesKeyValuePairs.findIndex((x) => x === key)

  if (keyIndex === -1) {
    return null
  }

  return decodeURIComponent(cookiesKeyValuePairs[keyIndex + 1])
}

export const dateFormat = "YYYY-MM-DD"

export const dateFormatWithTime = "YYYY-MM-DD HH:mm:ss"

export const formatNumber = (value, decimalPlaces = 2) => {
  if (typeof value !== "number") return

  return value
    .toFixed(decimalPlaces)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",")
    .replace(/ /g, "\u202F")
}

export const saveFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()

  // Clear a element:
  link.remove()

  // Release memory:
  URL.revokeObjectURL(url)
}

export const getRandomStr = () => {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 5)
}

export const blobToBase64 = async (blob) => {
  const reader = new FileReader()

  await new Promise((resolve, reject) => {
    reader.onload = resolve
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })

  return reader.result
}

export const shiftArray = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
}

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null
}

export const isEmpty = (val) => {
  if (isNUllOrEmptyOrUndefined(val)) {
    return true
  }

  if (Array.isArray(val)) {
    return val.length === 0
  }

  if (typeof val === "object") {
    return Object.entries(val).length === 0
  }

  throw Error("Not supported type of value")
}

export const getComputedStyleByProperty = (element, property) => {
  return window.getComputedStyle(element, null).getPropertyValue(property)
}

export const isDateRange = (dateMinStr, dateMaxStr) => {
  if (!dateMinStr || !dateMaxStr) {
    return true
  }

  const dateMin = new Date(dateMinStr)
  const dateMax = new Date(dateMaxStr)

  if (isEqual(dateMin, dateMax) || isBefore(dateMin, dateMax)) {
    return true
  }

  return false
}

export const isNumberRange = (numberMin, numberMax) => {
  if (!numberMin || !numberMax) {
    return true
  }

  if (numberMin <= numberMax) {
    return true
  }

  return false
}

export const getParamsFromURL = () => {
  const search = window.location.search
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, pair) => {
      const [key, value] = pair.map(decodeURIComponent)
      obj[key] = value
      return obj
    }, {})
  return search
}
