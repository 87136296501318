import Loader from "components/shared/Loader/Loader"
import React from "react"
import { useApi } from "hooks/useApi"

const SkanerContext = React.createContext()

const defaultFileMaxSizeMb = 100
const defaultAllowedExtensions = [
  ".pdf",
  ".docx",
  ".zip",
  ".doc",
  ".jpeg",
  ".jpg",
  ".png",
  ".7z",
]

const SkanerProvider = ({ children }) => {
  const { result, isLoading } = useApi(`/api/appInfo`)

  if (isLoading || !result) {
    return <Loader />
  }

  const value = result
  if (
    !value.attachements.fileMaxSizeMb ||
    value.attachements.fileMaxSizeMb === 0
  ) {
    value.attachements.fileMaxSizeMb = defaultFileMaxSizeMb
  }

  if (
    !value.attachements.defaultAllowedExtensions ||
    value.attachements.defaultAllowedExtensions.length === 0
  ) {
    value.attachements.allowedExt = defaultAllowedExtensions
  }

  return (
    <SkanerContext.Provider value={value}>{children}</SkanerContext.Provider>
  )
}

export default SkanerContext

export { SkanerProvider }
