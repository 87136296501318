import React from "react"
import {
  Divider,
  ErrorMessage,
  LabelValue,
  SubSectionTitle,
  TableContainer,
} from "components/wpio/EntitiesPersons/EntitiesPersonsShared"
import { errorMessage } from "components/wpio/EntitiesPersons/EntitiesPersonsConsts"
import { isEmpty, isNUllOrEmptyOrUndefined } from "utils/utils"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import MuiTableContainer from "@material-ui/core/TableContainer"

const EntitiesFinancesSection = ({ finances, error }) => {
  if (error) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>
  }

  const { dane_krs } = finances

  const shouldShowDivider = (nextElements) => {
    return nextElements.some((el) => el && el.length > 0)
  }

  return (
    <>
      {dane_krs.kapital && !isEmpty(dane_krs.kapital) && (
        <div>
          <SubSectionTitle>Kapitał</SubSectionTitle>
          <LabelValue
            label="Wysokość kapitału zakładowego"
            value={dane_krs.kapital.wysokosc_kapitalu_zakladowego}
          />
          <LabelValue
            label="Wysokość kapitału docelowego"
            value={dane_krs.kapital.wysokosc_kapitalu_docelowego}
          />
          {!isNUllOrEmptyOrUndefined(
            dane_krs.kapital.wartosci_akcji_objetych_za_aport
          ) && (
            <LabelValue
              label="Wartości udziałów objętych za aport"
              multi
              value={dane_krs.kapital.wartosci_akcji_objetych_za_aport}
            />
          )}
          {!isNUllOrEmptyOrUndefined(
            dane_krs.kapital.kwotowe_okreslenie_czesci_kapitalu_wplaconego
          ) && (
            <LabelValue
              label="Kwotowe określenie części kapitału wpłaconego"
              value={
                dane_krs.kapital.kwotowe_okreslenie_czesci_kapitalu_wplaconego
              }
            />
          )}
          {!isNUllOrEmptyOrUndefined(
            dane_krs.kapital.wartosc_nominalna_akcji
          ) && (
            <LabelValue
              label="Wartość nominalna akcji"
              value={dane_krs.kapital.wartosc_nominalna_akcji}
            />
          )}
          {!isNUllOrEmptyOrUndefined(
            dane_krs.kapital
              .wartosc_nominalna_warunkowego_podwyzszenia_kapitalu_zakladowego
          ) && (
            <LabelValue
              label="Wartość nominalna warunkowego podwyższenia kapitału zakładowego"
              value={
                dane_krs.kapital
                  .wartosc_nominalna_warunkowego_podwyzszenia_kapitalu_zakladowego
              }
            />
          )}
          {!isNUllOrEmptyOrUndefined(
            dane_krs.kapital.liczba_akcji_wszystkich_emisji
          ) && (
            <LabelValue
              label="Liczba akcji wszystkich emisji"
              value={dane_krs.kapital.liczba_akcji_wszystkich_emisji}
            />
          )}
          {shouldShowDivider([
            dane_krs.kapital.emisje_akcji,
            dane_krs.zaleglosci,
            dane_krs.wierzytelnosci,
            dane_krs.sprawozdanie_finansowe,
          ]) && <Divider />}
        </div>
      )}
      {dane_krs.kapital &&
      dane_krs.kapital.emisje_akcji &&
      dane_krs.kapital.emisje_akcji.length > 0 ? (
        <div>
          <SubSectionTitle>Emisje akcji</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell>Nazwa serii akcji</TableCell>
                  <TableCell>Liczba akcji</TableCell>
                  <TableCell>Rodzaj uprzywilejowania</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dane_krs.kapital.emisje_akcji.map(
                  ({
                    nazwa_serii_akcji,
                    liczba_akcji_w_danej_serii,
                    rodzaj_uprzywilejowania,
                  }) => (
                    <TableRow
                      key={
                        nazwa_serii_akcji +
                        liczba_akcji_w_danej_serii +
                        rodzaj_uprzywilejowania
                      }
                    >
                      <TableCell component="th" scope="row">
                        {nazwa_serii_akcji}
                      </TableCell>
                      <TableCell>{liczba_akcji_w_danej_serii}</TableCell>
                      <TableCell>{rodzaj_uprzywilejowania}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {shouldShowDivider([
            dane_krs.zaleglosci,
            dane_krs.wierzytelnosci,
            dane_krs.sprawozdanie_finansowe,
          ]) && <Divider />}
        </div>
      ) : (
        <div>
          <SubSectionTitle>Emisje akcji</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell>Nazwa serii akcji</TableCell>
                  <TableCell>Liczba akcji</TableCell>
                  <TableCell>Rodzaj uprzywilejowania</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Brak danych</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
        </div>
      )}
      {dane_krs.zaleglosci && dane_krs.zaleglosci.length > 0 ? (
        <div>
          <SubSectionTitle>Zaległości</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela zaległości">
              <TableHead>
                <TableRow>
                  <TableCell>Charakter zaległości</TableCell>
                  <TableCell>Organ wystawiający</TableCell>
                  <TableCell align="right">Wysokość zaległości</TableCell>
                  <TableCell>Informacja o zakończeniu egzekucji</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dane_krs.zaleglosci.map(
                  ({
                    charakter_zaleglosci,
                    organ_wystawiajacy,
                    wysokosc_zaleglosci,
                    zakonczenie_egzekucji,
                  }) => (
                    <TableRow
                      key={
                        charakter_zaleglosci +
                        organ_wystawiajacy +
                        wysokosc_zaleglosci +
                        zakonczenie_egzekucji
                      }
                    >
                      <TableCell component="th" scope="row">
                        {charakter_zaleglosci}
                      </TableCell>
                      <TableCell>{organ_wystawiajacy}</TableCell>
                      <TableCell align="right">{wysokosc_zaleglosci}</TableCell>
                      <TableCell>{zakonczenie_egzekucji}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {shouldShowDivider([
            dane_krs.wierzytelnosci,
            dane_krs.sprawozdanie_finansowe,
          ]) && <Divider />}
        </div>
      ) : (
        <div>
          <SubSectionTitle>Zaległości</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela zaległości">
              <TableHead>
                <TableRow>
                  <TableCell>Charakter zaległości</TableCell>
                  <TableCell>Organ wystawiający</TableCell>
                  <TableCell align="right">Wysokość zaległości</TableCell>
                  <TableCell>Informacja o zakończeniu egzekucji</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Brak danych</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
        </div>
      )}
      {dane_krs.wierzytelnosci && dane_krs.wierzytelnosci.length > 0 ? (
        <div>
          <SubSectionTitle>Wierzytelności</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela wierzytelności">
              <TableHead>
                <TableRow>
                  <TableCell>Tytuł wykonawczy</TableCell>
                  <TableCell>Kwota lub opis</TableCell>
                  <TableCell>Czy solidarna?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dane_krs.wierzytelnosci.map((row) => (
                  <CreditorsTableRow
                    key={
                      row.tytul_wykonawczy +
                      row.kwota_wierzytelnosci +
                      row.czy_wierzytelnosc_solidarna
                    }
                    row={row}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
        </div>
      ) : (
        <div>
          <SubSectionTitle>Wierzytelności</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela wierzytelności">
              <TableHead>
                <TableRow>
                  <TableCell>Tytuł wykonawczy</TableCell>
                  <TableCell>Kwota lub opis</TableCell>
                  <TableCell>Solidarna?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Brak danych</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
        </div>
      )}
      {dane_krs.sprawozdanie_finansowe &&
      dane_krs.sprawozdanie_finansowe.length > 0 ? (
        <div>
          <SubSectionTitle>Sprawozdania finansowe</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela sprawozdań finansowych">
              <TableHead>
                <TableRow>
                  <TableCell>Data złożenia</TableCell>
                  <TableCell>Za okres</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dane_krs.sprawozdanie_finansowe.map(
                  ({ data_zlozenia, za_okres }, index) => (
                    <TableRow key={data_zlozenia + za_okres + index}>
                      <TableCell component="th" scope="row">
                        {data_zlozenia}
                      </TableCell>
                      <TableCell>{za_okres}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div>
          <SubSectionTitle>Sprawozdania finansowe</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela sprawozdań finansowych">
              <TableHead>
                <TableRow>
                  <TableCell>Data złożenia</TableCell>
                  <TableCell>Za okres</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Brak danych</TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  )
}

const CreditorsTableRow = ({
  row: {
    tytul_wykonawczy,
    kwota_wierzytelnosci,
    czy_wierzytelnosc_jest_solidarna,
    lista_osob,
  },
}) => {
  return (
    <>
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          style={{ borderBottom: 0, width: "33%" }}
        >
          {tytul_wykonawczy}
        </TableCell>
        <TableCell style={{ borderBottom: 0, width: "33%" }}>
          {kwota_wierzytelnosci}
        </TableCell>
        <TableCell style={{ borderBottom: 0, width: "33%" }}>
          {czy_wierzytelnosc_jest_solidarna}
        </TableCell>
      </TableRow>
      {!isEmpty(lista_osob) && (
        <TableRow>
          <TableCell colSpan={3} style={{ padding: 0 }}>
            <MuiTableContainer
              component="div"
              style={{ background: "rgba(0, 0, 0, 0.05)" }}
            >
              <Table size="small" aria-label="tabela wierzycieli">
                <TableHead>
                  <TableRow>
                    <TableCell>Wierzyciele</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lista_osob.map(
                    ({ nazwa, imiona, numer_regon, numer_krs }) => (
                      <TableRow key={nazwa + imiona + numer_regon + numer_krs}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: 0, width: "33%" }}
                        >
                          {`${imiona} ${nazwa}`}
                        </TableCell>
                        <TableCell style={{ borderBottom: 0, width: "33%" }}>
                          {!isNUllOrEmptyOrUndefined(numer_regon) &&
                            `REGON: ${numer_regon}`}
                        </TableCell>
                        <TableCell style={{ borderBottom: 0 }}>
                          {!isNUllOrEmptyOrUndefined(numer_krs) &&
                            `KRS: ${numer_krs}`}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </MuiTableContainer>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default EntitiesFinancesSection
