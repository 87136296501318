import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import SamplersList from 'components/sampler/SamplersList';
import FileUpload from 'components/sampler/FileUpload';

const SamplerListView = ({ type }) => {
  return (
    <div>
      <Grid container spacing={3}>
        <h1>Przesyłanie pliku</h1>
        <Grid item xs={12}>
          <FileUpload />
        </Grid>
        <Grid item xs={12}>
          <SamplersList />
        </Grid>
      </Grid>
    </div>
  );
};

export default SamplerListView;