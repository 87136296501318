import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import styled from 'styled-components';
import Loader from 'components/shared/Loader/Loader';
import ErrorHandler from 'components/shared/ErrorHandler/ErrorHandler';
import GraphRelations from 'components/wpio/EntitiesPersons/GraphRelations/GraphRelations';
import {
  columnViewPxBoundary,
  LAYOUT,
  TYPE,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { getTypeByLocation } from 'components/wpio/EntitiesPersons/EntitiesPersonsUtils';
import Grid from '@material-ui/core/Grid';
import EntitiesDetailsPanel from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesDetailsPanel';
import PersonsDetailsPanel from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/PersonsDetailsPanel';
import EntitiesContext from 'contexts/wpio/EntitiesContext';
import PersonsContext from 'contexts/wpio/PersonsContext';
import EntitiesPersonsExportPdf from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesPersonsExportPdf';
import { skanerTheme } from 'utils/skanerTheme';
import EntitiesPersonsTitle from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsTitle';
import { useWindowSize } from 'hooks/useWindowSize';
import { getComputedStyleByProperty } from 'utils/utils';
import {
  getNameForPersons,
  GraphDetailsTogglerButton,
  ScrollableActionsWrapper, SubSectionTitle, TableContainer,
} from "components/wpio/EntitiesPersons/EntitiesPersonsShared"
import EntitiesNamesHistory from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesNamesHistory';
import Chip from '@material-ui/core/Chip';
import { Info, Label } from "@material-ui/icons"
import DialogContent from "@material-ui/core/DialogContent"
import MUIDialog from "@material-ui/core/Dialog"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import { Box, Tooltip } from "@material-ui/core"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import EntitiesService from "services/wpio/EntitiesService"
import CircularLoader from "../../../shared/Loader/CircularLoader"

const StyledHeaderCard = styled(Card)`
  background-color: ${skanerTheme.palette.primary};
`;

const StyledDialogTitle = styled(DialogTitle)`
  background: ${skanerTheme.palette.primary};
  color: ${skanerTheme.palette.white};
`

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: '1px solid rgba(0, 0, 0, 0.2)'
    },
    "& .MuiTableCell-head": {
      fontWeight: 500,
      backgroundColor: skanerTheme.palette.lightgray,
      marginBottom: "16px",
    }
  }
});

const StyledContent = styled.div`
  display: flex;
  height: 100%;

  > div {
    transition: all 0.1s;

    :first-child {
      width: ${({ layout }) =>
        (layout &&
          ((layout === LAYOUT.ONLY_GRAPH && '0%') ||
            (layout === LAYOUT.ONLY_DETAILS && '100%'))) ||
        '50%'};
    }

    :last-child {
      width: ${({ layout }) =>
        (layout &&
          ((layout === LAYOUT.ONLY_GRAPH && '100%') ||
            (layout === LAYOUT.ONLY_DETAILS && '0%'))) ||
        '50%'};
    }
  }

  @media (max-width: ${columnViewPxBoundary}px) {
    flex-flow: column;

    > div {
      :first-child {
        width: 100%;
      }

      :last-child {
        width: 100%;
      }
    }
  }
`;

const StyledDetailsPanelWrapper = styled.div`
  padding: ${({ layout }) =>
    (layout && layout === LAYOUT.ONLY_DETAILS && '0') || '16px'};
  overflow-y: auto;
  position: relative;

  @media (max-width: 1400px) {
    padding: 16px;
  }
`;

const StyledChip = styled(Chip)`
  background-color: ${skanerTheme.palette.white};
  margin-left: 55px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EntitiesPersonsDetails = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sourcesResult, setSourcesResult] = useState();
  const [sourcesResultIsLoading, setSourcesResultIsLoading] = useState(false);
  const classes = useStyles();
  const {
    params: { id },
  } = useRouteMatch();
  const location = useLocation();
  const type = getTypeByLocation(location);
  const contentRef = useRef(null);
  const windowSize = useWindowSize();
  const {
    details: { error, result, isLoading },
  } = useContext(type === TYPE.ENTITY ? EntitiesContext : PersonsContext);
  const [layout, setLayout] = useState(LAYOUT.BOTH);

  const onChangeLayout = (type) => {
    if (layout === LAYOUT.BOTH && type === LAYOUT.ONLY_DETAILS) {
      setLayout(LAYOUT.ONLY_DETAILS);
    } else if (layout === LAYOUT.BOTH && type === LAYOUT.ONLY_GRAPH) {
      setLayout(LAYOUT.ONLY_GRAPH);
    } else {
      setLayout(LAYOUT.BOTH);
    }
  };

  useEffect(() => {
    if (contentRef.current !== null) {
      const calculatedHeight = `${
        windowSize.height - contentRef.current.getBoundingClientRect().top - 30
      }px`;

      if (windowSize.height < 768) {
        contentRef.current.style.minHeight = calculatedHeight;
        contentRef.current.style.height = '';
      } else {
        contentRef.current.style.minHeight = '';
        contentRef.current.style.height = calculatedHeight;
      }
    }
  }, [windowSize, contentRef.current]);

  const FetchDivergenceOfSources = async () => {
    setSourcesResultIsLoading(true)
    const resp = await EntitiesService.getDivergenceOfSources(id);
    setSourcesResult(resp);
    setSourcesResultIsLoading(false)
  }

  if (error) {
    return <ErrorHandler module="wpio" error={error} />;
  }

  if (!result || isLoading) {
    return <Loader />;
  }


  return (
    <>
      <Grid item xs={12}>
        <Sticky>
          <StyledHeaderCard
            id="wpio-entity-person-details-header"
            elevation={2}
          >
            <CardHeader
              title={
                <>
                  <StyledTitleWrapper>
                    <EntitiesPersonsTitle
                      type={type}
                      title={
                        type === TYPE.ENTITY
                          ? result.nazwa
                          : getNameForPersons(result)
                      }
                    />
                    {result.nazwy_historyczne &&
                      result.nazwy_historyczne.length > 0 && (
                        <EntitiesNamesHistory
                          entityId={id}
                          history={result.nazwy_historyczne}
                        />
                      )}
                  </StyledTitleWrapper>
                  {result.forma_prawna && (
                    <div>
                      <StyledChip label={result.forma_prawna} />
                    </div>
                  )}
                </>
              }
              titleTypographyProps={{ variant: 'h6' }}
              style={{ flexWrap: 'wrap' }}
              action={
                <div style={{ marginTop: type === TYPE.ENTITY ? 15 : 5, marginRight: 10, display: "flex", flexDirection: "column",
                  justifyContent: "flex-END", alignItems: "right", textAlign: "right" }}>
                  <EntitiesPersonsExportPdf type={type} />
                  {type === TYPE.ENTITY &&
                    <div>
                      <Tooltip title={"Informacje identyfikacyjne podmiotu pochodzące ze źródeł SKANER"}>
                        <Info
                          style={{ marginTop: "5px", marginLeft: "100px", cursor: "pointer", color: "#FFFFFF"}}
                          fontSize='large'
                          onClick={() => {
                            void FetchDivergenceOfSources()
                            setIsDialogOpen(true)
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                </div>
              }
            ></CardHeader>
          </StyledHeaderCard>
        </Sticky>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={2}>
          <div id="sticky-drawer"></div>
          <StyledContent ref={contentRef} layout={layout}>
            <div style={{ minHeight: 300 }}>
              <GraphRelations onChangeLayout={onChangeLayout} layout={layout} />
            </div>
            <StyledDetailsPanelWrapper
              id="wpio-entity-person-details"
              layout={layout}
            >
              <ScrollableActionsWrapper
                location="right"
                boundaryElement="#wpio-entity-person-details-header"
              >
                {layout !== LAYOUT.ONLY_DETAILS &&
                  windowSize.width > columnViewPxBoundary && (
                    <GraphDetailsTogglerButton
                      onClick={() => onChangeLayout(LAYOUT.ONLY_DETAILS)}
                      ariaLabel="zwiń detale"
                      direction="right"
                    />
                  )}
              </ScrollableActionsWrapper>
              {type === TYPE.ENTITY ? (
                <EntitiesDetailsPanel />
              ) : (
                <PersonsDetailsPanel />
              )}
            </StyledDetailsPanelWrapper>
          </StyledContent>
        </Card>
      </Grid>
      <MUIDialog
        fullWidth
        maxWidth="lg"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          <span style={{marginRight: 10}}>Informacje identyfikacyjne podmiotu pochodzące ze źródeł SKANER</span>
          {sourcesResultIsLoading && <CircularLoader />}
        </StyledDialogTitle>
        <DialogContent>
          <SubSectionTitle>Nazwa podmiotu: </SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table} aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colspan={4}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colspan={4}>
                  <TableCell>{sourcesResult?.dane_ksi?.nazwa || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_sl?.nazwa || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_bk14?.nazwa || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_bk21?.nazwa || "-"}</TableCell>
                  <TableCell sx={{fontWeight: 300}}>{sourcesResult?.dane_krs?.nazwa || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_ceidg?.nazwa || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_crbr?.nazwa || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_vat?.nazwa || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Forma prawna: </SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table}  aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colspan={4}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colspan={4}>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{sourcesResult?.dane_krs?.forma_prawna || "-"}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{sourcesResult?.dane_crbr?.forma_prawna|| "-"}</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Numer NIP: </SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table}  aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colspan={4}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colspan={4}>
                  <TableCell>{sourcesResult?.dane_ksi?.nr_nip || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_sl?.nr_nip || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_bk14?.nr_nip || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_bk21?.nr_nip || "-"}</TableCell>
                  <TableCell sx={{fontWeight: 300}}>{sourcesResult?.dane_krs?.nr_nip || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_ceidg?.wlasciciel?.nr_nip || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_crbr?.nr_nip || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_vat?.nr_nip || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Numer KRS: </SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table}  aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colspan={4}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colspan={4}>
                  <TableCell>{sourcesResult?.dane_ksi?.nr_krs || "-"}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{sourcesResult?.dane_bk14?.nr_krs || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_bk21?.nr_krs || "-"}</TableCell>
                  <TableCell sx={{fontWeight: 300}}>{sourcesResult?.dane_krs?.nr_krs || "-"}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{sourcesResult?.dane_crbr?.nr_krs || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_vat?.nr_krs || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Numer REGON: </SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table}  aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colspan={4}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colspan={4}>
                  <TableCell>{sourcesResult?.dane_ksi?.nr_regon || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_sl?.nr_regon || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_bk14?.nr_regon || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_bk21?.nr_regon || "-"}</TableCell>
                  <TableCell sx={{fontWeight: 300}}>{sourcesResult?.dane_krs?.nr_regon || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_ceidg?.wlasciciel.nr_regon || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_crbr?.nr_regon || "-"}</TableCell>
                  <TableCell>{sourcesResult?.dane_vat?.nr_regon || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Adres podmiotu: </SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table}  aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colspan={4}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colspan={4}>
                  <TableCell>
                    {sourcesResult?.dane_ksi?.adres || "-"}
                  </TableCell>
                  <TableCell>
                    {sourcesResult?.dane_sl?.adres || "-"}
                  </TableCell>
                  <TableCell>
                    {sourcesResult?.dane_bk14?.adres || "-"}
                  </TableCell>
                  <TableCell>
                    {sourcesResult?.dane_bk21?.adres || "-"}
                  </TableCell>
                  <TableCell sx={{fontWeight: 300}}>
                    {sourcesResult?.dane_krs?.adres || "-"}
                  </TableCell>
                  <TableCell>
                    {sourcesResult?.dane_ceidg?.adres || "-"}
                  </TableCell>
                  <TableCell>
                    {sourcesResult?.dane_crbr?.adres || "-"}
                  </TableCell>
                  <TableCell>
                    {sourcesResult?.dane_vat?.adres || "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </MUIDialog>
    </>
  );
};

const Sticky = ({ children }) => {
  const isSticky = useRef(false);
  const stickyRef = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    const handleScroll = () => {
      if (!stickyRef.current) {
        return;
      }

      if (window.scrollY > 0) {
        setStyle(false);
        isSticky.current = true;
      } else {
        setStyle(true);
        isSticky.current = false;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickyRef.current]);

  useEffect(() => {
    if (isSticky.current) {
      setStyle(false);
    }
  }, [windowSize]);

  const setStyle = (reset = false) => {
    let drawerHeight;
    let navbarHeight;
    let top;

    if (!reset) {
      drawerHeight = document
        .querySelector('#drawer')
        .getBoundingClientRect().height;
      navbarHeight = document
        .querySelector('#nav-bar')
        .getBoundingClientRect().height;
      top = drawerHeight + navbarHeight;
    }

    document.querySelector('#sticky-drawer').style.height =
      (!reset && `${stickyRef.current.parentElement.offsetHeight}px`) || '';

    stickyRef.current.style.width =
      (!reset &&
        `${
          stickyRef.current.parentElement.offsetWidth -
          parseFloat(
            getComputedStyleByProperty(
              stickyRef.current.parentElement,
              'padding-left'
            )
          ) -
          parseFloat(
            getComputedStyleByProperty(
              stickyRef.current.parentElement,
              'padding-right'
            )
          )
        }px`) ||
      '';
    stickyRef.current.style.position = (!reset && 'fixed') || '';
    stickyRef.current.style.zIndex = (!reset && '2') || '';
    stickyRef.current.style.top = (!reset && `${top}px`) || '';
    stickyRef.current.style.paddingTop = (!reset && `${20}px`) || '';
    stickyRef.current.style.background = (!reset && `white`) || '';
    stickyRef.current.style.boxShadow =
      (!reset &&
        `0px 3px 1px -2px rgb(255 255 255), 0px 2px 2px 0px rgb(255 255 255), 0px 1px 5px 0px rgb(255 255 255)`) ||
      '';
  };

  return <div ref={stickyRef}>{children}</div>;
};

export default EntitiesPersonsDetails;
