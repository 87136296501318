import { TYPE } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { skanerTheme } from 'utils/skanerTheme';
import { hexToRgb } from 'utils/utils';

const svgEntity = (
  isMain = false,
  hexColor = skanerTheme.palette.primary
) => `<svg width="${isMain ? 24 : 18}" height="${
  isMain ? 24 : 18
}" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path fill="rgb(${hexToRgb(hexColor).join(
        ', '
      )})" d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z" />
    </svg>`;

const svgPerson = (
  isMain = false,
  hexColor = skanerTheme.palette.graphPerson
) => `<svg width="${isMain ? 24 : 18}" height="${
  isMain ? 24 : 18
}" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path fill="rgb(${hexToRgb(hexColor).join(
        ', '
      )})" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
    </svg>`;

const toSvgUrl = (svg) => encodeURI('data:image/svg+xml;utf-8,' + svg);

const svgEntityUrl = toSvgUrl(svgEntity());

const svgPersonUrl = toSvgUrl(svgPerson());

const nodeBaseStyle = {
  'transition-property': 'background-color border-color',
  'transition-duration': '0.3s',
  'transition-timing-function': 'ease-in-sine',
  'background-color': `${skanerTheme.palette.lightgray}`,
  label: 'data(title)',
  'font-weight': (node) => (node.data('isMain') && 'bold') || 'light',
  'font-size': (node) => (node.data('isMain') && '10px') || '8px',
  'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
  'text-valign': 'bottom',
  'text-wrap': 'ellipsis',
  'text-max-width': 120,
  'text-outline-color': `${skanerTheme.palette.lightgray}`,
  'text-outline-width': 2,
  'overlay-opacity': 0,
  'text-margin-y': 2,
  'text-wrap': 'wrap',
};

export default [
  {
    selector: `node[type="${TYPE.ENTITY}"]`,
    style: {
      ...nodeBaseStyle,
      'background-image': (node) =>
        (node.data('isMain') && toSvgUrl(svgEntity(true))) || svgEntityUrl,
    },
  },
  {
    selector: `node[type="${TYPE.PERSON}"]`,
    style: {
      ...nodeBaseStyle,
      'background-image': (node) =>
        (node.data('isMain') && toSvgUrl(svgPerson(true))) || svgPersonUrl,
    },
  },
  {
    selector: `:selected[type="${TYPE.ENTITY}"]`,
    css: {
      'background-image': (node) =>
        toSvgUrl(svgEntity(node.data('isMain'), skanerTheme.palette.white)),
      'background-color': `${skanerTheme.palette.primary}`,
    },
  },
  {
    selector: `:selected[type="${TYPE.PERSON}"]`,
    css: {
      'background-image': (node) =>
        toSvgUrl(svgPerson(node.data('isMain'), skanerTheme.palette.white)),
      'background-color': `${skanerTheme.palette.graphPerson}`,
    },
  },
  {
    selector: 'edge',
    style: {
      'curve-style': 'bezier',
      'text-rotation': 'autorotate',
      'target-arrow-shape': (edge) =>
        (edge.data('hasArrow') && 'triangle') || 'none',
      'target-arrow-color': (edge) =>
        (edge.data('isHistoric') && `#8A8A8A`) ||
        `${skanerTheme.palette.black}`,
      'line-color': (edge) =>
        (edge.data('isHistoric') && `#8A8A8A`) ||
        `${skanerTheme.palette.black}`,
      width: (edge) => (edge.data('isHistoric') && 0.5) || 0.7,
      'overlay-opacity': 0,
      label: (edge) =>
        (edge.data('titleIsVisible') && edge.data('title')) || '',
      'font-weight': 'lighter',
      'font-size': '6px',
      'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
      'text-wrap': 'ellipsis',
      'text-max-width': 80,
      'text-outline-color': `${skanerTheme.palette.lightgray}`,
      'text-outline-width': 2,
    },
  },
];
