import React from "react"
import logo from "./skanerlogo.png"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { skanerRoutes } from "routes/skanerRoutes"

const StyledWrapper = styled.div`
  display: flex;
  width: 175px;
  margin-right: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 62px;

  img {
    position: absolute;
    top: 4px;
  }

  > a {
    min-width: 145px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const LogoSkaner = ({ ...props }) => {
  return (
    <StyledWrapper {...props}>
      <Link to={skanerRoutes.home}>
        <img src={logo} alt="logo skaner" />
      </Link>
    </StyledWrapper>
  )
}

export default LogoSkaner
