import { useSize } from 'hooks/useSize';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useEffect, useRef, useState } from 'react';

const StickedScrollbar = ({
  children,
  bottomOffset = 0,
  onScrollElements = [],
}) => {
  const scrollRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const [style, setStyle] = useState({ height: 20 });
  const [opacity, setOpacity] = useState(1);
  const [width, setWidth] = useState('100%');
  const windowSize = useWindowSize();
  const size = useSize(tableWrapperRef);

  const stickedScrollbarOnScroll = () => {
    tableWrapperRef.current.scrollLeft = scrollRef.current.scrollLeft;
  };

  const tableScrollbarOnScroll = () => {
    scrollRef.current.scrollLeft = tableWrapperRef.current.scrollLeft;
  };

  const isInViewport = (rect) => {
    return rect.top + 100 <= window.innerHeight; // 100 offset for table head
  };

  const handleOpacity = () => {
    const rect = tableWrapperRef.current.getBoundingClientRect();
    const height = rect.height + rect.top;
    if (window.innerHeight >= height || !isInViewport(rect)) {
      setOpacity(0);
    } else {
      setOpacity(1);
    }
  };

  useEffect(() => {
    handleOpacity();
  }, [windowSize]);

  useEffect(() => {
    if (tableWrapperRef && tableWrapperRef.current) {
      const tableWidth = tableWrapperRef.current
        .getElementsByTagName('table')[0]
        .getBoundingClientRect().width;
      setStyle({ ...style, width: tableWidth });
      setWidth(tableWrapperRef.current.getBoundingClientRect().width);
    }
  }, [tableWrapperRef, windowSize, size]);

  useEffect(() => {
    const handleScroll = () => {
      handleOpacity();
    };
    const elements = [
      window,
      ...onScrollElements.map((el) => document.querySelector(el)),
    ];

    elements.forEach(
      (el) => el !== null && el.addEventListener('scroll', handleScroll)
    );

    return () => {
      elements.forEach(
        (el) => el !== null && el.removeEventListener('scroll', handleScroll)
      );
    };
  }, [windowSize]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          height: 20,
          overflowX: 'scroll',
          overflowY: 'hidden',
          opacity,
          position: 'fixed',
          bottom: bottomOffset,
          zIndex: 1,
          width,
        }}
        onScroll={stickedScrollbarOnScroll}
        ref={scrollRef}
      >
        <div style={style}></div>
      </div>
      <div
        ref={tableWrapperRef}
        style={{ overflowY: 'auto' }}
        onScroll={tableScrollbarOnScroll}
      >
        {children}
      </div>
    </div>
  );
};

export default StickedScrollbar;
