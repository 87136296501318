import React, { useContext, useEffect, useRef, useState } from "react"
import { Button } from "components/shared/Button/Button"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer"
import moment from "moment"
import CircularLoader from "components/shared/Loader/CircularLoader"
import { skanerTheme } from "utils/skanerTheme"
import { saveFile } from "utils/utils"
import font from "fonts/Roboto-Regular.ttf"
import fontBold from "fonts/Roboto-Bold.ttf"
import { Prompt, useHistory, useRouteMatch } from "react-router-dom"
import PersonsContext from "contexts/wpio/PersonsContext"
import { TYPE } from "components/wpio/EntitiesPersons/EntitiesPersonsConsts"
import EntitiesContext from "contexts/wpio/EntitiesContext"
import { wpioRoutes } from "routes/wpioRoutes"
import EntitiesObjectSectionPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesObjectSectionPdf"
import EntitiesPersonsEuFundingSectionPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesPersonsEuFundingSectionPdf"
import EntitiesPersonsHeaderPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesPersonsHeaderPdf"
import EntitiesPersonsIdentificationSectionPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesPersonsIdentificationSectionPdf"
import EntitiesPersonsGraphPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesPersonsGraphPdf"
import EntitiesNamesHistoryPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesNamesHistoryPdf"
import EntitiesBasicInfoPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesBasicInfoSectionPdf"
import EntitiesRelationsSectionPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesRelationsSectionPdf"
import PersonsRelationsSectionPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/PersonsRelationsSectionPdf"
import EntitiesFinancesSectionPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesFinancesSectionPdf"
import EntitiesTransformationSectionPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesTransformationSectionPdf"
import EntitiesPersonsPublicProcurementSectionPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesPersonsPublicProcurementSectionPdf"
import EntitiesUnitsSectionsPdf from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesUnitsSectionsPdf"
import { pageStyle } from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/baseStyles"
import EntitiesStatusVatSectionPdf from "./EntitiesStatusVatSectionPdf"
import EntitiesSourcesPdf from "./EntitiesSourcesPdf"
import { Dialog } from "components/shared/Modal/Modal"
import { getPath } from "../../../../../routes/routesUtils"
import { skanerRoutes } from "../../../../../routes/skanerRoutes"
import EntitiesService from "../../../../../services/wpio/EntitiesService"

const DEFAULT_DIALOG_OPTIONS = { isOpen: false, generatingTimeMessage: null };

const EntitiesPersonsExportPdf = ({ type }) => {
  const workerRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [dialogOptions, setDialogOptions] = useState(DEFAULT_DIALOG_OPTIONS);
  const {
    params: { id },
  } = useRouteMatch()
  const history = useHistory()
  const {
    details: { result },
    sections: {
      isLoading: sectionsIsLoading,
      relations,
      units,
      finances,
      euFunding,
      publicProcurement,
      transformation,
      object,
    },
    graph: { graphImage },
  } = useContext(type === TYPE.ENTITY ? EntitiesContext : PersonsContext)

  const handleClick = async () => {
    if(publicProcurement.data) {
      const publicProcurementCount = publicProcurement?.data?.lista_BK21.length + publicProcurement?.data?.lista_BK14.length
      
      if(publicProcurementCount < 151) {
        await handleGeneretePDF();
        return;
      }

      if(publicProcurementCount >= 151 && publicProcurementCount <= 350) {
        setDialogOptions({isOpen: true, generatingTimeMessage: "do 30min."})
        return;
      }

      if(publicProcurementCount >= 351 && publicProcurementCount <= 500) {
        setDialogOptions({isOpen: true, generatingTimeMessage: "do 1h."})
        return;
      }

      if(publicProcurementCount > 500) {
        setDialogOptions({isOpen: true, generatingTimeMessage: "ponad 1h."})
        return;
      }
    } else {
      await handleGeneretePDF();
    }
  }

  function beforeUnloadHandler(e){
    e.returnValue = 'Data may not be saved';
  }

  const handleGeneretePDF = async () => {
    setIsPdfGenerating(true)
    window.addEventListener("beforeunload", beforeUnloadHandler, true);
    try {
      let sourcesResult;
      if(type === TYPE.ENTITY) {
        sourcesResult = await EntitiesService.getDivergenceOfSources(id);
      }
      const generationDate = new Date()
      const title = `${
        type === TYPE.ENTITY ? "Podmiot" : "Osoba"
      }_${id}_${moment(generationDate).format("YYYYMMDD_HHmmss")}`

      workerRef.current = new Worker(
        new URL("./pdfGen.worker.js", import.meta.url)
      )
      workerRef.current.postMessage({
        id,
        title,
        result,
        generationDate,
        graphImage,
        relations,
        finances,
        units,
        object,
        euFunding,
        transformation,
        publicProcurement,
        type,
        sourcesResult
      })
      workerRef.current.onmessage = (event) => {
        saveFile(event.data, `${title}.pdf`)
        window.removeEventListener('beforeunload', beforeUnloadHandler, true);
        workerRef.current.terminate()
        setIsPdfGenerating(false)
      }
    } catch (error) {
      history.push(wpioRoutes.errors[500])
    }
  }

  useEffect(() => {
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate()
      }
    }
  }, [])


  return (
    <>
      <Prompt
        when={isPdfGenerating}
        message={JSON.stringify({
          text: "Jeśli opuścisz tę stronę, generowanie PDF-a zostanie przerwane. Jesteś pewien?",
          confirmText: "Tak, opuść stronę",
          cancelText: "Zostań na stronie",
        })}
      />
      <Dialog
        isOpen={dialogOptions.isOpen && dialogOptions.generatingTimeMessage !== null}
        handleClose={() =>
          setDialogOptions(DEFAULT_DIALOG_OPTIONS)
        }
        customCloseText="ANULUJ"
        handleConfirm={() => {
          setDialogOptions(DEFAULT_DIALOG_OPTIONS)
          handleGeneretePDF()
        }}
        customConfirmText="TAK, GENERUJ PDF"
        title="Ostrzeżenie"
        text={`Czas generowania PDF dla tego podmiotu może wynieść ${dialogOptions.generatingTimeMessage}`}
        dialog

        isLoading={false}
      ></Dialog>
      <Button
        type="button"
        variant="contained"
        customcolor="white"
        onClick={handleClick}
        className="card-header-btn"
        disabled={isLoading || sectionsIsLoading || !graphImage || isPdfGenerating}
        aria-label="pobierz pdf"
      >
        {((isLoading || sectionsIsLoading || !graphImage || isPdfGenerating) && (
          <>
            {isPdfGenerating && <span style={{marginRight: "10px"}}>Trwa generowanie PDF...</span>}
            <CircularLoader svgcolor={skanerTheme.palette.primary} />
          </>
        )) ||
          "Pobierz PDF"}
      </Button>
    </>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  entityPersonId: {
    fontSize: 16,
  },
  divider: {
    marginTop: 10,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: skanerTheme.palette.primary,
  },
  mtb10: {
    marginTop: 10,
    marginBottom: 10,
  },
  generationDate: {
    marginLeft: "auto",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "gray",
  },
})

Font.register({
  family: "Roboto",
  fonts: [{ src: font }, { src: fontBold, fontWeight: 700 }],
})

export const PdfPageNumber = () => {
  return (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  )
}

export const PdfDocument = ({
  id,
  title,
  details,
  generationDate,
  graph,
  relations,
  finances,
  transformation,
  units,
  object,
  euFunding,
  publicProcurement,
  type,
  sourcesResult,
}) => {
  return (
    <Document title={title} language="PL">
      <Page style={{ ...pageStyle }}>
        <View style={styles.header}>
          <View style={styles.entityPersonId}>
            <Text>
              Szczegóły {type === TYPE.ENTITY ? "podmiotu" : "osoby"} #{id}
            </Text>
          </View>
          <View style={styles.generationDate}>
            <View>
              <Text style={styles.key}>Data wygenerowania</Text>
            </View>
            <View>
              <Text>
                {moment(generationDate).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.divider}></View>
        <EntitiesPersonsHeaderPdf details={details} type={type} />
        <EntitiesPersonsIdentificationSectionPdf
          details={details}
          type={type}
        />
        {type === TYPE.ENTITY && (
          <EntitiesNamesHistoryPdf history={details.nazwy_historyczne} />
        )}
        <PdfPageNumber />
      </Page>
      {type === TYPE.ENTITY && <EntitiesSourcesPdf sourcesResult={sourcesResult}/>}
      <EntitiesPersonsGraphPdf graph={graph} />
      {type === TYPE.ENTITY && <EntitiesBasicInfoPdf details={details} />}
      {(type === TYPE.ENTITY && (
        <EntitiesRelationsSectionPdf relations={relations.data} />
      )) || <PersonsRelationsSectionPdf relations={relations.data} />}
      {type === TYPE.ENTITY && (
        <EntitiesFinancesSectionPdf finances={finances.data} />
      )}
      {type === TYPE.ENTITY && <EntitiesUnitsSectionsPdf units={units.data} />}
      {type === TYPE.ENTITY && (
        <EntitiesTransformationSectionPdf
          transformation={transformation.data}
        />
      )}
      {type === TYPE.ENTITY && (
        <EntitiesObjectSectionPdf object={object.data} />
      )}
      {type === TYPE.ENTITY && (
        <EntitiesStatusVatSectionPdf statusVat={details.dane_vat} />
      )}
      <EntitiesPersonsEuFundingSectionPdf euFunding={euFunding.data} />
      <EntitiesPersonsPublicProcurementSectionPdf
        publicProcurement={publicProcurement.data}
      />
    </Document>
  )
}

export default EntitiesPersonsExportPdf
