import { useState, useEffect } from "react"
import { http } from "services/http"

export const useApi = (url, skip) => {
  const [result, setResult] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [refreshIndex, setRefreshIndex] = useState(0)

  const refresh = () => {
    setRefreshIndex(refreshIndex + 1)
  }

  useEffect(() => {
    let cancelled = false
    if (skip) {
      setResult(null)
      setIsLoading(false)
      setLoaded(false)
    } else {
      setIsLoading(true)
      http
        .get(url)
        .then((r) => {
          if (!cancelled) {
            setResult(r.data)
            setIsLoading(false)
            setLoaded(true)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          if (error.response) {
            setError({
              status: error.response.status,
              message: error.response.data,
            })
          } else {
            setError({ message: error.message })
          }
        })
    }
    return () => {
      cancelled = true
    }
  }, [url, refreshIndex, skip])

  return { result, isLoading, loaded, error, refresh, setResult }
}
