import React from "react"
import {
  Divider,
  ErrorMessage,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
} from "components/wpio/EntitiesPersons/EntitiesPersonsShared"
import { isNUllOrEmptyOrUndefined } from "utils/utils"
import { errorMessage } from "components/wpio/EntitiesPersons/EntitiesPersonsConsts"
import { Link as RouterLink } from "react-router-dom"
import Link from "@material-ui/core/Link"
import { wpioRoutes } from "routes/wpioRoutes"
import { getPath } from "routes/routesUtils"
import Chip from "@material-ui/core/Chip"
import styled from "styled-components"
import {
  StyledLabel,
  StyledLabelValue,
} from "components/wpio/EntitiesPersons/EntitiesPersonsShared"

const StyledChip = styled(Chip)`
  font-size: 0.6rem;
  height: 24px;
  margin-left: 16px;
  margin-top: -5px;
`

const EntitiesRelationsSection = ({ relations, error }) => {
  if (error) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>
  }

  Object.keys(relations).forEach((k) => {
    if (relations[k] && relations[k].length > 0) {
      relations[k].forEach((x) => {
        if (x.dane_sformatowane === null) {
          x.dane_sformatowane = {}
        }
      })
    }
  })

  const {
    akcjonariusze,
    prokurenci,
    wspolnicy,
    organ_uprawniony_do_reprezentowania_spolki,
    organ_nadzoru,
    beneficjenci_rzeczywisci,
    reprezentanci,
    wlasciciel,
  } = relations

  const shouldShowDivider = (nextElements) => {
    return nextElements.some((el) => el && el.length > 0)
  }

  return (
    <>
      {organ_uprawniony_do_reprezentowania_spolki &&
        organ_uprawniony_do_reprezentowania_spolki.length > 0 && (
          <div>
            <SubSectionTitle>Organ reprezentacji</SubSectionTitle>
            {organ_uprawniony_do_reprezentowania_spolki.map(
              ({
                id_podmiot,
                dane_data_od,
                dane_data_do,
                rola,
                typ,
                dane_sformatowane: { nazwa, numer_krs, numer_regon },
              }) => (
                <div
                  key={`${id_podmiot}_${rola}_${dane_data_od}_${dane_data_do}`}
                  style={{ marginBottom: 16 }}
                >
                  <LabelValue
                    label=""
                    value={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Link
                          component={RouterLink}
                          to={getPath(
                            typ === "Podmiot"
                              ? wpioRoutes.entitiesPersons.entityDetails
                              : wpioRoutes.entitiesPersons.personDetails,
                            id_podmiot
                          )}
                        >
                          {nazwa}
                        </Link>
                        <StyledChip label={rola} />
                      </div>
                    }
                  />
                  <LabelValueRow>
                    {!isNUllOrEmptyOrUndefined(numer_krs) && (
                      <LabelValue label="KRS" value={numer_krs} />
                    )}
                    {!isNUllOrEmptyOrUndefined(numer_regon) && (
                      <LabelValue label="REGON" value={numer_regon} />
                    )}
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${
                        (!isNUllOrEmptyOrUndefined(dane_data_do) &&
                          dane_data_do !== "9999-12-31" &&
                          dane_data_do) ||
                        "obecnie"
                      }`}
                    />
                  </LabelValueRow>
                </div>
              )
            )}
            {shouldShowDivider([
              organ_nadzoru,
              prokurenci,
              wspolnicy,
              akcjonariusze,
              beneficjenci_rzeczywisci,
              reprezentanci,
              wlasciciel,
            ]) && <Divider />}
          </div>
        )}
      {organ_nadzoru && organ_nadzoru.length > 0 && (
        <div>
          <SubSectionTitle>Organ nadzoru</SubSectionTitle>
          {organ_nadzoru.map(
            ({
              id_podmiot,
              dane_data_od,
              dane_data_do,
              rola,
              typ,
              dane_sformatowane: { nazwa },
            }) => (
              <div
                key={`${id_podmiot}_${rola}_${dane_data_od}_${dane_data_do}`}
                style={{ marginBottom: 16 }}
              >
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        component={RouterLink}
                        to={getPath(
                          typ === "Podmiot"
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          id_podmiot
                        )}
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  <LabelValue
                    label="Okres"
                    value={`${dane_data_od} – ${
                      (!isNUllOrEmptyOrUndefined(dane_data_do) &&
                        dane_data_do !== "9999-12-31" &&
                        dane_data_do) ||
                      "obecnie"
                    }`}
                  />
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            prokurenci,
            wspolnicy,
            akcjonariusze,
            beneficjenci_rzeczywisci,
            reprezentanci,
            wlasciciel,
          ]) && <Divider />}
        </div>
      )}
      {prokurenci && prokurenci.length > 0 && (
        <div>
          <SubSectionTitle>Prokurenci</SubSectionTitle>
          {prokurenci.map(
            ({
              id_podmiot,
              dane_data_od,
              dane_data_do,
              rola,
              typ,
              dane_sformatowane: { nazwa, rodzaj_prokury },
            }) => (
              <div
                key={`${id_podmiot}_${rola}_${dane_data_od}_${dane_data_do}`}
                style={{ marginBottom: 16 }}
              >
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        component={RouterLink}
                        to={getPath(
                          typ === "Podmiot"
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          id_podmiot
                        )}
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {!isNUllOrEmptyOrUndefined(rodzaj_prokury) && (
                    <LabelValue label="Rodzaj prokury" value={rodzaj_prokury} />
                  )}
                  <LabelValue
                    label="Okres"
                    value={`${dane_data_od} – ${
                      (!isNUllOrEmptyOrUndefined(dane_data_do) &&
                        dane_data_do !== "9999-12-31" &&
                        dane_data_do) ||
                      "obecnie"
                    }`}
                  />
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            wspolnicy,
            akcjonariusze,
            beneficjenci_rzeczywisci,
            reprezentanci,
            wlasciciel,
          ]) && <Divider />}
        </div>
      )}
      {wspolnicy && wspolnicy.length > 0 && (
        <div>
          <SubSectionTitle>Wspólnicy</SubSectionTitle>
          {wspolnicy.map(
            ({
              id_podmiot,
              dane_data_od,
              dane_data_do,
              typ,
              rola,
              dane_sformatowane: {
                nazwa,
                numer_krs,
                numer_regon,
                posiadane_przez_wspolnika_udzialy,
                czy_wspolnik_posiada_calosc_udzialow_spolki,
              },
            }) => (
              <div
                key={`${id_podmiot}_${rola}_${dane_data_od}_${dane_data_do}`}
                style={{ marginBottom: 16 }}
              >
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        component={RouterLink}
                        to={getPath(
                          typ === "Podmiot"
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          id_podmiot
                        )}
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {!isNUllOrEmptyOrUndefined(numer_krs) && (
                    <LabelValue label="KRS" value={numer_krs} />
                  )}
                  {!isNUllOrEmptyOrUndefined(numer_regon) && (
                    <LabelValue label="REGON" value={numer_regon} />
                  )}
                  {!isNUllOrEmptyOrUndefined(
                    posiadane_przez_wspolnika_udzialy
                  ) && (
                    <LabelValue
                      label="Udziały"
                      value={posiadane_przez_wspolnika_udzialy}
                    />
                  )}
                  {!isNUllOrEmptyOrUndefined(
                    czy_wspolnik_posiada_calosc_udzialow_spolki
                  ) && (
                    <LabelValue
                      label="Posiada całość udziałów"
                      value={czy_wspolnik_posiada_calosc_udzialow_spolki}
                    />
                  )}
                  <LabelValue
                    label="Okres"
                    value={`${dane_data_od} – ${
                      (!isNUllOrEmptyOrUndefined(dane_data_do) &&
                        dane_data_do !== "9999-12-31" &&
                        dane_data_do) ||
                      "obecnie"
                    }`}
                  />
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            akcjonariusze,
            beneficjenci_rzeczywisci,
            reprezentanci,
            wlasciciel,
          ]) && <Divider />}
        </div>
      )}
      {akcjonariusze && akcjonariusze.length > 0 && (
        <div>
          <SubSectionTitle>Akcjonariusze</SubSectionTitle>
          {akcjonariusze.map(
            ({
              id_podmiot,
              dane_data_od,
              dane_data_do,
              typ,
              dane_sformatowane: {
                nazwa,
                numer_krs,
                czy_wspolnik_posiada_calosc_akcji_spolki,
              },
            }) => (
              <div
                key={`${id_podmiot}_${dane_data_od}_${dane_data_do}`}
                style={{ marginBottom: 16 }}
              >
                <LabelValue
                  label=""
                  value={
                    <Link
                      component={RouterLink}
                      to={getPath(
                        typ === "Podmiot"
                          ? wpioRoutes.entitiesPersons.entityDetails
                          : wpioRoutes.entitiesPersons.personDetails,
                        id_podmiot
                      )}
                    >
                      {nazwa}
                    </Link>
                  }
                />
                <LabelValueRow>
                  {!isNUllOrEmptyOrUndefined(numer_krs) && (
                    <LabelValue label="KRS" value={numer_krs} />
                  )}
                  {!isNUllOrEmptyOrUndefined(
                    czy_wspolnik_posiada_calosc_akcji_spolki
                  ) && (
                    <LabelValue
                      label="Posiada całość akcji spółki"
                      value={czy_wspolnik_posiada_calosc_akcji_spolki}
                    />
                  )}
                  <LabelValue
                    label="Okres"
                    value={`${dane_data_od} – ${
                      (!isNUllOrEmptyOrUndefined(dane_data_do) &&
                        dane_data_do !== "9999-12-31" &&
                        dane_data_do) ||
                      "obecnie"
                    }`}
                  />
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            beneficjenci_rzeczywisci,
            reprezentanci,
            wlasciciel,
          ]) && <Divider />}
        </div>
      )}
      {beneficjenci_rzeczywisci && beneficjenci_rzeczywisci.length > 0 && (
        <div>
          <SubSectionTitle>Beneficjenci rzeczywiści</SubSectionTitle>
          {beneficjenci_rzeczywisci.map(
            ({
              id_podmiot,
              typ,
              rola,
              dane_sformatowane: {
                nazwa,
                nr_pesel,
                kraj_zamieszkania,
                obywatelstwo,
                rodzaj_uprawnien_wlascicielskich,
                rodzaje_uprzywilejowan,
                kod_uprzywilejowania,
                ilosc,
                inne_uprawnienia,
                uprawnienia_wlascicielskie_posrednie,
              },
            }) => (
              <div key={`${id_podmiot}_${rola}`} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        component={RouterLink}
                        to={getPath(
                          typ === "Podmiot"
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          id_podmiot
                        )}
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {!isNUllOrEmptyOrUndefined(nr_pesel) && (
                    <LabelValue label="PESEL" value={nr_pesel} />
                  )}
                  {!isNUllOrEmptyOrUndefined(kraj_zamieszkania) && (
                    <LabelValue
                      label="Kraj zamieszkania"
                      value={kraj_zamieszkania}
                    />
                  )}
                  {!isNUllOrEmptyOrUndefined(obywatelstwo) && (
                    <LabelValue label="Obywatelstwo" value={obywatelstwo} />
                  )}
                  {!isNUllOrEmptyOrUndefined(rodzaje_uprzywilejowan) && (
                    <StyledLabelValue>
                      <StyledLabel>Kod - Rodzaj uprzywilejowania</StyledLabel>
                      {rodzaje_uprzywilejowan.map((x) => (
                        <div key={x}>
                          {x.kod} - {x.opis}
                        </div>
                      ))}
                    </StyledLabelValue>
                  )}

                  {!isNUllOrEmptyOrUndefined(
                    rodzaj_uprawnien_wlascicielskich
                  ) && (
                    <LabelValue
                      label="Rodzaj uprawnień właścicielskich"
                      value={rodzaj_uprawnien_wlascicielskich}
                    />
                  )}
                  {!isNUllOrEmptyOrUndefined(kod_uprzywilejowania) && (
                    <LabelValue
                      label="Kod uprzywilejowania"
                      value={kod_uprzywilejowania}
                    />
                  )}
                  {!isNUllOrEmptyOrUndefined(ilosc) && (
                    <LabelValue label="Ilość uprawnień" value={ilosc} />
                  )}
                  {!isNUllOrEmptyOrUndefined(inne_uprawnienia) && (
                    <LabelValue
                      label="Inne uprawnienia"
                      value={inne_uprawnienia}
                    />
                  )}
                  {!isNUllOrEmptyOrUndefined(
                    uprawnienia_wlascicielskie_posrednie
                  ) && (
                    <LabelValue
                      style={{ flexBasis: "100%" }}
                      label="Uprawnienia właścicielskie pośrednie"
                      value={uprawnienia_wlascicielskie_posrednie}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([reprezentanci, wlasciciel]) && <Divider />}
        </div>
      )}
      {reprezentanci && reprezentanci.length > 0 && (
        <div>
          <SubSectionTitle>Reprezentanci</SubSectionTitle>
          {reprezentanci.map(
            ({
              id_podmiot,
              typ,
              rola,
              dane_sformatowane: {
                nazwa,
                nr_pesel,
                kraj_zamieszkania,
                obywatelstwo,
              },
            }) => (
              <div key={`${id_podmiot}_${rola}`} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        component={RouterLink}
                        to={getPath(
                          typ === "Podmiot"
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          id_podmiot
                        )}
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {!isNUllOrEmptyOrUndefined(nr_pesel) && (
                    <LabelValue label="PESEL" value={nr_pesel} />
                  )}
                  {!isNUllOrEmptyOrUndefined(kraj_zamieszkania) && (
                    <LabelValue
                      label="Kraj zamieszkania"
                      value={kraj_zamieszkania}
                    />
                  )}
                  {!isNUllOrEmptyOrUndefined(obywatelstwo) && (
                    <LabelValue label="Obywatelstwo" value={obywatelstwo} />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([wlasciciel]) && <Divider />}
        </div>
      )}
      {wlasciciel && wlasciciel.length > 0 && (
        <div>
          <SubSectionTitle>Właściciel</SubSectionTitle>
          {wlasciciel.map(
            ({
              id_podmiot,
              typ,
              rola,
              dane_data_od,
              dane_data_do,
              dane_sformatowane: { wlasciciel },
            }) => (
              <div key={`${id_podmiot}_${rola}`} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        component={RouterLink}
                        to={getPath(
                          typ === "Podmiot"
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          id_podmiot
                        )}
                      >
                        {wlasciciel.nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  <LabelValue
                    label="Okres"
                    value={`${dane_data_od} – ${
                      (!isNUllOrEmptyOrUndefined(dane_data_do) &&
                        dane_data_do !== "9999-12-31" &&
                        dane_data_do) ||
                      "obecnie"
                    }`}
                  />
                </LabelValueRow>
              </div>
            )
          )}
        </div>
      )}
    </>
  )
}

export default EntitiesRelationsSection
