import React from 'react';
import TopBar from 'components/skaner/Layout/TopBar';
import DrawerHeader from 'components/skaner/Layout/DrawerHeader';
import ModuleContent from 'components/skaner/Layout/ModuleContent';
import SamplerListView from 'views/sampler/SamplerListView';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

const SamplerTemplate = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <TopBar moduleTitle="Sampler" />
      <div style={{ width: '100%' }}>
        <DrawerHeader />
        <ModuleContent>
          <Switch>
            <Route exact path={path}>
              <SamplerListView />
            </Route>
          </Switch>
        </ModuleContent>
      </div>
    </>
  );
};

export default SamplerTemplate;
