import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import SvgIcon from '@material-ui/core/SvgIcon';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { TYPE } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { skanerTheme } from 'utils/skanerTheme';

const StyledEntityPersonCardInfoWrapper = styled.div`
  position: absolute;
  z-index: 1;
  margin: 8px;
  overflow-y: auto;
  top: -4px;
  right: -4px;
  left: 38px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  font-size: 0.7rem;
  height: 28px;
  margin-left: 55px;
  margin-top: 8px;
`;

const StyledHeaderDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 55px;
  margin-top: 16px;

  > div:not(:first-child) {
    margin-left: 32px;
  }
`;

const StyledKeyValue = styled.div`
  display: flex;
`;

const StyledKey = styled.div`
  font-weight: bold;
`;

const StyledValue = styled.div`
  color: ${skanerTheme.palette.secondary};
  margin-left: 8px;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
  background-color: ${({ type }) =>
    (type === TYPE.ENTITY && skanerTheme.palette.primary) ||
    skanerTheme.palette.graphPerson};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 30px;
    color: ${skanerTheme.palette.white};
  }
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: flex-end;

  a {
    text-decoration: none !important;
  }
`;

const GraphEntityPersonCardInfo = ({
  data: { nodeId, type, title, ...rest },
  onShowRelations,
}) => {
  const {
    params: { id },
  } = useRouteMatch();

  return (
    <StyledEntityPersonCardInfoWrapper>
      <Card variant="outlined">
        <CardContent>
          <StyledHeader>
            <StyledAvatar variant="rounded" type={type}>
              <SvgIcon>
                {(type === TYPE.ENTITY && (
                  <path
                    fill="currentColor"
                    d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z"
                  />
                )) || (
                  <path
                    fill="currentColor"
                    d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                  />
                )}
              </SvgIcon>
            </StyledAvatar>
            <div>
              <Typography variant="h6">{title}</Typography>
            </div>
          </StyledHeader>
          {(type === TYPE.ENTITY && <EntityCardInfoPartial {...rest} />) || (
            <PersonCardInfoPartial {...rest} />
          )}
        </CardContent>
        <StyledCardActions>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => onShowRelations(nodeId, type)}
          >
            Wyświetl powiązania
          </Button>
          {+nodeId !== +id && (
            <Link
              component={RouterLink}
              to={`/wpio/${
                type === TYPE.ENTITY ? 'podmiot' : 'osoba'
              }/${nodeId}`}
            >
              <Button size="small" variant="outlined" color="primary">
                Wyświetl szczegóły
              </Button>
            </Link>
          )}
        </StyledCardActions>
      </Card>
    </StyledEntityPersonCardInfoWrapper>
  );
};

const EntityCardInfoPartial = ({
  nr_regon,
  nr_krs,
  nr_nip,
  forma_prawna,
  wlasciciel,
}) => {
  return (
    <>
      {forma_prawna && <StyledChip label={forma_prawna} />}
      <StyledHeaderDetails>
        <StyledKeyValue>
          <StyledKey>NIP:</StyledKey>
          <StyledValue>
            {nr_nip || (wlasciciel && wlasciciel.nr_nip) || 'Brak'}
          </StyledValue>
        </StyledKeyValue>
        <StyledKeyValue>
          <StyledKey>KRS:</StyledKey>
          <StyledValue>
            {nr_krs || (wlasciciel && wlasciciel.nr_krs) || 'Brak'}
          </StyledValue>
        </StyledKeyValue>
        <StyledKeyValue>
          <StyledKey>REGON:</StyledKey>
          <StyledValue>
            {nr_regon || (wlasciciel && wlasciciel.nr_regon) || 'Brak'}
          </StyledValue>
        </StyledKeyValue>
      </StyledHeaderDetails>
    </>
  );
};

const PersonCardInfoPartial = ({ data_urodzenia, nr_pesel }) => {
  return (
    <StyledHeaderDetails>
      <StyledKeyValue>
        <StyledKey>PESEL:</StyledKey>
        <StyledValue>{nr_pesel || 'Brak'}</StyledValue>
      </StyledKeyValue>
      <StyledKeyValue>
        <StyledKey>DATA URODZENIA:</StyledKey>
        <StyledValue>{data_urodzenia || 'Brak'}</StyledValue>
      </StyledKeyValue>
    </StyledHeaderDetails>
  );
};

export default GraphEntityPersonCardInfo;
