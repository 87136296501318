import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from 'contexts/shared/UserContext';
import Loader from 'components/shared/Loader/Loader';
import { skanerRoutes } from 'routes/skanerRoutes';
import { roles } from 'utils/roles';

const PrivateRoute = ({ children, requiredRole = null, ...rest }) => {
  const { user, isLoading } = useContext(UserContext);
  const userHasNoRequiredRoles = user && !user.roles.some((role) => requiredRole.includes(role))
  if (
    !isLoading &&
    requiredRole &&
    userHasNoRequiredRoles
  ) {
    return <Redirect push to={skanerRoutes.errors[403]} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoading ? (
          user && user.isLoggedIn ? (
            children
          ) : (
            <Redirect
              to={{ pathname: skanerRoutes.login, state: { from: location } }}
            />
          )
        ) : (
          <Loader />
        )
      }
    />
  );
};

export default PrivateRoute;
