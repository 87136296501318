import React from 'react';
import { Text, View, StyleSheet, Page } from '@react-pdf/renderer';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/SourcesTablePdf"
import {
  divider,
  pageStyle,
} from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/baseStyles"
import { PdfPageNumber } from "./EntitiesPersonsExportPdf"

const styles = StyleSheet.create({
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  tableCell: {
    textAlign: "center",
    fontSize: 10,
    padding: 0,
  }
});

const TableHeadComponent = () => {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell width={40}>
            <Text style={styles.tableCell}>CST</Text>
          </TableCell>
          <TableCell width={15} >
            <Text style={styles.tableCell}>KRS</Text>
          </TableCell>
          <TableCell width={15}>
            <Text style={styles.tableCell}>CEIDG</Text>
          </TableCell>
          <TableCell width={15}>
            <Text style={styles.tableCell}>CRBR</Text>
          </TableCell>
          <TableCell width={15}>
            <Text style={styles.tableCell}>VAT</Text>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableHead>
        <TableRow>
          <TableCell width={10} >
            <Text style={styles.tableCell}>KSI 07-13</Text>
          </TableCell>
          <TableCell width={10}>
            <Text style={styles.tableCell}>SL2014</Text>
          </TableCell>
          <TableCell width={10} >
            <Text style={styles.tableCell}>BK14</Text>
          </TableCell>
          <TableCell width={10}>
            <Text style={styles.tableCell}>BK21</Text>
          </TableCell>
          <TableCell width={60}></TableCell>
        </TableRow>
      </TableHead>
    </>
  )
}

const EntitiesSourcesPdf = ({ sourcesResult }) => {
  return (
    <Page style={{ ...pageStyle }}>
      <View style={{ marginTop: 30 }}>
        <View style={styles.sectionTitle}>
          <Text>Informacje identyfikacyjne podmiotu pochodzące ze źródeł SKANER</Text>
        </View>
        <View style={{ ...divider }} />
        <View>
          <Text style={{marginBottom: "10px"}}>Nazwa podmiotu:</Text>
        </View>
        <Table>
          <TableHeadComponent />
          <TableBody>
            <TableRow>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_ksi?.nazwa || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_sl?.nazwa || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_bk14?.nazwa || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_bk21?.nazwa || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_krs?.nazwa || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_ceidg?.nazwa || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_crbr?.nazwa || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_vat?.nazwa || "-"}</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <View>
          <Text style={{marginBottom: "10px", marginTop: "10px"}}>Forma prawna:</Text>
        </View>
        <Table>
          <TableHeadComponent />
          <TableBody>
            <TableRow>
              <TableCell width={10} style={styles.tableCell}>
                <Text>-</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>-</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>-</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>-</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_krs?.forma_prawna || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>-</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_crbr?.forma_prawna|| "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>-</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <View>
          <Text style={{marginBottom: "10px", marginTop: "10px"}}>Numer NIP:</Text>
        </View>
        <Table>
          <TableHeadComponent />
          <TableBody>
            <TableRow>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_ksi?.nr_nip || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_sl?.nr_nip || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_bk14?.nr_nip || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_bk21?.nr_nip || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_krs?.nr_nip || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_ceidg?.wlasciciel?.nr_nip || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_crbr?.nr_nip || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_vat?.nr_nip || "-"}</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <View>
          <Text style={{marginBottom: "10px", marginTop: "10px"}}>Numer KRS:</Text>
        </View>
        <Table>
          <TableHeadComponent />
          <TableBody>
            <TableRow>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_ksi?.nr_krs || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>-</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_bk14?.nr_krs || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_bk21?.nr_krs || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_krs?.nr_krs || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>-</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_crbr?.nr_krs || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_vat?.nr_krs || "-"}</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <View>
          <Text style={{marginBottom: "10px", marginTop: "10px"}}>Numer REGON:</Text>
        </View>
        <Table>
          <TableHeadComponent />
          <TableBody>
            <TableRow>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_ksi?.nr_regon || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_sl?.nr_regon || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_bk14?.nr_regon || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_bk21?.nr_regon || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_krs?.nr_regon || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_ceidg?.wlasciciel.nr_regon || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_crbr?.nr_regon || "-"}</Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_vat?.nr_regon || "-"}</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <View>
          <Text style={{marginBottom: "10px", marginTop: "10px"}}>Adres podmiotu:</Text>
        </View>
        <Table>
          <TableHeadComponent />
          <TableBody>
            <TableRow>
              <TableCell width={10} style={styles.tableCell}>
                <Text>{sourcesResult?.dane_ksi?.adres || "-"}</Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>
                  {sourcesResult?.dane_sl?.adres || "-"}
                </Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>
                  {sourcesResult?.dane_bk14?.adres || "-"}
                </Text>
              </TableCell>
              <TableCell width={10} style={styles.tableCell}>
                <Text>
                  {sourcesResult?.dane_bk21?.adres || "-"}
                </Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>
                  {sourcesResult?.dane_krs?.adres || "-"}
                </Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>
                  {sourcesResult?.dane_ceidg?.adres || "-"}
                </Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>
                  {sourcesResult?.dane_crbr?.adres || "-"}
                </Text>
              </TableCell>
              <TableCell width={15} style={styles.tableCell}>
                <Text>
                  {sourcesResult?.dane_vat?.adres || "-"}
                </Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </View>
      <PdfPageNumber />
    </Page>
  );
};

export default EntitiesSourcesPdf;
