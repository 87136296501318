import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, DangerButton } from 'components/shared/Button/Button';
import { useFormik } from 'formik';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import { TYPE } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& > div': {
      width: '100%',
    },
    '& > div:nth-child(2)': {
      marginTop: -20,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
      },
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 600,
    marginRight: 20,
    fontSize: '0.8rem',
    fontWeight: 400,
  },
}))(Tooltip);

const StyledFieldWithTooltip = styled.div`
  display: flex;
  align-items: center;
`;

const EntitiesPersonsFilters = ({ setFilters, type = TYPE.ENTITY }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      type,
      query: '',
    },
    onSubmit: (values) => {
      setFilters(values);
    },
    enableReinitialize: true,
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={2}>
          <CardHeader
            title={`Wyszukaj ${
              formik.values.type === TYPE.ENTITY ? 'podmioty' : 'osoby'
            }`}
            titleTypographyProps={{ variant: 'h6' }}
          ></CardHeader>
          <form onSubmit={formik.handleSubmit}>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={12} className={classes.gridItem}>
                <StyledFieldWithTooltip>
                  <TextField
                    label={
                      formik.values.type === TYPE.ENTITY
                        ? 'Nazwa, NIP lub KRS'
                        : 'Imię, nazwisko lub PESEL'
                    }
                    id="query"
                    name="query"
                    value={formik.values.query}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                  <HtmlTooltip
                    title={
                      (formik.values.type === TYPE.ENTITY && (
                        <div>
                          Wyszukiwanie w CRBR wymaga podania numeru NIP.
                        </div>
                      )) || (
                        <React.Fragment>
                          <div>
                            Wyszukiwanie w CEIDG wymaga podania najpierw imienia
                            (imion), następnie nazwiska.
                          </div>
                          <div style={{ marginTop: 10 }}>
                            Wyszukiwanie w CRBR obejmuje tylko beneficjentów rzeczywistych i wymaga podania numeru PESEL.
                          </div>
                        </React.Fragment>
                      )
                    }
                  >
                    <SvgIcon style={{ fontSize: '1rem', marginRight: 5 }}>
                      <path
                        fill="currentColor"
                        d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z"
                      />
                    </SvgIcon>
                  </HtmlTooltip>
                </StyledFieldWithTooltip>
              </Grid>
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <Button type="submit" variant="contained" color="primary">
                  Szukaj
                </Button>
                <DangerButton
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={() => {
                    formik.handleReset();
                    setFilters({ ...formik.initialValues });
                  }}
                >
                  Wyczyść filtry
                </DangerButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EntitiesPersonsFilters;
