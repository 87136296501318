import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { wpioRoutes } from "routes/wpioRoutes"
import { getPath } from "routes/routesUtils"
import Pagination from "components/shared/Pagination/Pagination"
import { Button } from "components/shared/Button/Button"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Link as RouterLink } from "react-router-dom"
import { getEntityPersonValue } from "components/wpio/EntitiesPersons/EntitiesPersonsUtils"
import Link from "@material-ui/core/Link"
import ActionTableCell from "components/shared/ActionTableCell/ActionTableCell"

const EntitiesTable = ({ data, pageNumber, pageSize, applyPaging, limitedRowsPerPage }) => {
  return (
    <>
      <Table aria-label="Tabela podmiotów">
        <TableHead>
          <TableRow className="table-head-row">
            <TableCell>NIP</TableCell>
            <TableCell>Nazwa</TableCell>
            <TableCell>Adres siedziby</TableCell>
            <ActionTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.lista_podmiotow &&
            data.lista_podmiotow.map((d) => (
              <TableRow key={d.id_podmiot}>
                <TableCell component="th" scope="row">
                  {getEntityPersonValue(d.nr_nip)}
                </TableCell>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={getPath(
                      wpioRoutes.entitiesPersons.entityDetails,
                      d.id_podmiot
                    )}
                    target="_blank"
                  >
                    {getEntityPersonValue(d.nazwa)}
                  </Link>
                </TableCell>
                <TableCell>{getEntityPersonValue(d.adres)}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <Button
                    component={RouterLink}
                    target="_blank"
                    variant="contained"
                    to={getPath(
                      wpioRoutes.entitiesPersons.entityDetails,
                      d.id_podmiot
                    )}
                    color="primary"
                  >
                    Szczegóły
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        count={data.liczba_podmiotow}
        page={pageNumber}
        pageSize={pageSize}
        rowsPerPageOptions={limitedRowsPerPage ? [10, 25] : [10,25,50]}
        handleChangePage={(page) => {
          applyPaging({ pageSize, pageNumber: page })
        }}
        handleChangeRowsPerPage={(event) => {
          applyPaging({
            pageNumber: 1,
            pageSize: parseInt(event.target.value, 10),
          })
        }}
        isPreciseCount={Boolean(data.czy_liczba_dokladna)}
      />
    </>
  )
}

export default EntitiesTable
