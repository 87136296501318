import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import { Button } from "components/shared/Button/Button"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import ActionTableCell from "components/shared/ActionTableCell/ActionTableCell"

const SamplersTable = ({ data, pageNumber, pageSize, applyPaging, limitedRowsPerPage, handleDownloadClick }) => {
  return (
    <>
      <Table aria-label="Tabela podmiotów">
        <TableHead>
          <TableRow className="table-head-row">
            <TableCell>Nazwa</TableCell>
            <TableCell>Status analizy</TableCell>
            <ActionTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d) => (
            <TableRow key={d.id_zlecenie}>
              <TableCell component="th" scope="row">
                {d.nazwa_pliku}
              </TableCell>
              <TableCell>
                {d.czy_gotowy ? 'Gotowe' : 'W trakcie'}
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Button
                    target="_blank"
                    variant="contained"
                    onClick={() => handleDownloadClick(d.id_zlecenie)}
                    color="primary"
                    disabled={!d.czy_gotowy}
                  >
                    Pobierz
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default SamplersTable
