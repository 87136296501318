import React from 'react';
import {
  ErrorMessage,
  TableContainer,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsShared';
import { errorMessage } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { wpioRoutes } from 'routes/wpioRoutes';
import { getPath } from 'routes/routesUtils';
import { isNUllOrEmptyOrUndefined } from 'utils/utils';

const PersonsRelationsSection = ({ relations, error }) => {
  if (error) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  return (
    <>
      {relations && relations.length > 0 && (
        <div>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela powiązań">
              <TableHead>
                <TableRow>
                  <TableCell>Podmiot</TableCell>
                  <TableCell>Rola</TableCell>
                  <TableCell style={{ minWidth: 200 }}>Okres</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {relations.map(
                  (
                    {
                      nazwa,
                      rola,
                      typ,
                      dane_data_od,
                      dane_data_do,
                      id_podmiot,
                    },
                    index
                  ) => (
                    <TableRow key={nazwa + id_podmiot + index}>
                      <TableCell component="th" scope="row">
                        <Link
                          component={RouterLink}
                          to={getPath(
                            typ === 'Podmiot'
                              ? wpioRoutes.entitiesPersons.entityDetails
                              : wpioRoutes.entitiesPersons.personDetails,
                            id_podmiot
                          )}
                        >
                          {nazwa}
                        </Link>
                      </TableCell>
                      <TableCell>{rola}</TableCell>
                      <TableCell>
                        <PersonRelationsPeriod
                          from={dane_data_od}
                          to={dane_data_do}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

const PersonRelationsPeriod = ({ from, to }) => {
  if (isNUllOrEmptyOrUndefined(from)) {
    return <>obecnie</>;
  }

  return (
    <>{`${from} – ${
      (!isNUllOrEmptyOrUndefined(to) && to !== '9999-12-31' && to) || 'obecnie'
    }`}</>
  );
};

export default PersonsRelationsSection;
