import React, { useState } from "react"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Button, DangerButton } from "components/shared/Button/Button"
import * as Yup from "yup"
import UsersService from "services/shared/UsersService"
import CircularLoader from "components/shared/Loader/CircularLoader"
import { useHistory, useParams } from "react-router-dom"
import { useFormik } from "formik"
import { useApi } from "hooks/useApi"
import Loader from "components/shared/Loader/Loader"
import ErrorHandler from "components/shared/ErrorHandler/ErrorHandler"
import { skanerRoutes } from "routes/skanerRoutes"
import { Dialog } from "components/shared/Modal/Modal"

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  gridItem: {
    width: "100%",
  },
  passwordInfo: {
    marginTop: "5px",
    padding: "10px",
  },
}))

const UserPasswordSchema = Yup.object().shape({
  userPassword: Yup.string()
    .min(10, "Hasło jest za krótkie - musi mieć min. 10 znaków.")
    .required("To pole musi być uzupełnione."),
  confirmUserPassword: Yup.string()
    .required("To pole musi być uzupełnione.")
    .min(10, "Hasło jest za krótkie - musi mieć min. 10 znaków.")
    .oneOf([Yup.ref("userPassword")], "Hasła nie pasują do siebie."),
})

const ChangeUserPassword = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const { result, isLoading: userIsLoading, error } = useApi(`/api/users/${id}`)
  const [showConfirmModalOptions, setShowConfirmModalOptions] = useState(false)
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: null,
    title: null,
  })

  const formik = useFormik({
    initialValues: {
      userPassword: "",
      confirmUserPassword: "",
    },
    onSubmit: async ({ userPassword }, { resetForm }) => {
      try {
        setIsLoading(true)
        await UsersService.editUserPassword({ userPassword, id })
        setIsLoading(false)
        setShowConfirmModalOptions(true)
        resetForm()
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          setErrorModalOptions({
            title: "Błąd",
            isOpen: true,
            message: "Wymogi wobec hasła nie zostały spełnione.",
          })
        } else {
          history.push(skanerRoutes.errors[500])
        }
      }
    },
    validationSchema: UserPasswordSchema,
  })

  if (error) {
    return <ErrorHandler error={error} />
  }

  if (userIsLoading || !result) {
    return <Loader />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={2}>
          <CardHeader
            title={`Zmień hasło użytkownika #${id}`}
            titleTypographyProps={{ variant: "h6" }}
          ></CardHeader>
          <form onSubmit={formik.handleSubmit}>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Nowe hasło"
                  name="userPassword"
                  id="userPassword"
                  type="password"
                  value={formik.values.userPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.userPassword &&
                    formik.touched.userPassword &&
                    formik.errors.userPassword
                  }
                  error={
                    formik.touched.userPassword &&
                    Boolean(formik.errors.userPassword)
                  }
                  fullWidth
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Powtórz hasło"
                  name="confirmUserPassword"
                  id="confirmUserPassword"
                  type="password"
                  value={formik.values.confirmUserPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.confirmUserPassword &&
                    formik.touched.confirmUserPassword &&
                    formik.errors.confirmUserPassword
                  }
                  error={
                    formik.touched.confirmUserPassword &&
                    Boolean(formik.errors.confirmUserPassword)
                  }
                  fullWidth
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.buttonsWrapper}>
                <Button type="submit" variant="contained" color="primary">
                  {isLoading ? <CircularLoader /> : "Zapisz"}
                </Button>
                <DangerButton
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Anuluj
                </DangerButton>
              </Grid>
            </Grid>
            <div className={classes.passwordInfo}>
              <span>
                <strong>Wymogi wobec haseł: </strong>
                <br />
                - Minimalna liczba znaków: 10 <br />
                - Maksymalna liczba znaków: 32 <br />
                - Musi różnić się od 3 poprzednich <br />
                - Przynajmniej jedna wielka oraz jedna mała litera
                <br />
                - Przynajmniej jedna cyfra <br />
                - Przynajmniej jeden znak specjalny <br /> - Nie może zawierać
                ciągu min. 3 liter z adresu mailowego lub imienia i nazwiska
              </span>
            </div>
            <Dialog
              isOpen={showConfirmModalOptions}
              handleClose={() => history.goBack()}
              title="Sukces"
              close
              text={"Hasło zostało poprawnie zmienione."}
            ></Dialog>
            <Dialog
              isOpen={errorModalOptions.isOpen}
              handleClose={() =>
                setErrorModalOptions({ isOpen: false, message: null })
              }
              error
              title={errorModalOptions.title}
              text={errorModalOptions.message}
            ></Dialog>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ChangeUserPassword
