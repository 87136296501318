import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {},
  tableBody: {
    margin: 0,
    padding: 0,
  },
  tableHead: {
    flexDirection: 'row',
    fontWeight: 'bold',
    backgroundColor: '#E8E8E8',
    margin: 0,
    padding: 0,
  },
  tableCell: {
    border: "1px solid gray",
    margin: 0,
    padding: 0,
  },
  tableRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
  },
});

export const Table = ({ children }) => {
  return <View style={styles.table}>{children}</View>;
};

export const TableCell = ({ width, children, style = {} }) => {
  return (
    <View style={[styles.tableCell, { width: `${width}%` }, style]}>
      {children}
    </View>
  );
};

export const TableHead = ({ children  }) => {
  return (
    <View style={styles.tableHead}>
      {children}
    </View>
  );
}

export const TableBody = ({ children }) => {
  return <View style={styles.tableBody}>{children}</View>;
};

export const TableRow = ({ children }) => {
  return <View style={styles.tableRow}>{children}</View>;
};
