import { TYPE } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { GRAPH_ELEMENT } from 'components/wpio/EntitiesPersons/GraphRelations/GraphConsts';
import { getRandomStr } from 'utils/utils';
import { subYears, startOfDay, parse, isBefore } from 'date-fns';

const getNodeData = ({ id_podmiot, nazwa, typ, dane_sformatowane }, rzad) => {
  return {
    ...dane_sformatowane,
    elemType: GRAPH_ELEMENT.NODE,
    id: id_podmiot.toString(),
    title: nazwa,
    isMain: rzad === 0,
    type: typ === 'Podmiot' ? TYPE.ENTITY : TYPE.PERSON,
  };
};

const getEdgeData = ({
  id_podmiot_a,
  id_podmiot_b,
  powiazanie_data_od,
  powiazanie_data_do,
  zwrot,
  nazwa,
}) => {
  return {
    elemType: GRAPH_ELEMENT.EDGE,
    id: getRandomStr(),
    source: zwrot === 'AB' ? id_podmiot_a.toString() : id_podmiot_b.toString(),
    target: zwrot === 'AB' ? id_podmiot_b.toString() : id_podmiot_a.toString(),
    hasArrow: zwrot === 'AB' || zwrot === 'BA',
    title: nazwa,
    isHistoric:
      powiazanie_data_do !== '9999-12-31' && powiazanie_data_do !== null,
    titleIsVisible: false,
    powiazanie_data_od,
    powiazanie_data_do,
  };
};

export const mapGraphItems = (data) => {
  const edges = [];
  const nodes = [];
  data.forEach(({ krawedz, rzad, wezel }) => {
    wezel.forEach((node) => nodes.push(getNodeData(node, rzad)));
    krawedz.forEach((edge) => edges.push(getEdgeData(edge)));
  });

  return {
    edges,
    nodes,
  };
};

export const mapNewGraphItems = (data) => {
  const items = mapGraphItems(data);

  // For new graph items set false for level 0 node:
  items.nodes.find((n) => n.isMain).isMain = false;
  return items;
};

const applyConfigFilters = (graphItems, graphConfig) => {
  const dateNow = new Date();

  graphItems.edges = graphItems.edges.filter(
    ({ isHistoric, powiazanie_data_do }) => {
      if (isHistoric) {
        const nowOffset = subYears(startOfDay(dateNow), graphConfig.yearsBack);
        const parsedDateTo = parse(
          powiazanie_data_do || '9999-12-31',
          'yyyy-MM-dd',
          dateNow
        );
        const isBeforeNowOffset = isBefore(parsedDateTo, nowOffset);

        if (isBeforeNowOffset) {
          return false;
        }
      }

      return true;
    }
  );
};

const traverseGraph = (nodeId, visited, adjacencyList) => {
  visited[nodeId] = true;

  const neighbours = adjacencyList.get(nodeId);

  neighbours.forEach((n) => {
    if (!visited[n]) {
      traverseGraph(n, visited, adjacencyList);
    }
  });
};

const getAllNodesAvailableFromStartingNode = (
  startingNodeId,
  adjacencyList
) => {
  const visited = {};

  traverseGraph(startingNodeId, visited, adjacencyList);

  return Object.keys(visited);
};

const getNodesToRemove = (data, startingNodeId) => {
  const adjacencyList = new Map();

  // Add vertices
  data.nodes.forEach(({ id }) => adjacencyList.set(id, []));

  // Add edges
  data.edges.forEach(({ source, target }) => {
    adjacencyList.get(source).push(target);
    adjacencyList.get(target).push(source);
  });

  const allNodesAvailableFromRoot = getAllNodesAvailableFromStartingNode(
    startingNodeId,
    adjacencyList
  );

  return data.nodes
    .map((n) => n.id)
    .filter((nodeId) => !allNodesAvailableFromRoot.includes(nodeId));
};

const removeDisconnectedGraphs = (graphItems, startingNodeId) => {
  const nodesToRemove = getNodesToRemove(graphItems, startingNodeId);

  graphItems.nodes = graphItems.nodes.filter(
    (n) => !nodesToRemove.includes(n.id)
  );
  graphItems.edges = graphItems.edges.filter(
    (e) =>
      !nodesToRemove.includes(e.source) && !nodesToRemove.includes(e.target)
  );
};

export const prepareGraphElements = (items, graphConfig, startingNodeId) => {
  const graphItems = JSON.parse(JSON.stringify(items));

  applyConfigFilters(graphItems, graphConfig);

  removeDisconnectedGraphs(graphItems, startingNodeId);

  // return in cytoscape format:
  return [
    ...graphItems.nodes.map((n) => ({
      data: n,
    })),
    ...graphItems.edges.map((e) => ({
      data: e,
    })),
  ];
};

const removeDuplicates = (newGraphItems, edges, nodes) => {
  const currentGraphNodeIds = nodes.map((el) => el.id());
  const currentGraphEdgesIds = edges.map(
    (el) => `${el.data('source')}-${el.data('target')}`
  );

  newGraphItems.nodes = newGraphItems.nodes.filter(
    (n) => !currentGraphNodeIds.includes(n.id)
  );
  newGraphItems.edges = newGraphItems.edges.filter(
    (e) =>
      !currentGraphEdgesIds.includes(`${e.source}-${e.target}`) &&
      !currentGraphEdgesIds.includes(`${e.target}-${e.sources}`)
  );
};

export const prepareNewGraphElements = (
  newItems,
  nodes,
  edges,
  graphConfig,
  startingNodeId
) => {
  const newGraphItems = JSON.parse(JSON.stringify(newItems));

  applyConfigFilters(newGraphItems, graphConfig);

  removeDisconnectedGraphs(newGraphItems, startingNodeId);

  removeDuplicates(newGraphItems, edges, nodes);

  return [
    ...newGraphItems.nodes.map((n) => ({
      data: n,
    })),
    ...newGraphItems.edges.map((e) => ({
      data: e,
    })),
  ];
};
