import React from 'react';
import {
  ErrorMessage,
  LabelValue,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsShared';
import { errorMessage } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { isEmpty } from 'utils/utils';

const EntitiesObjectSection = ({ object, error }) => {
  if (error) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  const { dane_ceidg, dane_krs } = object;

  return (
    <>
      {dane_ceidg && (
        <div>
          {dane_ceidg.dzialalnosc_glowna && (
            <LabelValue
              label="Działalność główna"
              value={dane_ceidg.dzialalnosc_glowna}
            />
          )}
          {!isEmpty(dane_ceidg.dzialalnosc_pozostala) && (
            <LabelValue
              label="Działalność pozostała"
              multi
              value={dane_ceidg.dzialalnosc_pozostala}
            />
          )}
        </div>
      )}
      {dane_krs && (
        <div>
          {dane_krs.dzialalnosc_glowna && (
            <LabelValue
              label="Działalność główna"
              value={dane_krs.dzialalnosc_glowna}
            />
          )}
          {!isEmpty(dane_krs.dzialalnosc_pozostala) && (
            <LabelValue
              label="Działalność pozostała"
              multi
              value={dane_krs.dzialalnosc_pozostala}
            />
          )}
        </div>
      )}
    </>
  );
};

export default EntitiesObjectSection;
