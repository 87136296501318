import React from 'react';
import { getEntityPersonValue } from 'components/wpio/EntitiesPersons/EntitiesPersonsUtils';
import {
  Divider,
  LabelValue,
  LabelValueRow,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsShared';

const EntitiesIndentificationSection = ({
  data: { nr_krs, nr_nip, nr_regon, adres },
}) => {
  return (
    <div>
      <LabelValueRow>
        <LabelValue label="NIP" value={getEntityPersonValue(nr_nip)} />
        <LabelValue label="KRS" value={getEntityPersonValue(nr_krs)} />
        <LabelValue label="REGON" value={getEntityPersonValue(nr_regon)} />
      </LabelValueRow>
      <Divider />
      <LabelValue label="Adres" value={getEntityPersonValue(adres)} />
    </div>
  );
};

export default EntitiesIndentificationSection;
