import { TYPE } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';

export const getTypeByLocation = (location) => {
  if (!location || !location.pathname) return null;

  const { pathname } = location;

  if (pathname.includes('/podmiot/')) {
    return TYPE.ENTITY;
  } else if (pathname.includes('/osoba/')) {
    return TYPE.PERSON;
  } else {
    console.error(
      `Nie znaleziono odpowiedniego typu dla lokalizacji = '${pathname}'`
    );
  }
};

export const getEntityPersonValue = (value) => {
  if (!value) return 'Brak';

  if (value.length === 0) return ['Brak'];

  return value;
};

export const getShowSectionStateByError = (error) => {
  if (error && error.response && error.response.status !== 404) {
    return true;
  }

  return false;
};
