import React, { useState } from "react"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Button, DangerButton } from "components/shared/Button/Button"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import * as Yup from "yup"
import UsersService from "services/shared/UsersService"
import CircularLoader from "components/shared/Loader/CircularLoader"
import { useHistory } from "react-router-dom"
import { useFormik } from "formik"
import { skanerRoutes } from "routes/skanerRoutes"
import FormLabel from "@material-ui/core/FormLabel"
import { roles, roleModulesMap } from "utils/roles"
import FormHelperText from "@material-ui/core/FormHelperText"
import { Dialog } from "components/shared/Modal/Modal"

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  gridItem: {
    width: "100%",
  },
}))

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required("Imię musi być uzupełnione."),
  lastName: Yup.string().required("Nazwisko musi być uzupełnione."),
  email: Yup.string()
    .email("To nie jest poprawny adres email.")
    .required("Adres email musi być uzupełniony."),
  roles: Yup.array().min(1, "Przynajmniej jedno z uprawnień musi być wybrane."),
  organisation: Yup.string()
    .required("Program/Instytucja musi być uzupełniony.")
    .max(100, "Program/Instytucja nie może mieć więcej niż 100 znaków."),
})

const AddUserView = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const [showConfirmModalOptions, setShowConfirmModalOptions] = useState(false)
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: null,
    title: null,
  })

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: [roles.USER_WPIO],
      organisation: "",
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await UsersService.addUser(values)
        setShowConfirmModalOptions(true)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          setErrorModalOptions({
            title: "Błąd",
            isOpen: true,
            message: error.response.data.detail,
          })
        } else {
          history.push(skanerRoutes.errors[500])
        }
      }
    },
    validationSchema: UserSchema,
  })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={2}>
          <CardHeader
            title="Dodaj użytkownika"
            titleTypographyProps={{ variant: "h6" }}
          ></CardHeader>
          <form onSubmit={formik.handleSubmit}>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Imię"
                  name="firstName"
                  id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.firstName &&
                    formik.touched.firstName &&
                    formik.errors.firstName
                  }
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Nazwisko"
                  name="lastName"
                  id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.lastName &&
                    formik.touched.lastName &&
                    formik.errors.lastName
                  }
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Email"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.email &&
                    formik.touched.email &&
                    formik.errors.email
                  }
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Program/Instytucja"
                  name="organisation"
                  id="organisation"
                  value={formik.values.organisation}
                  onChange={formik.handleChange}
                  helperText={
                    formik.errors.organisation &&
                    formik.touched.organisation &&
                    formik.errors.organisation
                  }
                  error={
                    formik.touched.organisation &&
                    Boolean(formik.errors.organisation)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <FormControlLabel
                  style={{ marginTop: 8 }}
                  control={
                    <Checkbox
                      checked={formik.values.roles.includes(roles.ADMIN)}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "roles",
                          e.target.checked
                            ? Object.values(roles)
                            : [roles.USER_WPIO]
                        )
                      }}
                      name={roles.ADMIN}
                      id={roles.ADMIN}
                      color="primary"
                    />
                  }
                  label="Czy administrator?"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <div>
                  <FormLabel>Uprawnienia do modułów</FormLabel>
                </div>
                {roleModulesMap.map((rmm) => (
                  <div key={rmm.name}>
                    <FormControlLabel
                      style={{ marginTop: 8 }}
                      control={
                        <Checkbox
                          checked={formik.values.roles.includes(rmm.role)}
                          name={rmm.name}
                          disabled={formik.values.roles.includes(rmm.role)}
                          id={rmm.name}
                          color="primary"
                        />
                      }
                      label={rmm.name}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
            {Boolean(formik.errors.roles) && (
              <Grid
                container
                item
                xs={12}
                spacing={4}
                style={{ margin: "-30px 0 0 0" }}
              >
                <Grid item md={6} className={classes.gridItem}>
                  <FormHelperText error>{formik.errors.roles}</FormHelperText>
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.buttonsWrapper}>
                <Button type="submit" variant="contained" color="primary">
                  {isLoading ? <CircularLoader /> : "Utwórz"}
                </Button>
                <DangerButton
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Anuluj
                </DangerButton>
              </Grid>
            </Grid>
          </form>
          <Dialog
            isOpen={showConfirmModalOptions}
            handleClose={() => history.goBack()}
            title="Sukces"
            close
            text={"Użytkownik został poprawnie dodany."}
          ></Dialog>
          <Dialog
            isOpen={errorModalOptions.isOpen}
            handleClose={() => {
              setErrorModalOptions({ isOpen: false, message: null })
            }}
            error
            title={errorModalOptions.title}
            text={errorModalOptions.message}
          ></Dialog>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AddUserView
