import React from 'react';
import {
  Divider,
  ErrorMessage,
  LabelValue,
  SubSectionTitle,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsShared';
import { errorMessage } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { isNullOrEmpty } from 'utils/utils';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import slugify from 'slugify';

const StyledSubSubTitle = styled(Typography)`
  margin: 16px 0;
  text-decoration: underline;
  font-weight: 500;
`;

const EntitiesTransformationSection = ({ transformation, error }) => {
  if (error) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  return (
    <>
      {transformation &&
        transformation.length > 0 &&
        transformation.map(
          (
            {
              okreslenie_okolicznosci,
              opis,
              podmioty_powstale,
              podmioty_przejmowane,
            },
            index
          ) => (
            <div
              key={slugify(
                okreslenie_okolicznosci + ((opis && opis.substr(0, 50)) || ''),
                { lower: true, strict: true }
              )}
            >
              <SubSectionTitle>{okreslenie_okolicznosci}</SubSectionTitle>
              <Typography variant="caption" display="block" gutterBottom>
                {opis}
              </Typography>
              {podmioty_powstale && podmioty_powstale.length > 0 && (
                <div>
                  <StyledSubSubTitle variant="subtitle1">
                    Podmioty powstałe
                  </StyledSubSubTitle>
                  {podmioty_powstale.map(
                    (
                      {
                        nazwa,
                        kraj_i_nazwa_rejestru,
                        numer_w_rejestrze,
                        nazwa_sadu_prowadzacego,
                        numer_regon,
                      },
                      pp_index
                    ) => (
                      <div
                        key={
                          nazwa +
                          kraj_i_nazwa_rejestru +
                          numer_w_rejestrze +
                          nazwa_sadu_prowadzacego +
                          numer_regon
                        }
                        style={{ marginLeft: 32 }}
                      >
                        {!isNullOrEmpty(nazwa) && (
                          <LabelValue label="Nazwa" value={nazwa} />
                        )}
                        {!isNullOrEmpty(kraj_i_nazwa_rejestru) && (
                          <LabelValue
                            label="Kraj i nazwa rejestru"
                            value={kraj_i_nazwa_rejestru}
                          />
                        )}
                        {!isNullOrEmpty(numer_w_rejestrze) && (
                          <LabelValue
                            label="Numer w rejestrze"
                            value={numer_w_rejestrze}
                          />
                        )}
                        {!isNullOrEmpty(nazwa_sadu_prowadzacego) && (
                          <LabelValue
                            label="Nazwa sądu prowadzącego"
                            value={nazwa_sadu_prowadzacego}
                          />
                        )}
                        {!isNullOrEmpty(numer_regon) && (
                          <LabelValue label="Nr REGON" value={numer_regon} />
                        )}
                        {pp_index !== podmioty_powstale.length - 1 && (
                          <Divider />
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
              {podmioty_przejmowane && podmioty_przejmowane.length > 0 && (
                <div>
                  <StyledSubSubTitle
                    variant="subtitle1"
                    style={{ marginTop: 32 }}
                  >
                    Podmioty przejmowane
                  </StyledSubSubTitle>
                  {podmioty_przejmowane.map(
                    (
                      {
                        nazwa,
                        kraj_i_nazwa_rejestru,
                        numer_w_rejestrze,
                        nazwa_sadu_prowadzacego,
                        numer_regon,
                      },
                      pp_index
                    ) => (
                      <div
                        key={
                          nazwa +
                          kraj_i_nazwa_rejestru +
                          numer_w_rejestrze +
                          nazwa_sadu_prowadzacego +
                          numer_regon
                        }
                        style={{ marginLeft: 32 }}
                      >
                        {!isNullOrEmpty(nazwa) && (
                          <LabelValue label="Nazwa" value={nazwa} />
                        )}
                        {!isNullOrEmpty(kraj_i_nazwa_rejestru) && (
                          <LabelValue
                            label="Kraj i nazwa rejestru"
                            value={kraj_i_nazwa_rejestru}
                          />
                        )}
                        {!isNullOrEmpty(numer_w_rejestrze) && (
                          <LabelValue
                            label="Numer w rejestrze"
                            value={numer_w_rejestrze}
                          />
                        )}
                        {!isNullOrEmpty(nazwa_sadu_prowadzacego) && (
                          <LabelValue
                            label="Nazwa sądu prowadzącego"
                            value={nazwa_sadu_prowadzacego}
                          />
                        )}
                        {!isNullOrEmpty(numer_regon) && (
                          <LabelValue label="Nr REGON" value={numer_regon} />
                        )}
                        {pp_index !== podmioty_przejmowane.length - 1 && (
                          <Divider />
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
              {index !== transformation.length - 1 && <Divider />}
            </div>
          )
        )}
    </>
  );
};

export default EntitiesTransformationSection;
