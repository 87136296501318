import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from 'components/shared/Loader/Loader';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .2)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const EntitiesPersonsAccordionTitle = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .2)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const EntitiesPersonsAccordionContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexFlow: 'column',
  },
}))(MuiAccordionDetails);

const EntitiesPersonsAccordion = ({ defaultExpanded, items }) => {
  const [expanded, setExpanded] = React.useState([defaultExpanded]);

  const handleChange = (panel) => (_, __) => {
    const isExpanded = expanded.includes(panel);
    setExpanded(
      isExpanded
        ? [...expanded].filter((x) => x !== panel)
        : [...expanded, panel]
    );
  };

  return (
    <div style={{ marginTop: 32 }}>
      {items.map(({ panelId, title, content, show, timeoutTransition }) => {
        if (show) {
          return (
            <Accordion
              key={panelId}
              square
              expanded={expanded.includes(panelId)}
              onChange={handleChange(panelId)}
              TransitionProps={{ timeout: timeoutTransition || 'auto' }}
              style={{
                borderBottom:
                  ([...items].filter((x) => !x.isLoading && x.show).pop()
                    .panelId === panelId &&
                    '1px solid rgba(0, 0, 0, .2)') ||
                  '0',
              }}
            >
              <EntitiesPersonsAccordionTitle
                aria-controls={`panel${panelId}-zawartość`}
                id={`panel${panelId}`}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{ fontWeight: '500' }}>{title}</Typography>
              </EntitiesPersonsAccordionTitle>
              <EntitiesPersonsAccordionContent>
                {content}
              </EntitiesPersonsAccordionContent>
            </Accordion>
          );
        }
      })}
    </div>
  );
};

export default EntitiesPersonsAccordion;
