import React from 'react';
import { Text, View, StyleSheet, Image, Page } from '@react-pdf/renderer';
import {
  divider,
  pageStyle,
} from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/baseStyles';
import { PdfPageNumber } from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsExportPdf/EntitiesPersonsExportPdf';

const styles = StyleSheet.create({
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 15,
  },
});

const EntitiesPersonsGraphPdf = ({ graph }) => {
  return (
    <Page style={{ ...pageStyle }}>
      <View style={styles.sectionTitle}>
        <Text>Graf zależności</Text>
      </View>
      <View style={{ ...divider }} />
      <View>
        <Image src={graph} style={{ width: '100%' }} />
      </View>
      <PdfPageNumber />
    </Page>
  );
};

export default EntitiesPersonsGraphPdf;
