import React, { useState } from "react"
import {
  ErrorMessage,
  LabelValue,
  SubSectionTitle,
  TableContainer,
} from "components/wpio/EntitiesPersons/EntitiesPersonsShared"
import { errorMessage } from "components/wpio/EntitiesPersons/EntitiesPersonsConsts"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { useWindowSize } from "hooks/useWindowSize"
import StickedScrollbar from "components/shared/StickedScrollbar/StickedScrollbar"
import { Button } from "components/shared/Button/Button"
import { Modal } from "components/shared/Modal/Modal"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { DangerButton } from "components/shared/Button/Button"
import styled from "styled-components"
import { makeStyles } from '@material-ui/core/styles';

const StyledSection = styled.div`
  &:not(:first-child) {
    padding-top: 16px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 16px;
  }
`

const useStyles = makeStyles({
  descriptionCell: {
    wordBreak: 'break-word',
  },
});


const EntitiesPersonsEuFundingSection = ({ euFunding, error }) => {
  const { height } = useWindowSize()

  if (error) {
    return (
      <ErrorMessage>
        {(error.response &&
          error.response.data &&
          error.response.data.detail) ||
          errorMessage}
      </ErrorMessage>
    )
  }

  const { odbiorcy, projekty_ue, projekty_ue_ksi } = euFunding

  const moreThan3 = (data) => data.length > 3

  return (
    <>
      {projekty_ue_ksi && projekty_ue_ksi.length > 0 && (
        <StyledSection>
          <SubSectionTitle>
            Projekty współfinansowane ze środków UE – perspektywa finansowa
            2007-2013
          </SubSectionTitle>
          <TableContainer component="div">
            <StickedScrollbar
              bottomOffset={height >= 768 ? 30 : 0}
              onScrollElements={["#wpio-entity-person-details"]}
            >
              <Table
                size="small"
                aria-label="tabela projektów współfinansowanych ze środków UE – perspektywa finansowa 2007-2013"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Numer projektu</TableCell>
                    <TableCell>Status projektu</TableCell>
                    <TableCell>Tytuł projektu</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(
                    (moreThan3(projekty_ue_ksi) && [
                      ...projekty_ue_ksi.filter((_, index) => index < 3),
                    ]) || [...projekty_ue_ksi]
                  ).map((data) => (
                    <EuFundingKsiProjectRow
                      key={data.nr_projektu + data.tytul_projektu}
                      data={data}
                    />
                  ))}
                </TableBody>
              </Table>
            </StickedScrollbar>
          </TableContainer>
          {moreThan3(projekty_ue_ksi) && (
            <ShowAll
              dialogTitle="Projekty współfinansowane ze środków UE – perspektywa finansowa 2007-2013"
              length={projekty_ue_ksi.length}
            >
              <TableContainer component="div">
                <StickedScrollbar bottomOffset={84}>
                  <Table
                    size="small"
                    aria-label="tabela projektów współfinansowanych ze środków UE – perspektywa finansowa 2007-2013"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Numer projektu</TableCell>
                        <TableCell>Status projektu</TableCell>
                        <TableCell>Tytuł projektu</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projekty_ue_ksi.map((data) => (
                        <EuFundingKsiProjectRow
                          key={data.nr_projektu + data.tytul_projektu}
                          data={data}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </StickedScrollbar>
              </TableContainer>
            </ShowAll>
          )}
        </StyledSection>
      )}
      {projekty_ue && projekty_ue.length > 0 && (
        <StyledSection>
          <SubSectionTitle>
            Projekty współfinansowane ze środków UE – perspektywa finansowa
            2014-2020
          </SubSectionTitle>
          <TableContainer component="div">
            <StickedScrollbar
              bottomOffset={height >= 768 ? 30 : 0}
              onScrollElements={["#wpio-entity-person-details"]}
            >
              <Table
                size="small"
                aria-label="tabela projektów współfinansowanych ze środków UE – perspektywa finansowa 2014-2020"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Numer projektu</TableCell>
                    <TableCell>Status projektu</TableCell>
                    <TableCell>Tytuł projektu</TableCell>
                    <TableCell>Krótki opis projektu</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(
                    (moreThan3(projekty_ue) && [
                      ...projekty_ue.filter((_, index) => index < 3),
                    ]) || [...projekty_ue]
                  ).map((data) => (
                    <EuFundingProjectRow
                      key={data.nr_projektu + data.tytul_projektu}
                      data={data}
                    />
                  ))}
                </TableBody>
              </Table>
            </StickedScrollbar>
          </TableContainer>
          {moreThan3(projekty_ue) && (
            <ShowAll
              dialogTitle="Projekty współfinansowane ze środków UE – perspektywa finansowa 2014-2020"
              length={projekty_ue.length}
            >
              <TableContainer component="div">
                <StickedScrollbar bottomOffset={84}>
                  <Table
                    size="small"
                    aria-label="tabela projektów współfinansowanych ze środków UE – perspektywa finansowa 2014-2020"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Numer projektu</TableCell>
                        <TableCell>Status projektu</TableCell>
                        <TableCell>Tytuł projektu</TableCell>
                        <TableCell>Krótki opis projektu</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projekty_ue.map((data) => (
                        <EuFundingProjectRow
                          key={data.nr_projektu + data.tytul_projektu}
                          data={data}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </StickedScrollbar>
              </TableContainer>
            </ShowAll>
          )}
        </StyledSection>
      )}
      {odbiorcy && odbiorcy.length > 0 && (
        <StyledSection>
          <SubSectionTitle>Ostateczny odbiorca</SubSectionTitle>
          <TableContainer component="div">
            <Table
              size="small"
              aria-label="tabela projektów współfinansowanych ze środków UE"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Numer umowy z odbiorcą</TableCell>
                  <TableCell>Rodzaj wsparcia</TableCell>
                  <TableCell>Numer projektu</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(
                  (moreThan3(odbiorcy) && [
                    ...odbiorcy.filter((_, index) => index < 3),
                  ]) || [...odbiorcy]
                ).map((data) => (
                  <EuFundingReceiverRow
                    key={data.nr_projektu + data.nr_umowy}
                    data={data}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {moreThan3(odbiorcy) && (
            <ShowAll dialogTitle="Ostateczny odbiorca" length={odbiorcy.length}>
              <TableContainer component="div">
                <Table
                  size="small"
                  aria-label="tabela projektów współfinansowanych ze środków UE"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Numer umowy z odbiorcą</TableCell>
                      <TableCell>Rodzaj wsparcia</TableCell>
                      <TableCell>Numer projektu</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {odbiorcy.map((data) => (
                      <EuFundingReceiverRow
                        key={data.nr_projektu + data.nr_umowy}
                        data={data}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ShowAll>
          )}
        </StyledSection>
      )}
    </>
  )
}

const EuFundingProjectRow = ({ data }) => {
  const classes = useStyles();
  const {
    nr_projektu,
    status_wniosku,
    status_umowy,
    status_projektu,
    opis_projektu,
    tytul_projektu,
  } = data

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {nr_projektu}
      </TableCell>
      <TableCell>
        <LabelValue label="WNIOSEK" value={status_wniosku} />
        <LabelValue label="UMOWA" value={status_umowy} />
        {status_projektu !== "Brak" && (
          <LabelValue label="PROJEKT" value={status_projektu} />
        )}
      </TableCell>
      <TableCell>{tytul_projektu}</TableCell>
      <TableCell className={classes.descriptionCell}>{opis_projektu}</TableCell>
    </TableRow>
  )
}

const EuFundingKsiProjectRow = ({ data }) => {
  const { nr_projektu, status_umowy, status_projektu, tytul_projektu } = data

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {nr_projektu}
      </TableCell>
      <TableCell>
        <LabelValue label="UMOWA" value={status_umowy} />
        {status_projektu !== "Brak" && (
          <LabelValue label="PROJEKT" value={status_projektu} />
        )}
      </TableCell>
      <TableCell>{tytul_projektu}</TableCell>
    </TableRow>
  )
}

const EuFundingReceiverRow = ({ data }) => {
  const { nr_umowy, rodzaj_wsparcia, nr_projektu } = data

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {nr_umowy}
      </TableCell>
      <TableCell>{rodzaj_wsparcia}</TableCell>
      <TableCell>{nr_projektu}</TableCell>
    </TableRow>
  )
}

const ShowAll = ({ dialogTitle, children, length }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => setModalIsOpen(true)}
        variant="contained"
        color="primary"
      >
        Pokaż wszystkie ({length})
      </Button>
      <Modal
        isOpen={modalIsOpen}
        title={dialogTitle}
        handleClose={() => setModalIsOpen(false)}
        maxWidth="lg"
      >
        <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
        <DialogActions>
          <DangerButton
            style={{ marginLeft: 16 }}
            variant="outlined"
            onClick={() => setModalIsOpen(false)}
          >
            Zamknij
          </DangerButton>
        </DialogActions>
      </Modal>
    </>
  )
}

export default EntitiesPersonsEuFundingSection
