import { http } from "../http"

const getPersons = async (
  szukanaWartosc,
  zrodloDanych,
  rozmiarStrony,
  numerStrony,
  request
) => {
  const response = await http.get(`/api/wpio/v1/osoby`, {
    cancelToken: request.token,
    params: {
      szukanaWartosc,
      zrodloDanych,
      rozmiarStrony,
      numerStrony,
    },
  })

  return response.data
}

const getGraph = async (id, level = 1) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/graf?rzad=${level}`)

  return response.data
}

const getEuFunding = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/dofinansowaniaUe`, {
    cancelToken: request.token,
  })

  return response.data
}

const getRelations = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/powiazania`, {
    cancelToken: request.token,
  })

  return response.data
}

const getPublicProcurement = async (id, request) => {
  const response = await http.get(
    `/api/wpio/v1/osoby/${id}/zamowieniaPubliczne`,
    { cancelToken: request.token }
  )

  return response.data
}

export default {
  getPersons,
  getGraph,
  getEuFunding,
  getRelations,
  getPublicProcurement,
}
