import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {},
  tableBody: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'gray',
    paddingBottom: 5,
    paddingHorizontal: 5,
  },
  tableHead: {
    flexDirection: 'row',
    fontWeight: 'bold',
    backgroundColor: '#E8E8E8',
    paddingHorizontal: 5,
    borderStyle: 'solid',
    borderColor: 'gray',
    borderWidth: 1,
    paddingTop: 5,
    paddingBottom: 5,
    borderBottomWidth: 0,
  },
  tableCell: {
    paddingRight: 5,
  },
  tableRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 5,
  },
});

export const Table = ({ children }) => {
  return <View style={styles.table}>{children}</View>;
};

export const TableCell = ({ width, children, style = {} }) => {
  return (
    <View style={[styles.tableCell, { width: `${width}%` }, style]}>
      {children}
    </View>
  );
};

export const TableHead = ({ columns = [] }) => {
  return (
    <View style={styles.tableHead}>
      {columns.map(({ title, width = 100 / columns.length }) => (
        <TableCell key={title} width={width}>
          <Text>{title}</Text>
        </TableCell>
      ))}
    </View>
  );
};

export const TableBody = ({ children }) => {
  return <View style={styles.tableBody}>{children}</View>;
};

export const TableRow = ({ children }) => {
  return <View style={styles.tableRow}>{children}</View>;
};
