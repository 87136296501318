import React from 'react';
import Typography from '@material-ui/core/Typography';

const ErrorBaseView = ({ title, message, action }) => {
  return (
    <div style={{ textAlign: 'center ' }}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {message}
      </Typography>
      {action}
    </div>
  );
};

export default ErrorBaseView;
