import React, { useContext } from "react"
import EntitiesPersonsAccordion from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsAccordion"
import EntitiesBasicInfo from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesBasicInfo"
import EntitiesUnitsSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesUnitsSection"
import EntitiesObjectSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesObjectSection"
import EntitiesTransformationSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesTransformationSection"
import EntitiesRelationsSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesRelationsSection"
import EntitiesFinancesSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesFinancesSection"
import EntitiesPersonsEuFundingSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsEuFundingSection"
import EntitiesPersonsPublicProcurementSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsPublicProcurementSection"
import Loader from "components/shared/Loader/Loader"
import { isEmpty } from "utils/utils"
import EntitiesContext from "contexts/wpio/EntitiesContext"
import { getShowSectionStateByError } from "components/wpio/EntitiesPersons/EntitiesPersonsUtils"
import EntitiesStatusVatSection from "./EntitiesStatusVatSection"

const EntitesRestInformationSection = ({ basicEntityData }) => {
  const {
    sections: {
      isLoading,
      relations,
      finances,
      units,
      euFunding,
      publicProcurement,
      transformation,
      object,
    },
  } = useContext(EntitiesContext)

  const accordionItems = [
    {
      panelId: 1,
      title: "Dane podstawowe",
      show: basicEntityData.dane_krs || basicEntityData.dane_ceidg,
      content: <EntitiesBasicInfo data={basicEntityData} />,
    },
    {
      panelId: 2,
      title: "Powiązania",
      show:
        (relations.data &&
          (relations.data.akcjonariusze ||
            relations.data.organ_nadzoru ||
            relations.data.organ_uprawniony_do_reprezentowania_spolki ||
            relations.data.prokurenci ||
            relations.data.wspolnicy ||
            relations.data.beneficjenci_rzeczywisci ||
            relations.data.reprezentanci ||
            relations.data.wlasciciel)) ||
        getShowSectionStateByError(relations.error),
      content: (
        <EntitiesRelationsSection
          relations={relations.data}
          error={relations.error}
        />
      ),
    },
    {
      panelId: 3,
      title: "Dane finansowe",
      show:
        (finances.data &&
          finances.data.dane_krs &&
          (!isEmpty(finances.data.dane_krs.kapital) ||
            !isEmpty(finances.data.dane_krs.sprawozdanie_finansowe) ||
            !isEmpty(finances.data.dane_krs.zaleglosci) ||
            !isEmpty(finances.data.dane_krs.wierzytelnosci))) ||
        getShowSectionStateByError(finances.error),
      content: (
        <EntitiesFinancesSection
          finances={finances.data}
          error={finances.error}
        />
      ),
    },
    {
      panelId: 4,
      title: "Oddziały / Adresy dodatkowe",
      show:
        (units.data &&
          ((units.data.dane_krs &&
            units.data.dane_krs.oddzialy &&
            units.data.dane_krs.oddzialy.length > 0) ||
            (units.data.dane_ceidg &&
              units.data.dane_ceidg.adresy_dzialalnosci_dodatkowe &&
              units.data.dane_ceidg.adresy_dzialalnosci_dodatkowe.length >
                0))) ||
        getShowSectionStateByError(units.error),
      content: <EntitiesUnitsSection units={units.data} error={units.error} />,
    },
    {
      panelId: 5,
      title: "Przekształcenia",
      show:
        !isEmpty(transformation.data) ||
        getShowSectionStateByError(transformation.error),
      content: (
        <EntitiesTransformationSection
          transformation={transformation.data}
          error={transformation.error}
        />
      ),
    },
    {
      panelId: 6,
      title: "Przedmiot działalności",
      show:
        (object.data &&
          ((object.data.dane_ceidg &&
            (object.data.dane_ceidg.dzialalnosc_glowna ||
              !isEmpty(object.data.dane_ceidg.dzialalnosc_pozostala))) ||
            (object.data.dane_krs &&
              (object.data.dane_krs.dzialalnosc_glowna ||
                !isEmpty(object.data.dane_krs.dzialalnosc_pozostala))))) ||
        getShowSectionStateByError(object.error),
      content: (
        <EntitiesObjectSection object={object.data} error={object.error} />
      ),
    },
    {
      panelId: 7,
      title: "Status VAT",
      show: !isEmpty(basicEntityData.dane_vat),
      content: (
        <EntitiesStatusVatSection statusVat={basicEntityData.dane_vat} />
      ),
    },
    {
      panelId: 8,
      title: "Dofinansowania z UE",
      show:
        (euFunding.data &&
          (!isEmpty(euFunding.data.projekty_ue) ||
            !isEmpty(euFunding.data.odbiorcy) ||
            !isEmpty(euFunding.data.projekty_ue_ksi))) ||
        getShowSectionStateByError(euFunding.error),
      content: (
        <EntitiesPersonsEuFundingSection
          euFunding={euFunding.data}
          error={euFunding.error}
        />
      ),
    },
    {
      panelId: 9,
      title: "Zamówienia publiczne",
      show:
        (publicProcurement.data &&
          (publicProcurement.data.lista_BK14.length > 0 ||
            publicProcurement.data.lista_BK21.length > 0)) ||
        getShowSectionStateByError(publicProcurement.error),
      content: (
        <EntitiesPersonsPublicProcurementSection
          publicProcurement={publicProcurement.data}
          error={publicProcurement.error}
        />
      ),
    },
  ]

  return (
    <>
      <EntitiesPersonsAccordion
        defaultExpanded={1}
        items={accordionItems}
      ></EntitiesPersonsAccordion>
      {isLoading && <Loader />}
    </>
  )
}

export default EntitesRestInformationSection
