import React, { useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import { Button } from "components/shared/Button/Button"
import SvgIcon from "@material-ui/core/SvgIcon"
import { skanerTheme } from "utils/skanerTheme"
import { useHistory } from "react-router-dom"
import { skanerRoutes } from "routes/skanerRoutes"
import UserContext from "contexts/shared/UserContext"
import { roles } from "utils/roles"

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    paddingTop: "2rem",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
  },
  paperInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
    textTransform: "none",
    color: skanerTheme.palette.primary,
    "& svg": {
      color: skanerTheme.palette.black,
      fontSize: "3rem",
    },
  },
}))

const HomeView = () => {
  const classes = useStyles()
  const history = useHistory()
  const { user } = useContext(UserContext)

  const tiles = [
    {
      title: "Zaloguj się",
      icon: (
        <path
          fill="currentColor"
          d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
        />
      ),
      show: !user,
      path: skanerRoutes.login,
    },
    {
      title: "Weryfikuj podmioty i osoby",
      icon: (
        <path
          fill="currentColor"
          d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
        />
      ),
      show: user && user.roles.includes(roles.USER_WPIO),
      path: skanerRoutes.modules.wpio,
    },
  ]

  const filteredTiles = tiles.filter((t) => t.show)

  return (
    <>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} md={9}>
          <Typography
            variant="h5"
            component="h1"
            className={clsx("bold", "center-text")}
          >
            Witamy w aplikacji SKANER!
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography
            className={clsx("center-text")}
            style={{ padding: "2rem 0" }}
          >
            Aplikacja SKANER jest elementem Centralnego Systemu
            Teleinformatycznego 2021. Służy do weryfikowania podmiotów i osób.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.container}>
        {filteredTiles.map(({ title, icon, path }) => (
          <Grid key={title} item xs={6} md={3}>
            <Paper
              elevation={3}
              className={classes.paper}
              component={Button}
              onClick={() => history.push(path)}
            >
              <div className={classes.paperInner}>
                <div>
                  <SvgIcon>{icon}</SvgIcon>
                </div>
                <div>
                  <Typography className={clsx("center-text")}>
                    {title}
                  </Typography>
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default HomeView
