import React, { useContext } from 'react';
import PersonsContext from 'contexts/wpio/PersonsContext';
import PersonsIdentificationSection from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/PersonsIdentificationSection';
import PersonsRestInformationSection from 'components/wpio/EntitiesPersons/EntitiesPersonsDetails/PersonsRestInformationSection';

const PersonsDetailsPanel = () => {
  const {
    details: { result },
  } = useContext(PersonsContext);

  return (
    <div>
      <PersonsIdentificationSection data={result} />
      <PersonsRestInformationSection />
    </div>
  );
};

export default PersonsDetailsPanel;
