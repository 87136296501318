import React from 'react';
import ErrorBaseView from 'views/shared/ErrorBaseView';
import { Button } from 'components/shared/Button/Button';
import { useHistory } from 'react-router-dom';
import { skanerRoutes } from 'routes/skanerRoutes';

const Error403View = () => {
  const history = useHistory();

  return (
    <ErrorBaseView
      title="Błąd 403"
      message="Nie masz uprawnień, aby widzieć tą stronę."
      action={
        <Button
          style={{ marginTop: 20 }}
          type="button"
          variant="outlined"
          color="primary"
          onClick={() => history.replace(skanerRoutes.home)}
          title="Powrót do strony głównej"
        >
          Powrót do strony głównej
        </Button>
      }
    />
  );
};

export default Error403View;
