import React, { useContext } from "react"
import EntitiesPersonsAccordion from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsAccordion"
import EntitiesPersonsEuFundingSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsEuFundingSection"
import EntitiesPersonsPublicProcurementSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/EntitiesPersonsPublicProcurementSection"
import Loader from "components/shared/Loader/Loader"
import { isEmpty } from "utils/utils"
import PersonsContext from "contexts/wpio/PersonsContext"
import PersonsRelationsSection from "components/wpio/EntitiesPersons/EntitiesPersonsDetails/PersonsRelationsSection"
import { getShowSectionStateByError } from "components/wpio/EntitiesPersons/EntitiesPersonsUtils"

const PersonsRestInformationSection = () => {
  const {
    sections: { isLoading, relations, publicProcurement, euFunding },
  } = useContext(PersonsContext)

  const accordionItems = [
    {
      panelId: 1,
      title: "Powiązania",
      show:
        (relations.data && !isEmpty(relations.data)) ||
        getShowSectionStateByError(relations.error),
      content: (
        <PersonsRelationsSection
          relations={relations.data}
          error={relations.error}
        />
      ),
    },
    {
      panelId: 2,
      title: "Dofinansowania z UE",
      show:
        (euFunding.data &&
          (!isEmpty(euFunding.data.projekty_ue) ||
            !isEmpty(euFunding.data.odbiorcy) ||
            !isEmpty(euFunding.data.projekty_ue_ksi))) ||
        getShowSectionStateByError(euFunding.error),
      content: (
        <EntitiesPersonsEuFundingSection
          euFunding={euFunding.data}
          error={euFunding.error}
        />
      ),
    },
    {
      panelId: 3,
      title: "Zamówienia publiczne",
      show:
        (publicProcurement.data &&
          (publicProcurement.data.lista_BK14.length > 0 ||
            publicProcurement.data.lista_BK21.length > 0)) ||
        getShowSectionStateByError(publicProcurement.error),
      content: (
        <EntitiesPersonsPublicProcurementSection
          publicProcurement={publicProcurement.data}
          error={publicProcurement.error}
        />
      ),
    },
  ]

  return (
    <>
      <EntitiesPersonsAccordion
        items={accordionItems}
      ></EntitiesPersonsAccordion>
      {isLoading && <Loader />}
    </>
  )
}

export default PersonsRestInformationSection
