import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { TYPE } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';
import { getNameForPersons } from 'components/wpio/EntitiesPersons/EntitiesPersonsShared';

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  chip: {
    marginTop: 5,
    paddingVertical: 5,
    borderRadius: 20,
  },
});

const EntitiesPersonsHeaderPdf = ({ details, type }) => {
  const title =
    type === TYPE.ENTITY ? details.nazwa : getNameForPersons(details);

  return (
    <View style={{ marginTop: 15 }}>
      <View style={styles.title}>
        <Text>{title}</Text>
      </View>
      {details.forma_prawna && (
        <View>
          <Text style={[styles.chip]}>{details.forma_prawna}</Text>
        </View>
      )}
    </View>
  );
};

export default EntitiesPersonsHeaderPdf;
