import React from "react"
import {
  Divider,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
  TableContainer,
} from "components/wpio/EntitiesPersons/EntitiesPersonsShared"
import { isNullOrEmpty, isNUllOrEmptyOrUndefined } from "utils/utils"
import { getEntityPersonValue } from "components/wpio/EntitiesPersons/EntitiesPersonsUtils"
import Link from "@material-ui/core/Link"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

const EntitiesBasicInfo = ({ data: { dane_krs, dane_ceidg } }) => {
  return (
    <>
      {!isNUllOrEmptyOrUndefined(dane_krs) && (
        <>
          <SubSectionTitle>Rejestr KRS</SubSectionTitle>
          <div>
            {!isNUllOrEmptyOrUndefined(dane_krs.data_wpisu_do_rp) && (
              <LabelValue
                label="Data wpisu do Rejestru Przedsiębiorców"
                value={dane_krs.data_wpisu_do_rp}
              />
            )}
            {!isNUllOrEmptyOrUndefined(dane_krs.data_wykreslenia_z_rp) && (
              <LabelValue
                label="Data wykreślenia z Rejestru Przedsiębiorców"
                value={dane_krs.data_wykreslenia_z_rp}
              />
            )}
            {!isNUllOrEmptyOrUndefined(
              dane_krs.data_wznowienia_dzialalnosci
            ) && (
              <LabelValue
                label="Data wznowienia działalności"
                value={dane_krs.data_wznowienia_dzialalnosci}
              />
            )}
            {!isNUllOrEmptyOrUndefined(
              dane_krs.data_zawieszenia_dzialalnosci
            ) && (
              <LabelValue
                label="Data zawieszenia działalności"
                value={dane_krs.data_zawieszenia_dzialalnosci}
              />
            )}
            {!isNUllOrEmptyOrUndefined(dane_krs.data_wpisu_do_rs) && (
              <LabelValue
                label="Data wpisu do Rejestru Stowarzyszeń"
                value={dane_krs.data_wpisu_do_rs}
              />
            )}
            {!isNUllOrEmptyOrUndefined(dane_krs.data_wykreslenia_z_rs) && (
              <LabelValue
                label="Data wykreślenia z Rejestru Stowarzyszeń"
                value={dane_krs.data_wykreslenia_z_rs}
              />
            )}
            {!isNUllOrEmptyOrUndefined(
              dane_krs.data_uprawomocnienia_wykreslenia_z_krs
            ) && (
              <LabelValue
                label="Data uprawomocnienia wykreślenia z KRS"
                value={dane_krs.data_uprawomocnienia_wykreslenia_z_krs}
              />
            )}
          </div>
          {dane_krs && (
            <LabelValueRow>
              {!isNullOrEmpty(dane_krs.adres_email) && (
                <LabelValue
                  label="Adres e-mail"
                  value={
                    <Link href={`mailto:${dane_krs.adres_email}`}>
                      {dane_krs.adres_email}
                    </Link>
                  }
                />
              )}
              {!isNullOrEmpty(dane_krs.adres_www) && (
                <LabelValue
                  label="Adres WWW"
                  value={
                    <Link
                      href={"//" + dane_krs.adres_www}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {dane_krs.adres_www}
                    </Link>
                  }
                />
              )}
            </LabelValueRow>
          )}
          <div>
            {dane_krs &&
              !isNullOrEmpty(dane_krs.dzialalnosc_z_innymi_podmiotami) && (
                <>
                  <SubSectionTitle>Udział w spółkach cywilnych</SubSectionTitle>
                  <LabelValue
                    label="Udział w spółkach cywilnych"
                    value={dane_krs.dzialalnosc_z_innymi_podmiotami}
                  />
                </>
              )}
          </div>
        </>
      )}
      {dane_ceidg && (
        <div>
          {dane_krs && <Divider />}
          <SubSectionTitle>Rejestr CEIDG</SubSectionTitle>
          <LabelValue
            label="Data rozpoczęcia"
            value={getEntityPersonValue(dane_ceidg.data_rozpoczecia)}
          />
          {!isNullOrEmpty(dane_ceidg.data_zakonczenia) && (
            <LabelValue
              label="Data zaprzestania"
              value={dane_ceidg.data_zakonczenia}
            />
          )}
          {!isNullOrEmpty(dane_ceidg.data_wykreslenia) && (
            <LabelValue
              label="Data wykreślenia"
              value={dane_ceidg.data_wykreslenia}
            />
          )}
          {!isNullOrEmpty(dane_ceidg.data_zawieszenia) && (
            <LabelValue
              label="Data zawieszenia"
              value={dane_ceidg.data_zawieszenia}
            />
          )}
          {!isNullOrEmpty(dane_ceidg.data_wznowienia) && (
            <LabelValue
              label="Data wznowienia"
              value={dane_ceidg.data_wznowienia}
            />
          )}
          <LabelValueRow>
            {!isNullOrEmpty(dane_ceidg.email) && (
              <LabelValue
                label="Adres e-mail"
                value={getEntityPersonValue(dane_ceidg.email)}
              />
            )}
            {!isNullOrEmpty(dane_ceidg.www) && (
              <LabelValue
                label="Adres WWW"
                value={getEntityPersonValue(dane_ceidg.www)}
              />
            )}
          </LabelValueRow>
        </div>
      )}
      {((dane_ceidg &&
        dane_ceidg.spolki_cywilne &&
        dane_ceidg.spolki_cywilne.length > 0) ||
        (dane_krs && dane_krs.dzialalnosc_z_innymi_podmiotami)) && (
        <div>
          {dane_ceidg &&
            dane_ceidg.spolki_cywilne &&
            dane_ceidg.spolki_cywilne.length > 0 && (
              <>
                <SubSectionTitle>Udział w spółkach cywilnych</SubSectionTitle>
                <TableContainer component="div">
                  <Table
                    size="small"
                    aria-label="tabela udziałów w spółkach cywilnych"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>NIP</TableCell>
                        <TableCell>REGON</TableCell>
                        <TableCell>Data zawieszenia</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dane_ceidg.spolki_cywilne.map(
                        ({ nr_nip, nr_regon, data_zawieszenia }) => (
                          <TableRow key={nr_nip + nr_regon + data_zawieszenia}>
                            <TableCell component="th" scope="row">
                              {nr_nip}
                            </TableCell>
                            <TableCell>{nr_regon}</TableCell>
                            <TableCell>{data_zawieszenia}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
        </div>
      )}
    </>
  )
}

export default EntitiesBasicInfo
