import { useApi } from "hooks/useApi"
import React, { createContext, useEffect, useRef, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import PersonsService from "services/wpio/PersonsService"
import { isEmpty } from "utils/utils"
import axios from "axios"

const PersonsContext = createContext()

const PersonsContextProvider = ({ children }) => {
  const {
    params: { id },
  } = useRouteMatch()
  const { result, isLoading, error, loaded } = useApi(
    `/api/wpio/v1/osoby/${id}`
  )
  const [allSectionsIsLoading, setAllSectionsIsLoading] = useState(false)
  const [relations, setRelations] = useState({})
  const [euFunding, setEuFunding] = useState({})
  const [publicProcurement, setPublicProcurement] = useState({})
  const [graphImage, setGraphImage] = useState(null)
  const requestCancellationToken = useRef(null)

  const fetchAllSections = () => {
    setAllSectionsIsLoading(true)
    clearAllSections()

    const request = axios.CancelToken.source()
    requestCancellationToken.current = request

    PersonsService.getRelations(id, request)
      .then((relations) => setRelations({ data: relations }))
      .catch((error) => handleError(error, setRelations))

    PersonsService.getEuFunding(id, request)
      .then((euFunding) => setEuFunding({ data: euFunding }))
      .catch((error) => handleError(error, setEuFunding))

    PersonsService.getPublicProcurement(id, request)
      .then((publicProcurement) =>
        setPublicProcurement({ data: publicProcurement })
      )
      .catch((error) => handleError(error, setPublicProcurement))
  }

  const handleError = (error, setFunc) => {
    if (axios.isCancel(error)) return

    setFunc({ error })
  }

  const clearAllSections = () => {
    cancelNotFinishedRequests()

    setRelations({})
    setEuFunding({})
    setPublicProcurement({})
  }

  useEffect(() => {
    return () => {
      cancelNotFinishedRequests()
    }
  }, [])

  useEffect(() => {
    if (loaded) {
      fetchAllSections()
    }
  }, [loaded, id])

  useEffect(() => {
    if ([relations, euFunding, publicProcurement].every((x) => !isEmpty(x))) {
      setAllSectionsIsLoading(false)
    }
  }, [relations, euFunding, publicProcurement])

  const cancelNotFinishedRequests = () => {
    // Cancel all requests that are not finished, because persons id has changed and they are not used anymore
    if (requestCancellationToken.current) {
      requestCancellationToken.current.cancel()
    }
  }

  return (
    <PersonsContext.Provider
      value={{
        details: { result, isLoading, error },
        sections: {
          isLoading: allSectionsIsLoading,
          relations,
          euFunding,
          publicProcurement,
        },
        graph: { graphImage, setGraphImage },
      }}
    >
      {children}
    </PersonsContext.Provider>
  )
}

export default PersonsContext

export { PersonsContextProvider }
