import React, { useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import clsx from "clsx"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import SvgIcon from "@material-ui/core/SvgIcon"
import UserContext from "contexts/shared/UserContext"
import { skanerRoutes } from "routes/skanerRoutes"
import { makeStyles } from "@material-ui/core/styles"
import { skanerTheme } from "utils/skanerTheme"
import { roles } from "utils/roles"
import slugify from "slugify"

const useStyles = makeStyles((theme) => ({
  menuLabel: {
    color: "#6d6d6d",
    paddingLeft: "16px",
    paddingTop: "16px",
    fontSize: "0.9rem",
  },
  menuItem: {
    color: skanerTheme.palette.primary,
    "&:hover": {
      backgroundColor: skanerTheme.palette.hoover,
    },
    "&.active": {
      backgroundColor: skanerTheme.palette.primary,
      color: skanerTheme.palette.white,
    },
  },
  menuIcon: {
    color: skanerTheme.palette.primary,
    "&.active": {
      color: skanerTheme.palette.white,
    },
  },
}))

const SidebarMenu = ({ hideSidebar }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { user } = useContext(UserContext)

  const menu = [
    {
      id: 1,
      title: "",
      show: true,
      items: [
        {
          text: "Strona główna",
          icon: (
            <path
              fill="currentColor"
              d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"
            />
          ),
          role: null,
          path: skanerRoutes.home,
          hideMenuAfterClick: false,
        },
      ],
    },
    {
      id: 2,
      title: "Moduły",
      show: user && user.roles.includes(roles.USER_WPIO),
      items: [
        {
          text: "Weryfikacja podmiotów i osób",
          icon: (
            <path
              fill="currentColor"
              d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
            />
          ),
          role: roles.USER_WPIO,
          path: skanerRoutes.modules.wpio,
          hideMenuAfterClick: true,
        },
        {
          text: "Sampler",
          icon: (
            <path
              fill="currentColor"
              d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
            />
          ),
          role: roles.ADMIN,
          path: skanerRoutes.modules.sampler,
          hideMenuAfterClick: true,
        },
      ],
    },
    {
      id: 3,
      title: "Administracja",
      show: user && user.roles.includes(roles.ADMIN),
      items: [
        {
          text: "Użytkownicy",
          icon: (
            <path
              fill="currentColor"
              d="M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.6,8.34C16.67,8.34 17.53,9.2 17.53,10.27C17.53,11.34 16.67,12.2 15.6,12.2A1.93,1.93 0 0,1 13.67,10.27C13.66,9.2 14.53,8.34 15.6,8.34M9.6,6.76C10.9,6.76 11.96,7.82 11.96,9.12C11.96,10.42 10.9,11.5 9.6,11.5C8.3,11.5 7.24,10.42 7.24,9.12C7.24,7.81 8.29,6.76 9.6,6.76M9.6,15.89V19.64C7.2,18.89 5.3,17.04 4.46,14.68C5.5,13.56 8.13,13 9.6,13C10.13,13 10.8,13.07 11.5,13.21C9.86,14.08 9.6,15.23 9.6,15.89M12,20C11.72,20 11.46,20 11.2,19.96V15.89C11.2,14.47 14.14,13.76 15.6,13.76C16.67,13.76 18.5,14.15 19.44,14.91C18.27,17.88 15.38,20 12,20Z"
            />
          ),
          role: roles.ADMIN,
          path: `${skanerRoutes.users.index}?pageNumber=1&pageSize=10`,
          hideMenuAfterClick: false,
        },
      ],
    },
  ]

  const filteredMenu = menu.filter((m) => m.show)

  return (
    <>
      {filteredMenu.map(({ id, title, items }, index) => (
        <React.Fragment key={id}>
          {title && (
            <Typography className={classes.menuLabel}>{title}</Typography>
          )}
          {items.map(({ text, icon, path, role, hideMenuAfterClick }) => {
            if (role === null || (user && user.roles.includes(role))) {
              return (
                <List component="div" key={slugify(text)}>
                  <ListItem
                    className={clsx(classes.menuItem, {
                      active: location.pathname === path,
                    })}
                    button
                    onClick={() => {
                      history.push(path)
                      if (hideMenuAfterClick) {
                        hideSidebar()
                      }
                    }}
                  >
                    <ListItemIcon>
                      <SvgIcon
                        className={clsx(classes.menuIcon, {
                          active: location.pathname === path,
                        })}
                      >
                        {icon}
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                </List>
              )
            }

            return null
          })}
          {![filteredMenu.length - 1].includes(index) &&
            filteredMenu.length > 1 && <Divider />}
        </React.Fragment>
      ))}
    </>
  )
}

export default SidebarMenu
