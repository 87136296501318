import { http } from "../http"

const getEntities = async (
  szukanaWartosc,
  zrodloDanych,
  rozmiarStrony,
  numerStrony,
  request
) => {
  const response = await http.get(`/api/wpio/v1/podmioty`, {
    cancelToken: request.token,
    params: {
      szukanaWartosc,
      zrodloDanych,
      rozmiarStrony,
      numerStrony,
      request,
    },
  })

  return response.data
}

const getRelations = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/podmioty/${id}/powiazania`, {
    cancelToken: request.token,
  })

  return response.data
}

const getDivergenceOfSources = async (id) => {
  const response = await http.get(`api/wpio/v1/podmioty/${id}/rozbieznoscZrodel`)

  return response.data
}

const getUnits = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/podmioty/${id}/oddzialy`, {
    cancelToken: request.token,
  })

  return response.data
}

const getFinances = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/podmioty/${id}/finanse`, {
    cancelToken: request.token,
  })

  return response.data
}

const getTransformation = async (id, request) => {
  const response = await http.get(
    `/api/wpio/v1/podmioty/${id}/przeksztalcenia`,
    {
      cancelToken: request.token,
    }
  )

  return response.data
}

const getObject = async (id, request) => {
  const response = await http.get(
    `/api/wpio/v1/podmioty/${id}/przedmiotDzialalnosci`,
    {
      cancelToken: request.token,
    }
  )

  return response.data
}

const getEuFunding = async (id, request) => {
  const response = await http.get(
    `/api/wpio/v1/podmioty/${id}/dofinansowaniaUe`,
    {
      cancelToken: request.token,
    }
  )

  return response.data
}

const getPublicProcurement = async (id, request) => {
  const response = await http.get(
    `/api/wpio/v1/podmioty/${id}/zamowieniaPubliczne`,
    { cancelToken: request.token }
  )

  return response.data
}

const getGraph = async (id, level = 1) => {
  const response = await http.get(
    `/api/wpio/v1/podmioty/${id}/graf?rzad=${level}`
  )

  return response.data
}

export default {
  getEntities,
  getRelations,
  getFinances,
  getTransformation,
  getUnits,
  getObject,
  getEuFunding,
  getPublicProcurement,
  getGraph,
  getDivergenceOfSources,
}
