import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const DropdownIcon = ({ ...props }) => {
  return <ArrowDropDownIcon role="none" {...props} />;
};

const Select = ({ label, value, onChange, id, name, options }) => {
  return (
    <TextField
      id={id}
      fullWidth
      select
      name={name}
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      SelectProps={{ IconComponent: DropdownIcon }}
    >
      <MenuItem value="">-- {label} --</MenuItem>
      {options.map((op) => (
        <MenuItem key={op.value} value={op.value}>
          {op.text}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
