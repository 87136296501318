import React from 'react';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

const StyledText = styled.span`
  opacity: 0;
  pointer-events: none;
`;

const ActionTableCell = ({ ...props }) => {
  return (
    <TableCell {...props}>
      <StyledText>Akcje</StyledText>
    </TableCell>
  );
};

export default ActionTableCell;
