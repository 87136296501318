import { http } from "../http"

const sendFile = async (data) => {
  const response = await http.post("/api/sampler/upload", data);

  return response.data
}

const getSamplersList = async (
  rozmiarStrony,
  numerStrony,
  request
) => {
  const response = await http.get(`/api/sampler/zlecenia`, {
    cancelToken: request.token,
    params: {
      rozmiarStrony,
      numerStrony,
      request,
    },
  })

  return response.data
}

const getXMLFile = async (id) => {
  const response = await http.get(`/api/sampler/wynik/${id}`, {
    responseType: 'blob'
  });

  return response
}

export default {
  getSamplersList,
  getXMLFile,
  sendFile,
}
