import React, { useEffect, useState } from "react"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { Button, DangerButton } from "components/shared/Button/Button"
import { useFormik } from "formik"
import { TextField } from "@material-ui/core"
import { getParamsFromURL } from "utils/utils"
import { useHistory, useLocation } from "react-router-dom"

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  gridItem: {
    width: "100%",
  },
}))

const UsersFilters = ({ setFilters }) => {
  const params = getParamsFromURL()
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const [initialQuery, setInitialQuery] = useState(
    (params.query !== "" && params.query) || ""
  )
  const formik = useFormik({
    initialValues: {
      query: initialQuery,
    },
    onSubmit: (values) => {
      setFilters(values)
    },
  })

  const clearFilters = async () => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set("pageNumber", 1)
    queryParams.set("pageSize", 10)
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    })
    setFilters({ query: "" })
    setInitialQuery("")

    formik.resetForm({ values: { query: "" } })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={2}>
          <CardHeader
            title="Wyszukaj użytkownika"
            titleTypographyProps={{ variant: "h6" }}
          ></CardHeader>
          <form onSubmit={formik.handleSubmit}>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Imię, nazwisko, email lub program/instytucja"
                  id="query"
                  name="query"
                  value={formik.values.query}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <Button type="submit" variant="contained" color="primary">
                  Szukaj
                </Button>
                <DangerButton
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={() => clearFilters()}
                >
                  Wyczyść filtry
                </DangerButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}

export default UsersFilters
