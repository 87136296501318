import { http } from "services/http"

const editPassword = async (data) => {
  await http.patch("/api/users/editUserPassword", data)
}

const editUser = async (data) => {
  await http.patch("/api/users", data)
}

const activateAccount = async (data) => {
  await http.post("/api/users/activate", data)
}

const checkIfActivationCodeIsValid = async (activationCode) => {
  const response = await http.get(`/api/users/activationCode/${activationCode}`)
  return response
}

const resetUserPassword = async (data) => {
  await http.patch("/api/users/resetPassword", data)
}

const setNewPassword = async (data) => {
  await http.patch("/api/users/setNewPassword", data)
}

export default {
  editPassword,
  editUser,
  activateAccount,
  checkIfActivationCodeIsValid,
  resetUserPassword,
  setNewPassword,
}
