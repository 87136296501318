import React, { useState } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import { skanerTheme } from "utils/skanerTheme"
import LogoCst from "components/skaner/Layout/LogoCst"
import TopBar, { drawerWidth } from "components/skaner/Layout/TopBar"
import DrawerHeader from "components/skaner/Layout/DrawerHeader"
import BottomLogos from "components/skaner/Layout/BottomLogos"
import SidebarMenu from "components/skaner/Layout/SidebarMenu"

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  logosWrapper: {
    marginTop: "auto",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },
}))

const SkanerTemplate = ({ children }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      <TopBar moduleTitle="" drawer={{ isOpen, setIsOpen }} />
      <Drawer
        id="drawer"
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <LogoCst />
        <SidebarMenu hideSidebar={() => setIsOpen(false)} />
        <div className={classes.logosWrapper}>
          <BottomLogos />
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: isOpen,
        })}
      >
        <DrawerHeader />
        {children}
      </main>
    </>
  )
}

export default SkanerTemplate
