import { http } from "services/http"

const getUserInfo = async () => {
  const response = await http.get("/api/account/getUserInfo")

  return response.data
}

const login = async (data) => {
  return await http.post("/api/account/login", data)
}

const logout = async () => {
  return await http.post("/api/account/logout")
}

const resetSession = async () => {
  return await http.post("/api/account/resetSession")
}

const ping = async () => {
  return await http.post("/api/account/ping")
}

export default {
  getUserInfo,
  login,
  logout,
  resetSession,
  ping,
}
