import React from 'react';
import TopBar from 'components/skaner/Layout/TopBar';
import DrawerHeader from 'components/skaner/Layout/DrawerHeader';
import ModuleNavBar from 'components/skaner/Layout/ModuleNavBar';
import ModuleContent from 'components/skaner/Layout/ModuleContent';
import { wpioRoutes } from 'routes/wpioRoutes';
import { skanerRoutes } from 'routes/skanerRoutes';
import EntitiesPersonsView from 'views/wpio/EntitesPersonsView/EntitiesPersonsView';
import EntitiesPersonsDetailsView from 'views/wpio/EntitiesPersonsDetailsVeiw.js/EntitiesPersonsDetailsView';
import Error500View from 'views/shared/Error500View';
import Error403View from 'views/shared/Error403View';
import Error404View from 'views/shared/Error404View';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { TYPE } from 'components/wpio/EntitiesPersons/EntitiesPersonsConsts';

const WpioTemplate = () => {
  const { path } = useRouteMatch();

  const menu = [
    {
      title: 'Weryfikacja podmiotów',
      path: wpioRoutes.entitiesPersons.entities,
      show: true,
      activePaths: [],
      svgIcon: (
        <path
          fill="currentColor"
          d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z"
        />
      ),
    },
    {
      title: 'Weryfikacja osób',
      path: wpioRoutes.entitiesPersons.persons,
      show: true,
      activePaths: [],
      svgIcon: (
        <path
          fill="currentColor"
          d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
        />
      ),
    },
  ];

  return (
    <>
      <TopBar moduleTitle="Weryfikacja podmiotów i osób" />
      <div style={{ width: '100%' }}>
        <DrawerHeader />
        <ModuleNavBar menu={menu} />
        <ModuleContent>
          <Switch>
            <Route exact path={path}>
              <Redirect to={wpioRoutes.entitiesPersons.entities} />
            </Route>
            <Route path={wpioRoutes.entitiesPersons.persons}>
              <EntitiesPersonsView type={TYPE.PERSON} />
            </Route>
            <Route path={wpioRoutes.entitiesPersons.entities}>
              <EntitiesPersonsView type={TYPE.ENTITY} />
            </Route>
            <Route path={wpioRoutes.entitiesPersons.entityDetails}>
              <EntitiesPersonsDetailsView />
            </Route>
            <Route path={wpioRoutes.entitiesPersons.personDetails}>
              <EntitiesPersonsDetailsView />
            </Route>
            <Route path={wpioRoutes.errors[500]}>
              <Error500View />
            </Route>
            <Route path={wpioRoutes.errors[403]}>
              <Error403View />
            </Route>
            <Route path={wpioRoutes.errors[404]}>
              <Error404View />
            </Route>
            <Route path={`${path}/*`}>
              <Error404View />
            </Route>
          </Switch>
        </ModuleContent>
      </div>
    </>
  );
};

export default WpioTemplate;
