import React, { useState } from "react"
import { Dialog } from "components/shared/Modal/Modal"
import AccountService from "services/shared/AccountService"
import { skanerRoutes } from "routes/skanerRoutes"
import { useHistory } from "react-router-dom"

const SessionExpWarningModal = ({ isOpen, handleConfirm, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const handleOk = async () => {
    setIsLoading(true)
    try {
      await AccountService.resetSession()
      setIsLoading(false)
      handleClose()
      handleConfirm()
    } catch (error) {
      history.push(skanerRoutes.errors[500])
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleOk}
      isLoading={isLoading}
      disableBackdropClick
      disableEscapeKeyDown
      title="Ostrzeżenie"
      text={"Twoja sesja wkrótce wygaśnie. Naciśnij OK, aby przedłużyć sesję."}
      custom={{ confirmText: "OK", closeText: "Anuluj" }}
    ></Dialog>
  )
}

export default SessionExpWarningModal
