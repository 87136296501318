import React, { useState } from "react"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Button, DangerButton } from "components/shared/Button/Button"
import * as Yup from "yup"
import CircularLoader from "components/shared/Loader/CircularLoader"
import { useHistory, useParams } from "react-router-dom"
import { useFormik } from "formik"
import { useApi } from "hooks/useApi"
import UserService from "services/shared/UserService"
import Loader from "components/shared/Loader/Loader"
import ErrorHandler from "components/shared/ErrorHandler/ErrorHandler"
import { skanerRoutes } from "routes/skanerRoutes"
import { getPath } from "routes/routesUtils"
import { Dialog } from "components/shared/Modal/Modal"

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  gridItem: {
    width: "100%",
  },
}))

const UserDataSchema = Yup.object().shape({
  firstName: Yup.string().required("Imię musi być uzupełnione."),
  lastName: Yup.string().required("Nazwisko musi być uzupełnione."),
})

const EditData = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const {
    result: user,
    isLoading: userIsLoading,
    error,
  } = useApi(`/api/users/${id}`)
  const [showConfirmModalOptions, setShowConfirmModalOptions] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: user && user.firstName,
      lastName: user && user.lastName,
    },
    onSubmit: async ({ firstName, lastName }, { resetForm }) => {
      try {
        setIsLoading(true)
        await UserService.editUser({
          firstName: firstName,
          lastName: lastName,
          id: id,
        })
        setIsLoading(false)
        setShowConfirmModalOptions(true)

      } catch (error) {
        setIsLoading(false)
        history.push(skanerRoutes.errors[500])
        resetForm()
      }
    },
    validationSchema: UserDataSchema,
    enableReinitialize: true,
  })

  if (error) {
    return <ErrorHandler error={error} />
  }

  if (userIsLoading || !user) {
    return <Loader />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={2}>
          <CardHeader
            title={`Edytuj swoje dane`}
            titleTypographyProps={{ variant: "h6" }}
          ></CardHeader>
          <form onSubmit={formik.handleSubmit}>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Imię"
                  name="firstName"
                  id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.firstName &&
                    formik.touched.firstName &&
                    formik.errors.firstName
                  }
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Nazwisko"
                  name="lastName"
                  id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.lastName &&
                    formik.touched.lastName &&
                    formik.errors.lastName
                  }
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.buttonsWrapper}>
                <Button type="submit" variant="contained" color="primary">
                  {isLoading ? <CircularLoader /> : "Zapisz"}
                </Button>
                <DangerButton
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={() => {
                    history.push(getPath(skanerRoutes.user.userPanel, id))
                  }}
                >
                  Anuluj
                </DangerButton>
              </Grid>
            </Grid>
          </form>
          <Dialog
            isOpen={showConfirmModalOptions}
            handleClose={() =>
              history.push(getPath(skanerRoutes.user.userPanel, id))
            }
            title="Sukces"
            close
            text={"Dane zostały poprawnie zmienione."}
          ></Dialog>
        </Card>
      </Grid>
    </Grid>
  )
}

export default EditData
