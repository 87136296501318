import React, { useEffect, useState } from "react"
import EntitiesService from "services/wpio/EntitiesService"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { skanerTheme } from "utils/skanerTheme"
import TableContainer from "@material-ui/core/TableContainer"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Loader from "components/shared/Loader/Loader"
import CircularLoader from "components/shared/Loader/CircularLoader"
import Chip from "@material-ui/core/Chip"
import axios from "axios"
import PersonsService from "services/wpio/PersonsService"
import PersonsTable from "components/wpio/EntitiesPersons/EntitiesPersonsList/PersonsTable"
import EntitiesTable from "components/wpio/EntitiesPersons/EntitiesPersonsList/EntitiesTable"
import styled from "styled-components"
import { TYPE } from "components/wpio/EntitiesPersons/EntitiesPersonsConsts"
import { formatNumber } from "utils/utils"

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${skanerTheme.palette.tab};
  min-height: 36px;

  .MuiTab-root {
    font-weight: 400;
    letter-spacing: initial;
    min-height: 36px;
    color: ${skanerTheme.palette.black};
    background: ${skanerTheme.palette.tab};
  }

  .MuiTabs-fixed {
    overflow-x: auto !important;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: ${skanerTheme.palette.white};
    background: ${skanerTheme.palette.primary};
    border-radius: 10px 10px 0 0;
  }

  .MuiTab-textColorPrimary:not(.Mui-selected) {
    border: 1px solid ${skanerTheme.palette.tab};
    border-bottom: none;
    border-radius: 10px 10px 0 0;
  }

  .MuiTabs-indicator {
    background-color: transparent;
  }
`

const StyledTabLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`

const StyledClWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  text-transform: none;
`

const StyledChip = styled(Chip)`
  color: ${skanerTheme.palette.black};
  background: ${skanerTheme.palette.white};
`

const StyledTabTitle = styled.div`
  font-weight: 500;
`

const DEFAULT_PAGING = { pageSize: 10, pageNumber: 1 }

const DEFAULT_STATE = {
  type: TYPE.ENTITY,
  data: null,
  isLoading: false,
  error: null,
  pageSize: DEFAULT_PAGING.pageSize,
  pageNumber: DEFAULT_PAGING.pageNumber,
}

const SOURCES = {
  DB_SKANER_KRS: "KRS",
  DB_SKANER_SRHD: "SRHD",
  DB_CEIDG: "CEIDG",
  DB_CRBR: "CRBR",
  DB_VAT: "VAT",
}

const PERSONS_DBVAT_STATE = {
  ...DEFAULT_STATE,
  error: { customMessage: "Brak wyszukiwania osób w Rejestrze VAT." },
}

const EntitiesPersonsList = ({ filters, type }) => {
  const [initialized, setInitialized] = useState(false)
  const [listType, setListType] = useState(TYPE.ENTITY)
  const [entitiesPersonsDbSkanerKrs, setEntitiesPersonsDbSkanerKrs] = useState({
    ...DEFAULT_STATE,
  })
  const [entitiesPersonsDbSkanerSrhd, setEntitiesPersonsDbSkanerSrhd] =
    useState({
      ...DEFAULT_STATE,
    })
  const [entitiesPersonsDbCeidg, setEntitiesPersonsDbCeidg] = useState({
    ...DEFAULT_STATE,
  })
  const [entitiesPersonsDbCrbr, setEntitiesPersonsDbCrbr] = useState({
    ...DEFAULT_STATE,
  })
  const [entitiesPersonsDbVat, setEntitiesPersonsDbVat] = useState({
    ...DEFAULT_STATE,
  })
  const [activeTab, setActiveTab] = useState(SOURCES.DB_SKANER_SRHD)
  const [requestCancellationTokens, setRequestCancellationTokens] = useState({
    [SOURCES.DB_SKANER_KRS]: null,
    [SOURCES.DB_CEIDG]: null,
    [SOURCES.DB_SKANER_SRHD]: null,
    [SOURCES.DB_CRBR]: null,
    [SOURCES.DB_VAT]: null,
  })

  useEffect(() => {
    cancelRequestTokensIfExists()
    if (filters && filters.query !== "") {
      init()
      getEntitiesOrPersons(
        filters.type,
        SOURCES.DB_SKANER_KRS,
        entitiesPersonsDbSkanerKrs,
        setEntitiesPersonsDbSkanerKrs,
        DEFAULT_PAGING
      )
      getEntitiesOrPersons(
        filters.type,
        SOURCES.DB_SKANER_SRHD,
        entitiesPersonsDbSkanerSrhd,
        setEntitiesPersonsDbSkanerSrhd,
        DEFAULT_PAGING
      )
      getEntitiesOrPersons(
        filters.type,
        SOURCES.DB_CRBR,
        entitiesPersonsDbCrbr,
        setEntitiesPersonsDbCrbr,
        DEFAULT_PAGING
      )

      if (filters.type === TYPE.ENTITY) {
        getEntitiesOrPersons(
          filters.type,
          SOURCES.DB_VAT,
          entitiesPersonsDbVat,
          setEntitiesPersonsDbVat,
          DEFAULT_PAGING
        )
      }
      // Added some delay to request for CEIDG API because SKANER DB API is much faster than CEIDG API
      // and doing set timeout we ensuring that calls to SKANER DB API will be handled as first
      setTimeout(() => {
        getEntitiesOrPersons(
          filters.type,
          SOURCES.DB_CEIDG,
          entitiesPersonsDbCeidg,
          setEntitiesPersonsDbCeidg,
          DEFAULT_PAGING
        )
      }, 200)
    } else {
      setInitialized(false)
      setEntitiesPersonsDbSkanerKrs({ ...DEFAULT_STATE })
      setEntitiesPersonsDbSkanerSrhd({ ...DEFAULT_STATE })
      setEntitiesPersonsDbCeidg({ ...DEFAULT_STATE })
      setEntitiesPersonsDbCrbr({ ...DEFAULT_STATE })
      setEntitiesPersonsDbVat({ ...DEFAULT_STATE })
    }
  }, [filters])

  useEffect(() => {
    cancelRequestTokensIfExists()
    setInitialized(false)
    setEntitiesPersonsDbSkanerKrs({ ...DEFAULT_STATE })
    setEntitiesPersonsDbSkanerSrhd({ ...DEFAULT_STATE })
    setEntitiesPersonsDbCeidg({ ...DEFAULT_STATE })
    setEntitiesPersonsDbCrbr({ ...DEFAULT_STATE })
    setEntitiesPersonsDbVat(
      type === TYPE.ENTITY
        ? {
            ...DEFAULT_STATE,
          }
        : { ...PERSONS_DBVAT_STATE }
    )
  }, [type])

  const getEntitiesOrPersons = async (
    type,
    source,
    state,
    setState,
    { pageSize, pageNumber }
  ) => {
    setState({ ...state, error: null, isLoading: true })
    setListType(type)
    const request = axios.CancelToken.source()
    setRequestCancellationTokens((prevValue) => ({
      ...prevValue,
      [source]: request,
    }))
    try {
      let result = null
      if (type === TYPE.ENTITY) {
        result = await EntitiesService.getEntities(
          filters.query,
          source,
          pageSize,
          pageNumber,
          request
        )
      } else {
        result = await PersonsService.getPersons(
          filters.query,
          source,
          pageSize,
          pageNumber,
          request
        )
      }

      setState({
        ...state,
        error: null,
        isLoading: false,
        pageSize,
        pageNumber,
        data: result,
      })
    } catch (error) {
      if (axios.isCancel(error)) return
      setState({
        ...state,
        isLoading: false,
        error: error,
        data: null,
        pageSize: DEFAULT_PAGING.pageSize,
        pageNumber: DEFAULT_PAGING.pageNumber,
      })
    }
  }

  const init = () => {
    if (!initialized) {
      setInitialized(true)
    }
  }

  // Finish all previous requests when somebody clears filter or change query and requests hasnt finished yet
  const cancelRequestTokensIfExists = () => {
    Object.values(requestCancellationTokens).forEach((x) => x && x.cancel())
  }

  const countLabel = (listType, data) => {
    let count = 0
    switch (listType) {
      case TYPE.ENTITY:
        count = data.liczba_podmiotow
        break
      case TYPE.PERSON:
        count = data.liczba_osob
        break
      default:
        break
    }

    return `${
      (!Boolean(data.czy_liczba_dokladna) && "ok. ") || ""
    }${formatNumber(count, 0)}`
  }

  if (!initialized) {
    return <></>
  }

  return (
    <>
      <StyledTabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          label={
            <StyledTabLabel>
              <StyledTabTitle>CST</StyledTabTitle>
              {entitiesPersonsDbSkanerSrhd.isLoading && (
                <StyledClWrapper>
                  <CircularLoader
                    svgcolor={
                      (activeTab === SOURCES.DB_SKANER_SRHD &&
                        skanerTheme.palette.white) ||
                      skanerTheme.palette.primary
                    }
                  />
                </StyledClWrapper>
              )}
              {!entitiesPersonsDbSkanerSrhd.isLoading &&
                entitiesPersonsDbSkanerSrhd.data !== null && (
                  <StyledClWrapper>
                    <StyledChip
                      label={countLabel(
                        listType,
                        entitiesPersonsDbSkanerSrhd.data
                      )}
                      color="primary"
                      size="small"
                    />
                  </StyledClWrapper>
                )}
            </StyledTabLabel>
          }
          value={SOURCES.DB_SKANER_SRHD}
          onClick={() => setActiveTab(SOURCES.DB_SKANER_SRHD)}
        />
        <Tab
          label={
            <StyledTabLabel>
              <StyledTabTitle>KRS</StyledTabTitle>
              {entitiesPersonsDbSkanerKrs.isLoading && (
                <StyledClWrapper>
                  <CircularLoader
                    svgcolor={
                      (activeTab === SOURCES.DB_SKANER_KRS &&
                        skanerTheme.palette.white) ||
                      skanerTheme.palette.primary
                    }
                  />
                </StyledClWrapper>
              )}
              {!entitiesPersonsDbSkanerKrs.isLoading &&
                entitiesPersonsDbSkanerKrs.data !== null && (
                  <StyledClWrapper>
                    <StyledChip
                      label={countLabel(
                        listType,
                        entitiesPersonsDbSkanerKrs.data
                      )}
                      color="primary"
                      size="small"
                    />
                  </StyledClWrapper>
                )}
            </StyledTabLabel>
          }
          value={SOURCES.DB_SKANER_KRS}
          onClick={() => setActiveTab(SOURCES.DB_SKANER_KRS)}
        />
        <Tab
          label={
            <StyledTabLabel>
              <StyledTabTitle>CEIDG</StyledTabTitle>
              {entitiesPersonsDbCeidg.isLoading && (
                <StyledClWrapper>
                  <CircularLoader
                    svgcolor={
                      (activeTab === SOURCES.DB_CEIDG &&
                        skanerTheme.palette.white) ||
                      skanerTheme.palette.primary
                    }
                  />
                </StyledClWrapper>
              )}
              {!entitiesPersonsDbCeidg.isLoading &&
                entitiesPersonsDbCeidg.data !== null && (
                  <StyledClWrapper>
                    <StyledChip
                      label={countLabel(listType, entitiesPersonsDbCeidg.data)}
                      color="primary"
                      size="small"
                    />
                  </StyledClWrapper>
                )}
            </StyledTabLabel>
          }
          value={SOURCES.DB_CEIDG}
          onClick={() => setActiveTab(SOURCES.DB_CEIDG)}
        />
        <Tab
          label={
            <StyledTabLabel>
              <StyledTabTitle>CRBR</StyledTabTitle>
              {entitiesPersonsDbCrbr.isLoading && (
                <StyledClWrapper>
                  <CircularLoader
                    svgcolor={
                      (activeTab === SOURCES.DB_CRBR &&
                        skanerTheme.palette.white) ||
                      skanerTheme.palette.primary
                    }
                  />
                </StyledClWrapper>
              )}
              {!entitiesPersonsDbCrbr.isLoading &&
                entitiesPersonsDbCrbr.data !== null && (
                  <StyledClWrapper>
                    <StyledChip
                      label={countLabel(listType, entitiesPersonsDbCrbr.data)}
                      color="primary"
                      size="small"
                    />
                  </StyledClWrapper>
                )}
            </StyledTabLabel>
          }
          value={SOURCES.DB_CRBR}
          onClick={() => setActiveTab(SOURCES.DB_CRBR)}
        />
        <Tab
          label={
            <StyledTabLabel>
              <StyledTabTitle>VAT</StyledTabTitle>
              {entitiesPersonsDbVat.isLoading && (
                <StyledClWrapper>
                  <CircularLoader
                    svgcolor={
                      (activeTab === SOURCES.DB_VAT &&
                        skanerTheme.palette.white) ||
                      skanerTheme.palette.primary
                    }
                  />
                </StyledClWrapper>
              )}
              {!entitiesPersonsDbVat.isLoading &&
                entitiesPersonsDbVat.data !== null && (
                  <StyledClWrapper>
                    <StyledChip
                      label={countLabel(listType, entitiesPersonsDbVat.data)}
                      color="primary"
                      size="small"
                    />
                  </StyledClWrapper>
                )}
            </StyledTabLabel>
          }
          value={SOURCES.DB_VAT}
          onClick={() => setActiveTab(SOURCES.DB_VAT)}
        />
      </StyledTabs>
      {activeTab === SOURCES.DB_SKANER_KRS && (
        <List
          type={listType}
          state={entitiesPersonsDbSkanerKrs}
          applyPaging={(paging) => {
            getEntitiesOrPersons(
              listType,
              SOURCES.DB_SKANER_KRS,
              entitiesPersonsDbSkanerKrs,
              setEntitiesPersonsDbSkanerKrs,
              paging
            )
          }}
        />
      )}
      {activeTab === SOURCES.DB_SKANER_SRHD && (
        <List
          type={listType}
          state={entitiesPersonsDbSkanerSrhd}
          applyPaging={(paging) => {
            getEntitiesOrPersons(
              listType,
              SOURCES.DB_SKANER_SRHD,
              entitiesPersonsDbSkanerSrhd,
              setEntitiesPersonsDbSkanerSrhd,
              paging
            )
          }}
        />
      )}
      {activeTab === SOURCES.DB_CEIDG && (
        <List
          limitedRowsPerPage={true}
          type={listType}
          state={entitiesPersonsDbCeidg}
          applyPaging={(paging) => {
            getEntitiesOrPersons(
              listType,
              SOURCES.DB_CEIDG,
              entitiesPersonsDbCeidg,
              setEntitiesPersonsDbCeidg,
              paging
            )
          }}
        />
      )}
      {activeTab === SOURCES.DB_CRBR && (
        <List
          type={listType}
          state={entitiesPersonsDbCrbr}
          applyPaging={(paging) => {
            getEntitiesOrPersons(
              listType,
              SOURCES.DB_CRBR,
              entitiesPersonsDbCrbr,
              setEntitiesPersonsDbCrbr,
              paging
            )
          }}
        />
      )}
      {activeTab === SOURCES.DB_VAT && (
        <List
          type={listType}
          state={entitiesPersonsDbVat}
          applyPaging={(paging) => {
            getEntitiesOrPersons(
              listType,
              SOURCES.DB_VAT,
              entitiesPersonsDbVat,
              setEntitiesPersonsDbVat,
              paging
            )
          }}
        />
      )}
    </>
  )
}

export const StyledEntityErrorMsg = styled.div`
  display: flex;
  justify-content: center;
  background: ${skanerTheme.palette.secondaryRed};
  padding: 20px;
  color: ${skanerTheme.palette.white}; ;
`

const List = ({
  type,
  state: { isLoading, data, error, pageSize, pageNumber },
  applyPaging,
  limitedRowsPerPage = false
}) => {
  const getErrorMsg = (error) => {
    const defaultMsg =
      "Wystąpił błąd w trakcie pobierania danych. Spróbuj ponownie. Jeżeli problem nie ustępuje skontaktuj się z administratorem systemu."

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 413:
          return "Dla zadanej frazy znaleziono zbyt wiele pasujących wyników wyszukiwania. Doprecyzuj zapytanie i ponów wyszukiwanie."
        case 422:
          return error.response.data.detail.msg
        default:
          return defaultMsg
      }
    }

    if (error.customMessage) {
      return error.customMessage
    }

    return defaultMsg
  }

  return (
    <div style={{ marginTop: -2 }}>
      <TableContainer component={Card} elevation={3}>
        <CardHeader
          title={type === TYPE.ENTITY ? "Podmioty" : "Osoby"}
          titleTypographyProps={{ variant: "h6" }}
        ></CardHeader>
        <div style={{ overflowY: "auto" }}>
          {(isLoading || (data === null && error === null)) && <Loader />}
          {error !== null && (
            <StyledEntityErrorMsg>{getErrorMsg(error)}</StyledEntityErrorMsg>
          )}
          {!isLoading && data !== null && (
            <>
              {(type === TYPE.ENTITY && (
                <EntitiesTable
                  limitedRowsPerPage={limitedRowsPerPage}
                  data={data}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  applyPaging={applyPaging}
                />
              )) || (
                <PersonsTable
                  limitedRowsPerPage={limitedRowsPerPage}
                  data={data}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  applyPaging={applyPaging}
                />
              )}
            </>
          )}
        </div>
      </TableContainer>
    </div>
  )
}

export default EntitiesPersonsList
