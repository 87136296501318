import React from "react"
import { LabelValue } from "components/wpio/EntitiesPersons/EntitiesPersonsShared"
import { isNUllOrEmptyOrUndefined } from "utils/utils"

const EntitiesStatusVatSection = ({ statusVat }) => {
  return (
    <>
      <div>
        {!isNUllOrEmptyOrUndefined(statusVat.status_podatnika) && (
          <LabelValue
            label="Status podatnika"
            value={statusVat.status_podatnika}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.adres_siedziby) && (
          <LabelValue label="Adres siedziby" value={statusVat.adres_siedziby} />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.adres_dzialalnosci) && (
          <LabelValue
            label="Adres działalności"
            value={statusVat.adres_dzialalnosci}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.data_rejestracji) && (
          <LabelValue
            label="Data rejestracji"
            value={statusVat.data_rejestracji}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.data_odmowy) && (
          <LabelValue
            label="Data odmowy rejestracji"
            value={statusVat.data_odmowy}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.data_wykreslenia) && (
          <LabelValue
            label="Data wykreślenia"
            value={statusVat.data_wykreslenia}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.data_przywrocenia) && (
          <LabelValue
            label="Data przywrócenia"
            value={statusVat.data_przywrocenia}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.data_wznowienia) && (
          <LabelValue
            label="Data wznowienia"
            value={statusVat.data_wznowienia}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.podstawa_prawna_wykreslenia) && (
          <LabelValue
            label="Podstawa prawna wykreślenia"
            value={statusVat.podstawa_prawna_wykreslenia}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.podstawa_prawna_odmowy) && (
          <LabelValue
            label="Podstawa prawna odmowy"
            value={statusVat.podstawa_prawna_odmowy}
          />
        )}
        {!isNUllOrEmptyOrUndefined(statusVat.podstawa_prawna_przywrocenia) && (
          <LabelValue
            label="Podstawa prawna przywrócenia"
            value={statusVat.podstawa_prawna_przywrocenia}
          />
        )}
      </div>
    </>
  )
}

export default EntitiesStatusVatSection
