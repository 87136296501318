import React from 'react';
import logo from './cst2020logo.png';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;

  img {
    width: 200px;
    margin: 0.5rem auto;
    display: block;
  }
`;

const LogoCst = () => {
  return (
    <StyledWrapper>
      <img src={logo} alt="logo CST2021" />
    </StyledWrapper>
  );
};

export default LogoCst;
