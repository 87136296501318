import React, { useState, useContext } from "react"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Button, DangerButton } from "components/shared/Button/Button"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import CircularLoader from "components/shared/Loader/CircularLoader"
import { useHistory, useParams } from "react-router-dom"
import Loader from "components/shared/Loader/Loader"
import ErrorHandler from "components/shared/ErrorHandler/ErrorHandler"
import { skanerRoutes } from "routes/skanerRoutes"
import { roles, roleModulesMap } from "utils/roles"
import FormLabel from "@material-ui/core/FormLabel"
import { getPath } from "routes/routesUtils"
import { useApi } from "hooks/useApi"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  gridItem: {
    width: "100%",
  },
}))

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: "black",
      },
    },
    MuiInputBase: {
      input: { color: "black" },
    },
  },
})

const UserPanelView = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const {
    result: user,
    isLoading: userIsLoading,
    error,
  } = useApi(`/api/users/${id}`)

  if (error) {
    return <ErrorHandler error={error} />
  }

  if (!user) {
    return <Loader />
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card elevation={2}>
            <CardHeader
              title={`Panel użytkownika #${id}`}
              titleTypographyProps={{ variant: "h6" }}
            ></CardHeader>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Imię"
                  name="firstName"
                  id="firstName"
                  disabled
                  value={(user && user.firstName) || ""}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Nazwisko"
                  name="lastName"
                  id="lastName"
                  disabled
                  value={(user && user.lastName) || ""}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Email"
                  name="email"
                  id="email"
                  disabled
                  value={(user && user.email) || ""}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Program/Instytucja"
                  name="organisation"
                  id="organisation"
                  disabled
                  value={(user && user.organisation) || "Brak"}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <FormControlLabel
                  style={{ marginTop: 8 }}
                  control={
                    <Checkbox
                      disabled
                      style={{ cursor: "default" }}
                      checked={user.role.split(",").includes(roles.ADMIN)}
                      name={roles.ADMIN}
                      id={roles.ADMIN}
                      color="primary"
                    />
                  }
                  label="Czy administrator?"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <div>
                  <FormLabel>Uprawnienia do modułów</FormLabel>
                </div>
                {roleModulesMap.map((rmm) => (
                  <div key={rmm.name}>
                    <FormControlLabel
                      style={{ marginTop: 8 }}
                      control={
                        <Checkbox
                          style={{ cursor: "default" }}
                          checked={user.role.split(",").includes(rmm.role)}
                          name={rmm.name}
                          disabled
                          id={rmm.name}
                          color="primary"
                        />
                      }
                      label={rmm.name}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.buttonsWrapper}>
                <Button
                  onClick={() =>
                    history.push(getPath(skanerRoutes.user.editPassword, id))
                  }
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 16 }}
                >
                  {isLoading ? <CircularLoader /> : "Zmień hasło"}
                </Button>
                <Button
                  onClick={() =>
                    history.push(getPath(skanerRoutes.user.editData, id))
                  }
                  variant="contained"
                  color="primary"
                >
                  {isLoading ? <CircularLoader /> : "Edytuj swoje dane"}
                </Button>
                <DangerButton
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={() => {
                    history.push(skanerRoutes.home)
                  }}
                >
                  Powrót
                </DangerButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default UserPanelView
