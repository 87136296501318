import React, { useEffect, useState } from "react"
import { skanerTheme } from "utils/skanerTheme"
import TableContainer from "@material-ui/core/TableContainer"
import Card from "@material-ui/core/Card"
import Loader from "components/shared/Loader/Loader"
import axios from "axios"
import SamplersTable from "components/sampler/SamplerTable"
import styled from "styled-components"
import SamplerService from "services/sampler/SamplerService"

const DEFAULT_PAGING = { pageSize: 10, pageNumber: 1 }

const DEFAULT_STATE = {
  data: null,
  isLoading: false,
  error: null,
  pageSize: DEFAULT_PAGING.pageSize,
  pageNumber: DEFAULT_PAGING.pageNumber,
}

const SamplersList = () => {
  const [entitiesPersonsDbSkanerSrhd, setEntitiesPersonsDbSkanerSrhd] =
    useState({
      ...DEFAULT_STATE,
    })

  useEffect(() => {
    getEntitiesOrPersons(
      entitiesPersonsDbSkanerSrhd,
      setEntitiesPersonsDbSkanerSrhd,
      DEFAULT_PAGING
    )
  }, [])

  const getEntitiesOrPersons = async (
    state,
    setState,
    { pageSize, pageNumber }
  ) => {
    setState({ ...state, error: null, isLoading: true })
    const request = axios.CancelToken.source()
    try {
      let result = null
      result = await SamplerService.getSamplersList(
        pageSize,
        pageNumber,
        request
      )

      setState({
        ...state,
        error: null,
        isLoading: false,
        pageSize,
        pageNumber,
        data: result,
      })
    } catch (error) {
      if (axios.isCancel(error)) return
      setState({
        ...state,
        isLoading: false,
        error: error,
        data: null,
        pageSize: DEFAULT_PAGING.pageSize,
        pageNumber: DEFAULT_PAGING.pageNumber,
      })
    }
  }

  return (
    <>
      <h1>Lista plików</h1>
      <List
        state={entitiesPersonsDbSkanerSrhd}
        applyPaging={(paging) => {
          getEntitiesOrPersons(
            entitiesPersonsDbSkanerSrhd,
            setEntitiesPersonsDbSkanerSrhd,
            paging
          )
        }}
      />
    </>
  )
}

export const StyledEntityErrorMsg = styled.div`
  display: flex;
  justify-content: center;
  background: ${skanerTheme.palette.secondaryRed};
  padding: 20px;
  color: ${skanerTheme.palette.white};
`

const List = ({
  state: { isLoading, data, error, pageSize, pageNumber },
  applyPaging,
  limitedRowsPerPage = false
}) => {
  const getErrorMsg = (error) => {
    const defaultMsg =
      "Wystąpił błąd w trakcie pobierania danych. Spróbuj ponownie. Jeżeli problem nie ustępuje skontaktuj się z administratorem systemu."

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 413:
          return "Dla zadanej frazy znaleziono zbyt wiele pasujących wyników wyszukiwania. Doprecyzuj zapytanie i ponów wyszukiwanie."
        case 422:
          return error.response.data.detail.msg
        default:
          return defaultMsg
      }
    }

    if (error.customMessage) {
      return error.customMessage
    }

    return defaultMsg
  }

  const handleDownloadClick = async (id) => {
    const response = await SamplerService.getXMLFile(id);

    const disposition = response.headers['content-disposition'];
    let fileName = '';
    
    if (disposition && disposition.includes('attachment')) {
      const fileNameMatch = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (fileNameMatch != null && fileNameMatch[1]) {
        fileName = fileNameMatch[1].replace(/['"]/g, '');
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div style={{ marginTop: -2 }}>
      <TableContainer component={Card} elevation={3}>
        <div style={{ overflowY: "auto" }}>
          {(isLoading || (data === null && error === null)) && <Loader />}
          {error !== null && (
            <StyledEntityErrorMsg>{getErrorMsg(error)}</StyledEntityErrorMsg>
          )}
          {!isLoading && data !== null && (
            <>
              <SamplersTable
                limitedRowsPerPage={limitedRowsPerPage}
                data={data}
                pageNumber={pageNumber}
                pageSize={pageSize}
                applyPaging={applyPaging}
                handleDownloadClick={handleDownloadClick}
              />
            </>
          )}
        </div>
      </TableContainer>
    </div>
  )
}

export default SamplersList
